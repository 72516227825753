@if(isSupervisorPosition) {
<div class="handoff-container env-prev custom-{{selectedPlantCode}}-{{positionCode}}">
    <div class="row spacing-pt-20">
        <div class="col-6 items-section">
            @if(selectedPlantCode ==='MAT' && (positionCode ==='FLS' || positionCode === 'PLH') || (selectedPlantCode==='HAR' && (positionCode==='FLS1' || positionCode === 'FLS2' || positionCode === 'SLS' || positionCode === 'PLH1' || positionCode === 'PLH2'))){
            }
            @else{
            <h3 class="mb-0 ">{{ "app.previous_hoff.items" | translate }}</h3>
            }
            @if((selectedPlantCode ==='BUR' && positionCode ==='PLS') || (selectedPlantCode ==='HAR' && (positionCode ==='FLS1' || positionCode ==='FLS2' || positionCode ==='SLS' || positionCode === 'PLH1' || positionCode === 'PLH2'))){
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of item; track controlItem1) {
            @if(controlItem1.ctrltype === 'rdbutton' || (controlItem1.ctrltype === 'text' &&
            (!controlItem1.controlname.includes('-cmt'))) || (controlItem1.ctrltype == 'number' &&
            selectedPlantCode=='MAT') || (controlItem1.ctrltype == 'number' &&
            selectedPlantCode=='HAR'))
            {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                        @if(controlItem1.unit){
                        <div class="col d-flex justify-content-end ">
                            <span class="unit-bg">{{controlItem1.unit}}</span>
                        </div>
                        }
                    </div>
                    </div>
            </div>
            }
            }
            }
            }
            @else{
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of envResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'rdbutton' || (controlItem1.ctrltype == 'number' && selectedPlantCode=='MAT'))
            {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            @if(selectedPlantCode==='MAT' && (positionCode==='SLS' || positionCode==='FLS')){
            <div class="row  m-0">
                <div class="col response-sec">
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                        @if(controlItem1.unit){
                        <div class="col d-flex justify-content-end ">
                            <span class="unit-bg">{{controlItem1.unit}}</span>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
            @else{
            <div class="row  m-0">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>


                </div>
            </div>
            }
            }
            }
            }
            }
        </div>
        <div class="col-6 comments-section">
            @if((selectedPlantCode ==='MAT' && (positionCode ==='FLS' || positionCode === 'PLH')) || (selectedPlantCode==='HAR' && (positionCode==='FLS1' || positionCode==='FLS2' || positionCode ==='SLS' || positionCode === 'PLH1' || positionCode === 'PLH2'))){
            }
            @else{
            <h3 class="mb-0 ">{{ "app.previous_hoff.comments" | translate }}</h3>
            }
            @if((selectedPlantCode ==='BUR' && positionCode ==='PLS') || (selectedPlantCode==='HAR' && (positionCode == 'SLS' || positionCode === 'PLH1' || positionCode === 'PLH2'))){
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of item; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' && (controlItem1.controlname.includes('-cmt'))) {
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>
            <div class="row spacing-pt-20 ">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>
            }
            }
            }
            }
            @else{
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of envResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' && (controlItem1.controlname.includes('-cmt'))) {
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>
            <div class="row spacing-pt-20 custom-cmt1-{{controlItem1.controlname}}">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>
            }
            }
            }
            }


        </div>

    </div>

    <div class="row target-container env-section">
        <div class="col-6 custom-label-head">
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of envResponse[i+1]; track controlItem1) {
            @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype === "label" &&
            controlItem1.labeltext === ''){

            <div class="col custom-class-table">
                <div class="col p-0 d-flex textbox-content  textbox-wrapper">
                    <label
                        class=" form-control label-head rounded-0  d-flex justify-content-center">{{controlItem1.labeltext}}</label>
                </div>
            </div>

            }@else if(controlItem1.widget ==='input-table' && controlItem1.ctrltype === "text"){
            <div class="col custom-class-table ">
                <div class="col p-0 d-flex textbox-content textbox-wrapper">
                    <label class=" form-control rounded-0 d-flex ">{{controlItem1.value}}</label>
                </div>
            </div>
            }
            }
            }
        </div>
        <div class="col-6 p-0 ">
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of envResponse[i+1]; track controlItem1) {
            @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype === "label" &&
            controlItem1.labeltext !== ''){

            <div class="col custom-class-table">
                <div class="col p-0 d-flex textbox-content  textbox-wrapper">
                    <label class=" form-control  label-head rounded-0  d-flex ">{{controlItem1.labeltext}}</label>
                </div>
            </div>

            }
            @else if(controlItem1.widget ==='input-table' && controlItem1.ctrltype === "number" ||
            controlItem1.ctrltype
            === "date" )
            {
            <div class="col custom-table-response">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper align-items-center">
                    {{controlItem1.response}}
                    <span class="badge text-bg-secondary rounded-0">{{controlItem1.unit}}</span>
                </div>
            </div>
            }
            }
            }
        </div>
    </div>


</div>
}
@else if(isOperatorPosition )
{
    @if((selectedPlantCode === 'BUR2' || selectedPlantCode === 'BUR1') && positionCode === 'PSA'){
        <div class="handoff-container category-container spl-frying-container table-continer" [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">
            <div class="row spacing-pt-20">
                <div class="col">
                    @for (controlItem of envResponse; track controlItem;let i = $index) {
                        <div [ngClass]="
                        envResponse[i][0]?.widget == 'input-table-header'
                              ? 'row quality-custom-table'
                              : 'row'
                          ">
                          @for (equStopsContentItem of controlItem; track equStopsContentItem;let j
                          = $index) { @if(equStopsContentItem.widget ==='input-table-header' &&
                          equStopsContentItem.ctrltype === "label"){
                          <div class="col p-0">
                            <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header custom-{{
                                equStopsContentItem.controlname
                              }}">
                              <label
                                class="form-control h-75 label-head quality-widget-align rounded-0 d-flex custom-label-{{equStopsContentItem.controlname}}">{{
                                equStopsContentItem.labeltext
                                }}</label>
                            </div>
                          </div>
                          } @if(equStopsContentItem.widget ==='input-table' ||
                          equStopsContentItem.ctrltype === "text") { @if(equStopsContentItem.value)
                          {
                          <div class="p-1 target-container col-width-23 col" >
                            <div
                              class="col p-0 d-flex textbox-content textbox-wrapper align-items-center custom-{{equStopsContentItem.controlname}}">
                              {{ equStopsContentItem.value }}
                            </div>
                          </div>
                          } } @if(equStopsContentItem.widget ==='input-table' &&
                          equStopsContentItem.ctrltype === "rdbutton") {
                          <div class=" p-1 target-container col" >
                            <div class="col p-0 d-flex textbox-content textbox-wrapper">
                              <div class="d-flex align-items-center col-9">
                                {{ equStopsContentItem.response }}
                              </div>
                              @if(equStopsContentItem.unit) {
                              <div class="d-flex align-items-center justify-content-end unit-bg">
                                {{ equStopsContentItem.unit }}
                              </div>
                              }
                            </div>
                          </div>
                          } @if(equStopsContentItem.ctrltype ==='blank' ) {
                          <div class="col p-1 target-container">
                            <div class="col p-0 d-flex textbox-content textbox-wrapper">
                              <div class="d-flex align-items-center col-10">&nbsp;</div>
                            </div>
                          </div>
                          } @if((equStopsContentItem.widget ==='input-table' &&
                          equStopsContentItem.ctrltype === "text") && ( equStopsContentItem.disabled!=="true"
                    
                          )) {
                          <div class="col p-1 text-widget target-container comment-width {{
                              equStopsContentItem.controlname
                            }}">
                            <div class="col p-0 d-flex textbox-content textbox-wrapper">
                              <div class="d-flex align-items-center col-10">
                                @if(equStopsContentItem.response) {
                                {{ equStopsContentItem.response }}}
                              </div>
                            </div>
                          </div>
                    
                          } @if((equStopsContentItem.widget ==='input-table' &&
                          equStopsContentItem.ctrltype === "text") && (
                          equStopsContentItem.controlname==="tegra-screen-size-val" )) {
                          <div class="col-6 p-1 target-container  comment-width {{
                              equStopsContentItem.controlname
                            }}">
                            <div class="col p-0 d-flex textbox-content textbox-wrapper">
                              <div class="d-flex align-items-center col-10">
                                @if(equStopsContentItem.response) {
                                {{ equStopsContentItem.response }} }
                              </div>
                            </div>
                          </div>
                    
                          } @if(equStopsContentItem.ctrltype == "flagicon") {
                          <span class="enableFlag-{{
                              equStopsContentItem.response == true ? true : false
                            }}">
                          </span>
                          } @if(equStopsContentItem.ctrltype === 'number') {
                          @if(equStopsContentItem.controlname=== "tegra-screen-size-val"){
                          <div class="col-5 radio-col-width1 p-1 target-container">
                            <div class="col p-0 d-flex textbox-content textbox-wrapper">
                              <div class="d-flex align-items-center col-9">
                                @if(equStopsContentItem.response || equStopsContentItem.response ==
                                '0') {
                                {{ equStopsContentItem.response }}
                                }
                              </div>
                              @if(equStopsContentItem.unit) {
                              <div class="d-flex align-items-center unit-bg">
                                {{ equStopsContentItem.unit }}
                              </div>
                              }
                            </div>
                          </div>
                        }
                          @else if(equStopsContentItem.widget){
                          <div class="col p-1 target-container">
                            <div class="col p-0 d-flex textbox-content textbox-wrapper">
                              <div class="d-flex align-items-center col-9">
                                @if(equStopsContentItem.response || equStopsContentItem.response ==
                                '0') {
                                {{ equStopsContentItem.response }}
                                }
                              </div>
                              @if(equStopsContentItem.unit) {
                              <div class="d-flex align-items-center unit-bg">
                                {{ equStopsContentItem.unit }}
                              </div>
                              }
                            </div>
                          </div>
                          } } }
                        </div>
                        }
                    </div>
                    </div>
        </div>
        }
        @else{
<div class="handoff-container category-container"
    [ngClass]="selectedPlantCode? selectedPlantCode + ' ' + positionCode : ''">

    <div class="row spacing-pt-10">
        <div class="col items-section">
            @if((selectedPlantCode =='MAT' && positionCode == 'CF') || (selectedPlantCode =='BUR' && positionCode == 'PCMPN') || (selectedPlantCode === 'BUR2' && positionCode === 'PKGCMPN')){
            <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3>
            }
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of envResponse[i]; track controlItem1) {
            @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype
            ===
            'number' || (selectedPlantCode == 'HAR' && (positionCode == 'CTXL1' || positionCode == 'CTXL2') &&
            controlItem1.ctrltype === 'text' && !controlItem1.controlname.includes('-cmt'))) && controlItem1.controlname !== 'env-other-risk' && !controlItem1.widget) {
            <div class="row">
                <div class="col label-style">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0 custom-{{controlItem1.controlname}}">
                <div class="col response-sec d-flex">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                    @if(controlItem1.controlname === "safety-fryer-scrape") {
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                                ,moment.ISO_8601,true).isValid()?(controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                                :controlItem1.response}}</p>
                        </div>
                        <div class="col d-flex justify-content-end ">
                            <span class="unit-bg">{{ "app.previous_hoff.hrs" | translate }}</span>

                        </div>

                    </div>

                    }@else {
                    @if(selectedPlantCode==='MAT' && positionCode==='EL' && controlItem1.controlname
                    ==='lavage-wtr-concern-scrape'){
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                        @if(controlItem1.unit){
                        <div class="col d-flex justify-content-end ">
                            <span class="unit-bg">{{controlItem1.unit}}</span>
                        </div>
                        }
                    </div>
                    }
                    @else if(selectedPlantCode==='BUR' && positionCode==='PSA' && controlItem1.controlname
                    =="env-waterusages-actual"){
                            <p class="mb-0">{{controlItem1.response}}</p>
                        @if(controlItem1.unit){
                        <div class="col d-flex justify-content-end ">
                            <span class="unit-bg">{{controlItem1.unit}}</span>
                        </div>
                        }
                    }
                    @else{
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid()?(controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                        :controlItem1.response }}</p>
                        @if(controlItem1.unit){
                            <div class="col d-flex justify-content-end ">
                                <span class="unit-bg">{{controlItem1.unit}}</span>
                            </div>
                        }
                    }
                    }
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }
            @else if(!!controlItem1.widget) {
                @if(positionCode=='ATSPL' && controlItem1.ctrltype !== "number" || positionCode!=='ATSPL') {
            <!-- table content starts here -->
                <div class="env-table-content target-container custom-{{controlItem1.controlname}}">
                @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype === "label"
                && ((selectedPlantCode === 'BUR' && positionCode === 'ATSPL') && (controlItem1.controlname !== 'env-water' && controlItem1.controlname !== 'env-blank-header'))
                ){
                <div class="col p-1 env-table-header">
                    <div class="col p-0 d-flex textbox-content label-header textbox-wrapper" id="custom-{{controlItem1.controlname}}">
                        <label
                            class=" form-control h-75 label-head rounded-0  d-flex">{{controlItem1.labeltext}}</label>
                    </div>
                </div>
            }@else if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype === "label"
            && ((selectedPlantCode === 'BUR' && positionCode === 'ATSPL' &&  controlItem1.controlname === 'env-blank-header') ||
            (selectedPlantCode === 'BUR2' && positionCode === 'AUTOSPLT' &&  controlItem1.controlname === 'env-blank-header'))
            ){
                <div class="col p-1 custom1-{{controlItem1.controlname}}">
                    <div class="col p-0 d-flex textbox-content label-header textbox-wrapper" id="custom-{{controlItem1.controlname}}">
                        <label
                            class=" form-control h-75 label-head rounded-0  d-flex">{{controlItem1.labeltext}}</label>
                    </div>
                </div>
            }
            @if(controlItem1.widget ==='input-table'){
            <div class="env-table-row">
            @if((controlItem1.ctrltype === "text")){
            @if(controlItem1.value) {
            <div class="col p-1 custom-table-response custom3-{{controlItem1.controlname}}">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                    {{controlItem1.value}}

                </div>
            </div>
            } 

            }
           
        </div>
    }
        </div>
            }}
       
            <!-- table content ends -->
            }
            }
        </div>
        <div class="col-6 comments-section">
            @if((selectedPlantCode =='MAT' && positionCode == 'CF') || (selectedPlantCode =='BUR' && positionCode == 'PCMPN') || (selectedPlantCode === 'BUR2' && positionCode === 'PKGCMPN')){
            <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
            }
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of envResponse[i]; track controlItem1) {
            @if((controlItem1.ctrltype === 'text' || controlItem1.ctrltype === 'number') && (controlItem1.controlname.includes("-cmt")) && (positionCode !== "CTXL1" && positionCode !== "CTXL2" && positionCode !== "EPRL1" && positionCode !== "EPRL2" && positionCode !== "BTR" && !positionCode.includes('CYLRL')) && !controlItem1.widget) {
                <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>
            <div class="row spacing-pt-20  custom-{{controlItem1.controlname}}">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>
                </div>
            </div>
            }@else if(!!controlItem1.widget && controlItem1.ctrltype === "number") {
                <div class="env-table-content target-container custom-{{controlItem1.controlname}}">
                <div class="col p-1 custom-table-response custom4-{{controlItem1.controlname}}">
                    <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                        <div class="d-flex align-items-center col-10">
                            @if(controlItem1.response) {
                            {{controlItem1.response}}
    
                            }
                        </div>
                        <div class="d-flex align-items-center justify-content-end unit-bg">
                            {{controlItem1.unit}}
                        </div>
    
                    </div>
                </div>
                </div>
            }
            @else {
                @if(controlItem1.ctrltype === 'text'  && (positionCode == 'CTXL1' || positionCode == 'CTXL2' || positionCode == "EPRL1" || positionCode == "EPRL2" || positionCode == "BTR" || positionCode.includes('CYLRL'))
                && controlItem1.controlname.includes("-cmt") && selectedPlantCode === 'HAR') {
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>
            <div class="row spacing-pt-20  custom-{{controlItem1.controlname}}">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>
                </div>
            </div>
            }@else if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype === "label"
            && (((selectedPlantCode === 'BUR' && positionCode === 'ATSPL' && controlItem1.controlname === 'env-water')) || ((selectedPlantCode === 'BUR2' && positionCode === 'AUTOSPLT' && controlItem1.controlname === 'env-water')))
            ){
            <div class="env-table-content target-container custom-{{controlItem1.controlname}}">
                <div class="col p-1 custom2-{{controlItem1.controlname}}">
                    <div class="col p-0 d-flex textbox-content label-header textbox-wrapper" id="custom-{{controlItem1.controlname}}">
                        <label
                            class=" form-control h-75 label-head rounded-0  d-flex">{{controlItem1.labeltext}}</label>
                    </div>
                </div>
            </div>
            }
            }
            }
            }

        </div>

    </div>
    <div class="row">
        <div class="col">
            @for(item of envResponse; track item; let i= $index) {
            @for (controlItem1 of envResponse[i]; track controlItem1) {
            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header report-header">{{controlItem1.labeltext}}</label>
            </div>
            @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
            {
            <app-reported-defect-view [tableId]="'PHF_Environment_Table'" [selectedPlantCode]="selectedPlantCode"
                [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
            }
            @else{
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }}
            </div>
            }
            }
            }
            }

        </div>

    </div>
    @for(item of envResponse; track item; let i= $index) {
    @for (controlItem1 of envResponse[i]; track controlItem1) {
    @if(controlItem1.ctrltype === 'rdbutton' && controlItem1.controlname === 'env-other-risk' ) {
    <div class="row">
        <div class="col label-style">
            <label class="label">{{controlItem1.labeltext}}</label>
        </div>

    </div>
    <div class="row  m-0 custom-{{controlItem1.controlname}}">
        <div class="col-6 response-sec">

            <p class="mb-0">{{controlItem1.response}}</p>


        </div>
    </div>
    }
    }
    }

</div>
        }
}