<span [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">
  @if(selectedPlantCode == 'HAR')
  {   
     <div class="handoff-container quality-prev">
       <div class="row spacing-pt-20">
         <div class="col-6 items-section">
           @for(item of qualityResponse; track item; let i= $index) { @for
           (controlItem1 of qualityResponse[i]; track controlItem1) { @if
           (!controlItem1.widget){ @if((controlItem1.ctrltype === 'rdbutton' ||
           controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'select' 
           || controlItem1.ctrltype === 'number' || (controlItem1.ctrltype === 'text'  &&  !(controlItem1.controlname.includes('cmt'))  )  )  ) {
           @if(controlItem1.controlname != "quality-fsq"){
           <div class="row spacing-pt-20">
             <div class="col">
               <label class="label">{{ controlItem1.labeltext }}</label>
             </div>
           </div>
           <div class="row m-0">
             <div class="col response-sec">
               @if(controlItem1.ctrltype === 'date') {
               <p class="mb-0 d-flex justify-content-between">
                 <span>{{
                   controlItem1.response != " " &&
                   moment(controlItem1.response, moment.ISO_8601, true).isValid()
                   ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                   : controlItem1.response
                   }}</span>
                 @if(controlItem1?.unit){<span class="unit-txt">{{
                   controlItem1?.unit
                   }}</span>}
               </p>
               } @else if(controlItem1.ctrltype === 'number') {
               <p class="mb-0 d-flex justify-content-between">
                 <span>{{ controlItem1.response }}</span>
                 @if(controlItem1?.unit){<span class="unit-txt">{{
                   controlItem1?.unit
                   }}</span>}
               </p>
               }
               @else if(controlItem1.ctrltype === 'text') {
               <p class="mb-0 d-flex justify-content-between">
                 <span>{{ controlItem1.response }}</span>
                 @if(controlItem1?.unit){<span class="unit-txt">{{
                   controlItem1?.unit
                   }}</span>}
               </p>
               }@else {
               <p class="mb-0 d-flex justify-content-between">
                 <span>{{ controlItem1.response }}</span>
               </p>
               }
             </div>
           </div>
           } } } } }
         </div>
         <div class="col-6 comments-section">
           <!-- @if(positionCode !== 'MR'){
           <h3 class="mb-0 comments-header">Comments</h3>
           } -->
           @for(item of qualityResponse; track item; let i= $index) { @for
           (controlItem1 of qualityResponse[i]; track controlItem1) { @if
           (!controlItem1.widget){ @if(controlItem1.ctrltype === 'text' && controlItem1.controlname.includes('cmt')){
           <div class="row spacing-pt-20">
             <div class="col p-0">{{ controlItem1.labeltext }}</div>
           </div>
           <div class="row custom-{{controlItem1.controlname}}" [ngClass]="controlItem1.labeltext ? '' : 'spacing-pt-20'">
             <div class="col response-sec custom-{{controlItem1.controlname}}">
               <p class="mb-0">
                 {{ controlItem1.response ? controlItem1.response : "" }}
               </p>
             </div>
           </div>
           } } } }
         </div>
       </div>
     </div>
     <div class="handoff-container category-container spl-frying-container table-continer">
      @for (controlItem of qualityResponse; track controlItem;let i = $index) {
      <div [ngClass]="
          qualityResponse[i][0]?.widget == 'input-table-header'
            ? 'row quality-custom-table'
            : 'row'
        ">
        @for (equStopsContentItem of controlItem; track equStopsContentItem;let j
        = $index) { @if(equStopsContentItem.widget ==='input-table-header' &&
        equStopsContentItem.ctrltype === "label"){
        <div class="col p-0">
          <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header custom-{{
              equStopsContentItem.controlname
            }}">
            <label
              class="form-control h-75 label-head quality-widget-align rounded-0 d-flex custom-label-{{equStopsContentItem.controlname}}">{{
              equStopsContentItem.labeltext
              }}</label>
          </div>
        </div>
        } @if(equStopsContentItem.widget ==='input-table' ||
        equStopsContentItem.ctrltype === "text") { @if(equStopsContentItem.value)
        {
        <div class="col p-1 target-container col-width-23">
          <div
            class="col p-0 d-flex textbox-content textbox-wrapper align-items-center custom-{{equStopsContentItem.controlname}}">
            {{ equStopsContentItem.value }}
          </div>
        </div>
        } } @if(equStopsContentItem.widget ==='input-table' &&
        equStopsContentItem.ctrltype === "rdbutton") {
        <div class=" p-1 target-container" [ngClass]=" positionCode ==='BTR' && equStopsContentItem.controlname === 'batter-consumption-monitoring-istarget-val' ? 'radio-col-width-48' : 'col'">
          <div class="col p-0 d-flex textbox-content textbox-wrapper">
            <div class="d-flex align-items-center col-9">
              {{ equStopsContentItem.response }}
            </div>
            @if(equStopsContentItem.unit) {
            <div class="d-flex align-items-center justify-content-end unit-bg">
              {{ equStopsContentItem.unit }}
            </div>
            }
          </div>
        </div>
        } @if(equStopsContentItem.ctrltype ==='blank' ) {
        <div class="col p-1 target-container">
          <div class="col p-0 d-flex textbox-content textbox-wrapper">
            <div class="d-flex align-items-center col-10">&nbsp;</div>
          </div>
        </div>
        } @if((equStopsContentItem.widget ==='input-table' &&
        equStopsContentItem.ctrltype === "text") && ( equStopsContentItem.disabled!=="true"
  
        )) {
        <div class="col p-1 text-widget target-container comment-width {{
            equStopsContentItem.controlname
          }}">
          <div class="col p-0 d-flex textbox-content textbox-wrapper">
            <div class="d-flex align-items-center col-10">
              @if(equStopsContentItem.response) {
              {{ equStopsContentItem.response }}}
            </div>
          </div>
        </div>
  
        } @if((equStopsContentItem.widget ==='input-table' &&
        equStopsContentItem.ctrltype === "text") && (
        equStopsContentItem.controlname==="tegra-screen-size-val" )) {
        <div class="col-6 p-1 target-container  comment-width {{
            equStopsContentItem.controlname
          }}">
          <div class="col p-0 d-flex textbox-content textbox-wrapper">
            <div class="d-flex align-items-center col-10">
              @if(equStopsContentItem.response) {
              {{ equStopsContentItem.response }} }
            </div>
          </div>
        </div>
  
        } @if(equStopsContentItem.ctrltype == "flagicon") {
        <span class="enableFlag-{{
            equStopsContentItem.response == true ? true : false
          }}">
        </span>
        } @if(equStopsContentItem.ctrltype === 'number') {
        @if(equStopsContentItem.controlname=== "tegra-screen-size-val"){
        <div class="col-5 radio-col-width1 p-1 target-container">
          <div class="col p-0 d-flex textbox-content textbox-wrapper">
            <div class="d-flex align-items-center col-9">
              @if(equStopsContentItem.response || equStopsContentItem.response ==
              '0') {
              {{ equStopsContentItem.response }}
              }
            </div>
            @if(equStopsContentItem.unit) {
            <div class="d-flex align-items-center unit-bg">
              {{ equStopsContentItem.unit }}
            </div>
            }
          </div>
        </div>
        }
        @else if(equStopsContentItem.controlname=="quality-blocks" || equStopsContentItem.controlname=="quality-blades" ){
        <div class="row spacing-pt-20">
          <div class="col p-0">{{ equStopsContentItem.labeltext }}</div>
        </div>
        <div class="row">
          <div class="col response-sec">
            <p class="mb-0">
              {{ equStopsContentItem.response ? equStopsContentItem.response : "" }}
            </p>
          </div>
        </div>
        }
        @else if(equStopsContentItem.widget){
        <div class="col p-1 target-container">
          <div class="col p-0 d-flex textbox-content textbox-wrapper">
            <div class="d-flex align-items-center col-9">
              @if(equStopsContentItem.response || equStopsContentItem.response ==
              '0') {
              {{ equStopsContentItem.response }}
              }
            </div>
            @if(equStopsContentItem.unit) {
            <div class="d-flex align-items-center unit-bg">
              {{ equStopsContentItem.unit }}
            </div>
            }
          </div>
        </div>
        } } }
      </div>
      }
    </div>
     <div class="handoff-container spl-frying-container quality-prev">
       <div class="row spacing-pt-20">
         @for(item of qualityResponse; track item; let i= $index) { @for
         (controlItem1 of qualityResponse[i+1]; track controlItem1) {
         @if(controlItem1.ctrltype === 'label' && controlItem1.type
         ==='defect' ){
         <div class="row py-3 px-0 spacing-pt-10">
           <label class="safety-header">{{ controlItem1.labeltext }}</label>
         </div>
         @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
         {
         <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
           [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
         } @else{
         <div class="row p-0 d-flex mt-0 zero-report-wrapper">
           <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
           0 {{ "app.previous_hoff.reported" | translate }}
         </div>
         } }
         @if(controlItem1.ctrltype === 'rdbutton' &&
         (controlItem1.controlname === "quality-fsq")) {
         <div class="row spacing-pt-10">
           <div class="col px-0">
             <label class="label">{{ controlItem1.labeltext }}</label>
           </div>
         </div>
         <div class="row label-container" >
           <div class="col-6 response-sec" class="custom-{{controlItem1.controlname}}">
             <p class="mb-0">{{ controlItem1.response }}</p>
           </div>
         </div>
         }
         }
         }
       </div>
     </div>
     
  }
  @else{
  @if(isSupervisorPosition ) {
  @if( selectedPlantCode=='MAT' && positionCode === 'SLS')
  {
  <div class="handoff-container category-container spl-frying-container">
    @for (controlItem of qualityResponse; track controlItem;let i = $index) {
    <div [ngClass]="
        qualityResponse[i][0]?.widget == 'input-table-header'
          ? 'row quality-custom-table'
          : 'row'
      ">
      @for (equStopsContentItem of controlItem; track equStopsContentItem;let j
      = $index) { @if(equStopsContentItem.widget ==='input-table-header' &&
      equStopsContentItem.ctrltype === "label"){
      <div class="col p-0">
        <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header custom-{{
            equStopsContentItem.controlname
          }}">
          <label
            class="form-control h-75 label-head quality-widget-align rounded-0 d-flex custom-label-{{equStopsContentItem.controlname}}">{{
            equStopsContentItem.labeltext
            }}</label>
        </div>
      </div>
      } @if(equStopsContentItem.widget ==='input-table' ||
      equStopsContentItem.ctrltype === "text") { @if(equStopsContentItem.value)
      {
      <div class="col p-1 target-container col-width-23">
        <div
          class="col p-0 d-flex textbox-content textbox-wrapper align-items-center custom-{{equStopsContentItem.controlname}}">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } } @if(equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "rdbutton") {
      <div class="col p-1 target-container"> 
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            {{ equStopsContentItem.response }}
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center justify-content-end unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } @if(equStopsContentItem.ctrltype ==='blank' ) {
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">&nbsp;</div>
        </div>
      </div>
      } @if((equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text") && ( equStopsContentItem.disabled!=="true"

      )) {
      <div class="col p-1 text-widget target-container comment-width {{
          equStopsContentItem.controlname
        }}">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.response) {
            {{ equStopsContentItem.response }}}
          </div>
        </div>
      </div>

      } @if((equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text") && (
      equStopsContentItem.controlname==="tegra-screen-size-val" )) {
      <div class="col-6 p-1 target-container  comment-width {{
          equStopsContentItem.controlname
        }}">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.response) {
            {{ equStopsContentItem.response }} }
          </div>
        </div>
      </div>

      } @if(equStopsContentItem.ctrltype == "flagicon") {
      <span class="enableFlag-{{
          equStopsContentItem.response == true ? true : false
        }}">
      </span>
      } @if(equStopsContentItem.ctrltype === 'number') {
      @if(equStopsContentItem.controlname=== "tegra-screen-size-val"){
      <div class="col-5 radio-col-width1 p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            @if(equStopsContentItem.response || equStopsContentItem.response ==
            '0') {
            {{ equStopsContentItem.response }}
            }
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      }
      @else if(equStopsContentItem.controlname=="quality-blocks" || equStopsContentItem.controlname=="quality-blades" ){
      <div class="row spacing-pt-20">
        <div class="col p-0">{{ equStopsContentItem.labeltext }}</div>
      </div>
      <div class="row">
        <div class="col response-sec">
          <p class="mb-0">
            {{ equStopsContentItem.response ? equStopsContentItem.response : "" }}
          </p>
        </div>
      </div>
      }
      @else if(equStopsContentItem.widget){
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            @if(equStopsContentItem.response || equStopsContentItem.response ==
            '0') {
            {{ equStopsContentItem.response }}
            }
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } } }
    </div>
    }
  </div>
  <div class="handoff-container quality-prev">
    <div class="row spacing-pt-20">
      <div class="col-6 items-section">
        @if(selectedPlantCode !=='MAT' && positionCode !== 'MR'){
        <h3 class="mb-0">Items</h3>
        }
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) { @if
        (!controlItem1.widget){ @if((controlItem1.ctrltype === 'rdbutton' ||
        controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'select'
        || controlItem1.controlname === 'quality-variety'
        || controlItem1.ctrltype === 'number' || (controlItem1.ctrltype === 'text' && (controlItem1.controlname ===
        'flakes-nsc-variety' || controlItem1.controlname ===
        'flakes-hoppers-inprogress')) ) && controlItem1.controlname !==
        "quality-fsq") {
        @if( controlItem1.controlname !==
        "quality-blades" && controlItem1.controlname !=="quality-blocks"){
        <div class="row spacing-pt-20">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec">
            @if(controlItem1.ctrltype === 'date') {
            <p class="mb-0 d-flex justify-content-between">
              <span>
                {{
                controlItem1.response != " " &&
                moment(controlItem1.response, moment.ISO_8601, true).isValid()
                ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                : controlItem1.response
                }}</span>
              @if(controlItem1?.unit){<span class="unit-txt">{{
                controlItem1?.unit
                }}</span>}
            </p>
            } @else if(controlItem1.ctrltype === 'number') {
            <p class="mb-0 d-flex justify-content-between">
              <span>{{ controlItem1.response }}</span>
              @if(controlItem1?.unit){<span class="unit-txt">{{
                controlItem1?.unit
                }}</span>}
            </p>
            }
            @else if(controlItem1.ctrltype === 'text') {
            <p class="mb-0 d-flex justify-content-between">
              <span>{{ controlItem1.response }}</span>
              @if(controlItem1?.unit){<span class="unit-txt">{{
                controlItem1?.unit
                }}</span>}
            </p>
            }@else {
            <p class="mb-0 d-flex justify-content-between">
              <span>{{ controlItem1.response }}</span>
            </p>
            }
          </div>
        </div>
        } } } } }
      </div>
      <div class="col-6 comments-section">
        @if(selectedPlantCode !=='MAT' && positionCode !== 'MR'){
        <h3 class="mb-0 comments-header">Comments</h3>
        }
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) { @if
        (!controlItem1.widget){ @if(controlItem1.ctrltype === 'text' && controlItem1.controlname !==
        'flakes-nsc-variety' && controlItem1.controlname !==
        'flakes-hoppers-inprogress'){
        <div class="row spacing-pt-20">
          <div class="col p-0">{{ controlItem1.labeltext }}</div>
        </div>
        <div class="row" [ngClass]="controlItem1.labeltext ? '' : 'spacing-pt-20'">
          <div class="col response-sec">
            <p class="mb-0">
              {{ controlItem1.response ? controlItem1.response : "" }}
            </p>
          </div>
        </div>
        } } } }
      </div>
    </div>
  </div>
  <div class="handoff-container spl-frying-container quality-prev">
    <div class="row spacing-pt-20">
      @for(item of qualityResponse; track item; let i= $index) { @for
      (controlItem1 of qualityResponse[i+1]; track controlItem1) {
      @if(controlItem1.ctrltype === 'label' && controlItem1.type
      ==='defect' ){
      <div class="row py-3 px-0 spacing-pt-10">
        <label class="safety-header">{{ controlItem1.labeltext }}</label>
      </div>
      @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
      {
      <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
        [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
      } @else{
      <div class="row p-0 d-flex mt-0 zero-report-wrapper">
        <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
        0 {{ "app.previous_hoff.reported" | translate }}
      </div>
      } }
      @if(controlItem1.ctrltype === 'rdbutton' &&
      (controlItem1.controlname === "quality-fsq")) {
      <div class="row spacing-pt-10">
        <div class="col px-0">
          <label class="label">{{ controlItem1.labeltext }}</label>
        </div>
      </div>
      <div class="row label-container" >
        <div class="col-6 response-sec" class="custom-{{controlItem1.controlname}}">
          <p class="mb-0">{{ controlItem1.response }}</p>
        </div>
      </div>
      }
      }
      }
    </div>
  </div>
  }
  @else{
  <div class="handoff-container quality-prev">
    <div class="row spacing-pt-20">
      <div class="col-6 items-section">
        @if(selectedPlantCode==='MAT' && (positionCode==='FLS' || positionCode ==='PLH')){

        }
        @else{
        <h3 class="mb-0">{{ "app.previous_hoff.items" | translate }}</h3>
        }
        @if(selectedPlantCode=='BUR') { 
        @for(item of qualityResponse; track item; let i= $index) {         
            @for (controlItem1 of qualityResponse[i]; track controlItem1) {
              @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype ===
              'date' || controlItem1.ctrltype === 'number') &&
              controlItem1.controlname !== "quality-fsq") {
              <div class="row spacing-pt-20">
                <div class="col">
                  <label class="label">{{ controlItem1.labeltext }}</label>
                </div>
              </div>
              <div class="row m-0">
                <div class="col response-sec">
                  @if(controlItem1.ctrltype === 'date') {
                  <p class="mb-0 d-flex justify-content-between">
                    <span>{{
                      controlItem1.response != " " &&
                      moment(controlItem1.response, moment.ISO_8601, true).isValid()
                      ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                      : controlItem1.response
                      }}</span>
                    @if(controlItem1?.unit){<span class="unit-txt">{{
                      controlItem1?.unit
                      }}</span>}
                  </p>
                  } @else if(controlItem1.ctrltype === 'number') {
                  <p class="mb-0 d-flex justify-content-between">
                    <span>{{ controlItem1.response }}</span>
                    @if(controlItem1?.unit){<span class="unit-txt">{{
                      controlItem1?.unit
                      }}</span>}
                  </p>
                  } @else {
                  <p class="mb-0 d-flex justify-content-between">
                    <span>{{ controlItem1.response }}</span>
                  </p>
                  }
                </div>
              </div>
              } } }
          }@else {
            @for(item of qualityResponse; track item; let i= $index) { 
            @for (controlItem1 of qualityResponse[i+1]; track controlItem1) {
              @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype ===
              'date' || controlItem1.ctrltype === 'number') &&
              controlItem1.controlname !== "quality-fsq") {
              <div class="row spacing-pt-20">
                <div class="col">
                  <label class="label">{{ controlItem1.labeltext }}</label>
                </div>
              </div>
              <div class="row m-0">
                <div class="col response-sec">
                  @if(controlItem1.ctrltype === 'date') {
                  <p class="mb-0 d-flex justify-content-between">
                    <span>{{
                      controlItem1.response != " " &&
                      moment(controlItem1.response, moment.ISO_8601, true).isValid()
                      ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                      : controlItem1.response
                      }}</span>
                    @if(controlItem1?.unit){<span class="unit-txt">{{
                      controlItem1?.unit
                      }}</span>}
                  </p>
                  } @else if(controlItem1.ctrltype === 'number') {
                  <p class="mb-0 d-flex justify-content-between">
                    <span>{{ controlItem1.response }}</span>
                    @if(controlItem1?.unit){<span class="unit-txt">{{
                      controlItem1?.unit
                      }}</span>}
                  </p>
                  } @else {
                  <p class="mb-0 d-flex justify-content-between">
                    <span>{{ controlItem1.response }}</span>
                  </p>
                  }
                </div>
              </div>
              } } }
          }
        
      </div>
      <div class="col-6 comments-section">
        @if(selectedPlantCode==='MAT' && (positionCode==='FLS' || positionCode ==='PLH')){

        }
        @else{
        <h3 class="mb-0 comments-header">{{ "app.previous_hoff.comments" | translate }}</h3>
        }
        @if(selectedPlantCode=='BUR') {
          @for(item of qualityResponse; track item; let i= $index) { @for
            (controlItem1 of qualityResponse[i]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' && controlItem1.controlname ===
            "inventory-tote-cmt") {
            <div class="row spacing-pt-20">
              <div class="col p-0">{{ controlItem1.labeltext }}</div>
            </div>
            <div class="row two-line-spacing">
              <div class="col response-sec">
                <p class="mb-0">
                  {{ controlItem1.response ? controlItem1.response : "" }}
                </p>
              </div>
            </div>
            } @else if(controlItem1.ctrltype === 'text') {
            <div class="row spacing-pt-20">
              <div class="col p-0">{{ controlItem1.labeltext }}</div>
            </div>
            <div class="row cust-cmt-{{ controlItem1.controlname }}" [ngClass]="controlItem1.labeltext ? '' : 'spacing-pt-20'">
              <div class="col response-sec">
                <p class="mb-0">
                  {{ controlItem1.response ? controlItem1.response : "" }}
                </p>
              </div>
            </div>
            } } }
        }
        @else {
          @for(item of qualityResponse; track item; let i= $index) { @for
            (controlItem1 of qualityResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' && controlItem1.controlname ===
            "inventory-tote-cmt") {
            <div class="row spacing-pt-20">
              <div class="col p-0">{{ controlItem1.labeltext }}
              </div>
            </div>
            <div class="row two-line-spacing">
              <div class="col response-sec">
                <p class="mb-0">
                  {{ controlItem1.response ? controlItem1.response : "" }}
                </p>
              </div>
            </div>
            } @else if(controlItem1.ctrltype === 'text') {
            <div class="row spacing-pt-20">
              <div class="col p-0">{{ controlItem1.labeltext }}</div>
            </div>
            <div class="row cust-cmt-{{ controlItem1.controlname }}" [ngClass]="controlItem1.labeltext ? '' : 'spacing-pt-20'">
              <div class="col response-sec">
                <p class="mb-0">
                  {{ controlItem1.response ? controlItem1.response : "" }}
                </p>
              </div>
            </div>
            } } }
        }
        
      </div>
    </div>
    <div class="row spacing-pt-20">
      <div class="col">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i+1]; track controlItem1) {
        @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype
        === "label"){
        <div class="col p-0">
          <div class="col p-0 d-flex textbox-content textbox-wrapper label-header">
            <label class="form-control h-75 label-head rounded-0 d-flex">{{
              controlItem1.labeltext
              }}</label>
          </div>
        </div>

        } @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect'
        ){
        <div class="row py-3">
          <label class="safety-header">{{ controlItem1.labeltext }}</label>
        </div>
        @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length >
        0) {
        <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
          [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
        } @else{
        <div class="row p-0 d-flex mt-0 zero-report-wrapper">
          <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
          0 {{ "app.previous_hoff.reported" | translate }}
        </div>
        } } } }
      </div>
    </div>
    <div class="row spacing-pt-20 mb-4">
      <div class="col-6 items-section">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i+1]; track controlItem1) {
        @if(controlItem1.ctrltype === 'rdbutton' && controlItem1.controlname ===
        "quality-fsq" ) {
        <div class="row">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec">
            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number') {
            <p class="mb-0">
              {{
              controlItem1.response != " " &&
              moment(controlItem1.response, moment.ISO_8601, true).isValid()
              ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
              : controlItem1.response
              }}
            </p>
            } @else {
            <p class="mb-0">{{ controlItem1.response }}</p>

            }
          </div>
        </div>
        } } }
      </div>
    </div>
  </div>
  }
  } @else if(isOperatorPosition) { 
    @if(positionCode === 'SPLS') {
  <div class="handoff-container category-container">
    <div class="row spacing-pt-10">
      <div class="col-6 items-section">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) {
        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype
        ==='number') { @if(controlItem1.controlname ===
        "last-cleaned-rifle-plate") {
        <div class="row spacing-pt-10">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec">
            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number') {
            <p class="mb-0">
              {{
              controlItem1.response != " " &&
              moment(controlItem1.response, moment.ISO_8601, true).isValid()
              ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
              : controlItem1.response
              }}
            </p>
            } @else {
            <p class="mb-0">{{ controlItem1.response }}</p>

            }
          </div>
        </div>
        } } } }
      </div>
      <div class="col-6 comments-section">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) {
        @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype ===
        'date' || controlItem1.ctrltype === 'number') {
        @if(controlItem1.controlname === "last-cleaned-cutters") {
        <div class="row spacing-pt-10">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec mt-0">
            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number') {
            <p class="mb-0">
              {{
              controlItem1.response != " " &&
              moment(controlItem1.response, moment.ISO_8601, true).isValid()
              ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
              : controlItem1.response
              }}
            </p>
            } @else {
            <p class="mb-0">{{ controlItem1.response }}</p>

            }
          </div>
        </div>
        } } @else if(controlItem1.ctrltype === 'text') {
        <div class="row spacing-pt-10">
          <div class="col">{{ controlItem1.labeltext }}</div>
        </div>
        <div class="row spacing-pt-10">
          <div class="col response-sec">
            <p class="mb-0">{{ controlItem1.response }}</p>
          </div>
        </div>
        } } }
      </div>
    </div>

    <div class="row spacing-pt-10">
      <div class="col">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i+1]; track controlItem1) {
        @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect'
        ){
        <div class="row py-3">
          <label class="safety-header">{{ controlItem1.labeltext }}</label>
        </div>
        @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length >
        0) {
        <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
          [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
        } @else{
        <div class="row p-0 d-flex mt-0 zero-report-wrapper">
          <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
          0 {{ "app.previous_hoff.reported" | translate }}
        </div>
        } } } }
      </div>
    </div>

    <div class="row spacing-pt-10">
      <div class="col-6 items-section">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) {
        @if(controlItem1.ctrltype === 'rdbutton') {
        <div class="row">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec">
            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number') {
            <p class="mb-0">
              {{
              controlItem1.response != " "
              ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
              : controlItem1.response
              }}
            </p>
            } @else {
            <p class="mb-0">{{ controlItem1.response }}</p>

            }
          </div>
        </div>
        } } }
      </div>
    </div>
  </div>
  }@else if(((positionCode.includes('PKG') ||  positionCode.includes('PSA') ||  positionCode.includes('FORK') || positionCode.includes('ATSPL') ) && selectedPlantCode !== 'MAT')) {

  <div class="handoff-container quality-prev">
    <div class="row spacing-pt-20">
      <div class="col-6 items-section">
        @if(positionCode !== 'CT' && selectedPlantCode !== 'MAT'){
        <h3 class="mb-0">Items</h3>
        }
        @for(item of qualityResponse; track item; let i= $index) {
           @for(controlItem1 of item; track controlItem1) {
            @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype ==='date' || controlItem1.ctrltype ==='number' ||
             (controlItem1.ctrltype ==='text' && !controlItem1.controlname.includes('cmt') )) &&
        controlItem1.controlname != "fsq-risk" ) {
        <div class="row spacing-pt-20">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec">
            @if(controlItem1.ctrltype === 'date') {
            <p class="mb-0">
              {{
              controlItem1.response != " "
              ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
              : controlItem1.response
              }}
            </p>
            } @else if(controlItem1.ctrltype === 'number') {
              <p class="mb-0 d-flex justify-content-between custom-class3-{{controlItem1.controlname}}">
                <span>{{ controlItem1.response }}</span>
                @if(controlItem1?.unit){<span class="unit-txt">{{
                  controlItem1?.unit
                  }}</span>}
              </p>
              }@else {
            <p class="mb-0">{{ controlItem1.response }}</p>

            }
          </div>
        </div>
        } } }
      </div>
      <div class="col-6 comments-section">
        @if(positionCode !== 'CT' && selectedPlantCode !== 'MAT'){
        <h3 class="mb-0 comments-header">Comments</h3>
        } @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of item; track controlItem1) {
        @if(controlItem1.ctrltype === 'text' && controlItem1.controlname ===
        "inventory-tote-cmt") {
        <div class="row spacing-pt-20">
          <div class="col">{{ controlItem1.labeltext }}</div>
        </div>
        <div class="row two-line-spacing">
          <div class="col response-sec">
            <p class="mb-0">
              {{ controlItem1.response ? controlItem1.response : "" }}
            </p>
          </div>
        </div>
        } @else if(controlItem1.ctrltype === 'text' &&
        controlItem1.controlname.includes('cmt')) {
        <div class="row spacing-pt-20">
          <div class="col">{{ controlItem1.labeltext }}</div>
        </div>
        <div class="row spacing-pt-20 custom-class5-{{controlItem1.controlname}}">
          <div class="col response-sec ">
            <p class="mb-0">
              {{ controlItem1.response ? controlItem1.response : "" }}
            </p>
          </div>
        </div>
        } } }
      </div>
    </div>
    <div class="row spacing-pt-20">
      <div class="col">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i+1]; track controlItem1) {
        @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype
        === "label"){
        <div class="col p-0">
          <div class="col p-0 d-flex textbox-content textbox-wrapper label-header">
            <label class="form-control h-75 label-head rounded-0 d-flex">{{
              controlItem1.labeltext
              }}</label>
          </div>
        </div>

        } 
        @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect'){
        <div class="row py-3">
          <label class="safety-header">{{ controlItem1.labeltext }}</label>
        </div>
        @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length >
        0) {
        <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
          [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
        } @else{
          <div class="row p-0 d-flex mt-0 zero-report-wrapper">
            <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
            0 {{ "app.previous_hoff.reported" | translate }}
          </div>
        }
        <div class="row spacing-pt-20 mb-4">
          <div class="col-6 items-section">
            @for(item of qualityResponse; track item; let i= $index) { @for
            (controlItem1 of qualityResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'rdbutton' && controlItem1.controlname
            === "fsq-risk" ) {
            <div class="row">
              <div class="col">
                <label class="label">{{ controlItem1.labeltext }}</label>
              </div>
            </div>
            <div class="row m-0">
              <div class="col response-sec">
                @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype
                === 'number') {
                <p class="mb-0">
                  {{
                  controlItem1.response != " " &&
                  moment(
                  controlItem1.response,
                  moment.ISO_8601,
                  true
                  ).isValid()
                  ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                  : controlItem1.response
                  }}
                </p>
                } @else {
                <p class="mb-0">{{ controlItem1.response }}</p>

                }
              </div>
            </div>
            } } }
          </div>
        </div>
         } } }
      </div>
    </div>
  </div>
  }
  @else if((positionCode === 'CT'&&
  selectedPlantCode === 'MAT')) {

  <div class="handoff-container quality-prev">
    <div class="row spacing-pt-20">
      <div class="col-6 items-section">
        @if(positionCode !== 'CT' && selectedPlantCode !== 'MAT'){
        <h3 class="mb-0">Items</h3>
        } @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) {
        @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'select'  || controlItem1.ctrltype ===
        'date' || controlItem1.ctrltype ==='number' || (controlItem1.ctrltype
        ==='text' && !controlItem1.controlname.includes('cmt') )) &&
        controlItem1.controlname != "quality-fsq" ) {
        <div class="row spacing-pt-20">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec">
            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number') {
            <p class="mb-0">
              {{
              controlItem1.response != " "
              ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
              : controlItem1.response
              }}
            </p>
            } @else {
            <p class="mb-0">{{ controlItem1.response }}</p>

            }
          </div>
        </div>
        } } }
      </div>
      <div class="col-6 comments-section">
        @if(positionCode !== 'CT' && selectedPlantCode !== 'MAT'){
        <h3 class="mb-0 comments-header">Comments</h3>
        } @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) {
        @if(controlItem1.ctrltype === 'text' && controlItem1.controlname ===
        "inventory-tote-cmt") {
        <div class="row spacing-pt-20">
          <div class="col">{{ controlItem1.labeltext }}</div>
        </div>
        <div class="row two-line-spacing">
          <div class="col response-sec">
            <p class="mb-0">
              {{ controlItem1.response ? controlItem1.response : "" }}
            </p>
          </div>
        </div>
        } @else if(controlItem1.ctrltype === 'text' &&
        controlItem1.controlname.includes('cmt')) {
        <div class="row spacing-pt-20">
          <div class="col">{{ controlItem1.labeltext }}</div>
        </div>
        <div class="row spacing-pt-20">
          <div class="col response-sec">
            <p class="mb-0">
              {{ controlItem1.response ? controlItem1.response : "" }}
            </p>
          </div>
        </div>
        } } }
      </div>
    </div>
    <div class="row spacing-pt-20">
      <div class="col">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) {
        @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype
        === "label"){
        <div class="col p-0">
          <div class="col p-0 d-flex textbox-content textbox-wrapper label-header">
            <label class="form-control h-75 label-head rounded-0 d-flex">{{
              controlItem1.labeltext
              }}</label>
          </div>
        </div>

        } @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect'
        ){
        <div class="row py-3">
          <label class="safety-header">{{ controlItem1.labeltext }}</label>
        </div>
        @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length >
        0) {
        <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
          [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
        } @else{
        <div class="row p-0 d-flex mt-0 zero-report-wrapper">
          <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
          0 {{ "app.previous_hoff.reported" | translate }}
        </div>
        }
        <div class="row spacing-pt-20 mb-4">
          <div class="col-6 items-section">
            @for(item of qualityResponse; track item; let i= $index) { @for
            (controlItem1 of qualityResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'rdbutton' && controlItem1.controlname
            === "quality-fsq" ) {
            <div class="row">
              <div class="col">
                <label class="label">{{ controlItem1.labeltext }}</label>
              </div>
            </div>
            <div class="row m-0">
              <div class="col response-sec">
                @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype
                === 'number') {
                <p class="mb-0">
                  {{
                  controlItem1.response != " " &&
                  moment(
                  controlItem1.response,
                  moment.ISO_8601,
                  true
                  ).isValid()
                  ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                  : controlItem1.response
                  }}
                </p>
                } @else {
                <p class="mb-0">{{ controlItem1.response }}</p>

                }
              </div>
            </div>
            } } }
          </div>
        </div> } } }
      </div>
    </div>
  </div>
  }

  @else if(positionCode === 'SPLFRY' || positionCode === 'SPLMIX' ||
  positionCode.includes('CDR') || positionCode.includes('CT') ||
  positionCode.includes('PH') || positionCode.includes('PSL') ||  positionCode.includes('FROP') || positionCode.includes('PLR') || positionCode.includes('STR') || positionCode.includes('BTR')) {
  <div class="handoff-container category-container spl-frying-container " [ngClass]="
      positionCode.includes('CT') ||
      positionCode.includes('CDR') ||
      positionCode.includes('PH') ||
      positionCode.includes('FRY')||
      positionCode.includes('STR')
        ? ' cutting-container'
        : ''
    ">

    @for (controlItem of qualityResponse; track controlItem;) {
    <div class="row d-flex align-items-stretch position-relative {{
        controlItem.length > 0 && controlItem[0]?.controlname
          ? controlItem[0]?.controlname
          : 'quality-custom-table'
      }}">
      @for (equStopsContentItem of controlItem; track equStopsContentItem ; let
      idx = $index) { @if(equStopsContentItem.widget ==='input-table-header' &&
      equStopsContentItem.ctrltype === "label"){
      <div class="col p-0 tot-records-{{ controlItem?.length }}">
        <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header custom-{{
            equStopsContentItem.controlname
          }}">
          <label class=" form-control h-75 label-head  quality-widget-align rounded-0  d-flex {{
              equStopsContentItem.controlname
            }}{{ idx }} ">{{ equStopsContentItem.labeltext }}</label>
        </div>
      </div>

      } @if(equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text"){ @if(equStopsContentItem.value) {
      @if(equStopsContentItem.controlname === "foreign-mat-hdr" ){
      <div class="col-8 potato-label-col-width p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } @else if(equStopsContentItem.controlname === "screening-hdr" ){
      <div class="col-6 tote-quality-label-col-width p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } @else{
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center  custom-label-{{
            equStopsContentItem.controlname
          }}">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } } @else if(equStopsContentItem.response && !(positionCode === 'SPLFRY'
      || positionCode === 'SPLMIX') ) {
      <div class="col p-1 text-widget target-container comment-width1">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center comment-width11">
          {{ equStopsContentItem.response }}
        </div>
      </div>
      } @else if(equStopsContentItem.placeholder && !(positionCode == 'SPLFRY'
      || positionCode == 'SPLMIX')){
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
          {{ equStopsContentItem.placeholder }}
        </div>
      </div>
      } } @if(equStopsContentItem.ctrltype ==='blank' ) { }
      @if(equStopsContentItem.ctrltype ==="textarea" && equStopsContentItem.widget ==='input-table' ) {
      @if(equStopsContentItem.controlname === "bins-group-mat-hdr"){
      <div class="col-9 potato-txtarea-width p-1 target-container textarea-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.value){
            <div class="m-0 p-0" [innerHTML]="equStopsContentItem.value"></div>
            }@else if(equStopsContentItem.response){
            <div class="m-0 p-0" [innerHTML]="equStopsContentItem.response"></div>
            }@else if(equStopsContentItem.placeholder){
            {{ equStopsContentItem.placeholder }}
            }
          </div>
        </div>
      </div>
      } @else{
      <div class="col p-1 target-container textarea-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.value){
            <div class="m-0 p-0" [innerHTML]="equStopsContentItem.value"></div>
            }@else if(equStopsContentItem.response){
            <div class="m-0 p-0" [innerHTML]="equStopsContentItem.response"></div>
            }@else if(equStopsContentItem.placeholder){
            {{ equStopsContentItem.placeholder }}
            }
          </div>
        </div>
      </div>
      } }
      @if(equStopsContentItem.ctrltype ==="textarea" && !equStopsContentItem.widget ) {
      <div [ngClass]="(equStopsContentItem.value ) ? 'col-9 question' : 'col textbox-wrapper'"
        class="spacing potato-txtarea-width p-1 target-container textarea-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.value){
            <div class="m-0 p-0" [innerHTML]="equStopsContentItem.value"></div>
            }@else if(equStopsContentItem.response){
            <div class="m-0 p-0" [innerHTML]="equStopsContentItem.response"></div>
            }@else if(equStopsContentItem.placeholder){
            {{ equStopsContentItem.placeholder }}
            }
          </div>
        </div>
      </div>
      }
      @if(equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "date" ){
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
          {{
          equStopsContentItem.response != " "
          ? (equStopsContentItem.response | datetimeFormat:'mm/dd/yyyy')
          : equStopsContentItem.response
          ? equStopsContentItem.response
          : ""
          }}
        </div>
      </div>
      } @if(equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "number") {
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex justify-content-between">
          <div class="d-flex align-items-center">
            {{ equStopsContentItem.response }}
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } @if(equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "rdbutton") {
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            {{ equStopsContentItem.response }}
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center justify-content-end unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } @if(equStopsContentItem.ctrltype === "flagicon"){

      <span class="flag-img enableFlag-{{
          equStopsContentItem.response == true ? true : false
        }}">
      </span>
      } @if(!(selectedPlantCode == "BUR" && positionCode == "FROP") && (equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text") &&
      (equStopsContentItem.controlname==="fryer-grade-value"
      ||equStopsContentItem.controlname==="fryer-defect-value" ||
      equStopsContentItem.controlname==="fryer-salt-value" ||
      equStopsContentItem.controlname==="fryer-sapp_test-value"
      ||equStopsContentItem.labeltextcmt || equStopsContentItem.controlname ===
      "metal-dtctr-istarget-cmt" || equStopsContentItem.controname ===
      "metal-dtctr-comments" || equStopsContentItem.controlname ===
      "fryer-color-istarget-cmt" ||equStopsContentItem.controlname ===
      "fryer-salt-istarget-cmt"||equStopsContentItem.controlname
      ==="fryer-sapp_test-istarget-cmt"||equStopsContentItem.controlname
      ==="fryer-weight-istarget-cmt"||equStopsContentItem.controlname
      ==="fryer-grade-istarget-cmt"||equStopsContentItem.controlname
      ==="fryer-defect-istarget-cmt"|| equStopsContentItem.controlname ===
      "blancher-ph-istarget-cmt" || equStopsContentItem.controlname ===
      "blancher-color-istarget-cmt" || equStopsContentItem.controlname ===
      "fryer-solid-istarget-cmt" || equStopsContentItem.controlname ===
      "fryer-ffa-istarget-cmt" || equStopsContentItem.controlname ===
      "fryer-piece-count-istarget-cmt" || equStopsContentItem.controlname ===
      "fryer-weight-istarget-cmt" || equStopsContentItem.controlname ===
      "fryer-grade-istarget-cmt")) {
      <div class="col p-1 text-widget target-container comment-width1">
        <div class="col p-0 d-flex textbox-content textbox-wrapper comment-width11">
          <div class="d-flex align-items-center col-9">
            @if(equStopsContentItem.response) {
            {{ equStopsContentItem.response }}
            }@else { }
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center justify-content-end unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>

      } @if((equStopsContentItem.ctrltype === "label" &&
      equStopsContentItem.controlname === "quality-item")) {
      <div class="col p-1">
        <div class="col p-0 d-flex">
          <div class="row py-3 px-0">
            <label class="safety-header">{{
              equStopsContentItem.labeltext
              }}</label>
          </div>
        </div>
      </div>
      } @if(equStopsContentItem.ctrltype === "label" &&
      equStopsContentItem.controlname === "quality-comment") {
      <div class="col p-1">
        <div class="col p-0 d-flex">
          <div class="row py-3 px-0">
            <label class="safety-header">{{
              equStopsContentItem.labeltext
              }}</label>
          </div>
          <div class="row"></div>
        </div>
      </div>
      } @if(equStopsContentItem.ctrltype === 'number' &&
      equStopsContentItem.controlname === "metal-dtctr-prdct-loss") {
      <div class="col">
        <div class="row">
          <div class="col px-0">
            <label class="label">{{ equStopsContentItem.labeltext }}</label>
          </div>
        </div>
        <div class="row label-container">
          <div class="col response-sec" class="custom-{{equStopsContentItem.controlname}}">
            <p class="mb-0">
              {{ equStopsContentItem.response }}
              @if(equStopsContentItem.unit) {
              <span class="unit-bg unit-kg">
                {{ equStopsContentItem.unit }}
              </span>
              }
            </p>
          </div>
        </div>
      </div>

      } @if(equStopsContentItem.ctrltype === 'date' &&
      (equStopsContentItem.controlname === "last-cleaned-date" ||
      equStopsContentItem.controlname === "comitrol-last-cleaned")) {
      <div class="col">
        <div class="row">
          <div class="col px-0">
            <label class="label">{{ equStopsContentItem.labeltext }}</label>
          </div>
        </div>
        <div class="row label-container">
          <div class="col response-sec" class="custom-{{ equStopsContentItem.controlname}}">
            <p class="mb-0">
              {{
              equStopsContentItem.response != " " &&
              moment(
              equStopsContentItem.response,
              moment.ISO_8601,
              true
              ).isValid()
              ? (equStopsContentItem.response | datetimeFormat:'mm/dd/yyyy')
              : equStopsContentItem.response
              }}
            </p>
          </div>
        </div>
      </div>

      } @if(equStopsContentItem.ctrltype === "text" && !(selectedPlantCode == "FVL" && positionCode == "SPLMIX") &&
      (equStopsContentItem.controlname === "last-cleaned-date-cmt" ||
      equStopsContentItem.controlname ==="comitrol-last-cleaned-cmt")) {
        @if(positionCode=='SPLMIX' && equStopsContentItem.controlname ==="comitrol-last-cleaned-cmt"){

        }@else{
          <div class="col">
            <div class="row no-label label-container">
              <div class="col response-sec" class="custom-{{equStopsContentItem.controlname}}">
                <p class="mb-0"> testing extra {{ equStopsContentItem.response }}</p>
              </div>
            </div>
          </div>
        }

      } @if(equStopsContentItem.ctrltype === "text" && !(selectedPlantCode == "FVL" && positionCode == "SPLMIX") &&
      equStopsContentItem.controlname === "metal-dtctr-prdct-loss-cmt") {
      <div class="col">
        <div class="row no-label label-container">
          <div class="col response-sec" class="custom-{{equStopsContentItem.controlname}}">
            <p class="mb-0">{{ equStopsContentItem.response }}</p>
          </div>
        </div>
      </div>

      } @if(equStopsContentItem.controlname === "last-fr-inspect-date" ||
      equStopsContentItem.controlname === "last-tnl-inspect-date") {
      <div class="col px-0 date-lablel-container">
        <div class="row spacing-pt-10">
          <div class="col">
            <label class="label">{{ equStopsContentItem.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec d-flex align-items-center" class="custom-{{equStopsContentItem.controlname}}">
            <p class="mb-0">
              {{
              equStopsContentItem.response != " " &&
              moment(
              equStopsContentItem.response,
              moment.ISO_8601,
              true
              ).isValid()
              ? (equStopsContentItem.response | datetimeFormat:'mm/dd/yyyy')
              : equStopsContentItem.response
              }}
            </p>
          </div>
        </div>
      </div>
      }
      @if((equStopsContentItem.ctrltype === 'rdbutton' || equStopsContentItem.ctrltype === 'select' ||equStopsContentItem.ctrltype === 'text') &&
      !equStopsContentItem.widget && equStopsContentItem.type != 'defect' && equStopsContentItem.controlname !=
      'quality-fsq' ) {
      @if(equStopsContentItem.ctrltype === 'rdbutton' || equStopsContentItem.ctrltype === 'select'){
      <div class="row pt-3">
        <div class="col px-0">
          <label class="label">{{ equStopsContentItem.labeltext }}</label>
        </div>
      </div>
      }
      <div class="row d-flex w-50">
        @if(equStopsContentItem.ctrltype === 'rdbutton'){
        <div class="col response-sec mt-0">
          @if(equStopsContentItem.ctrltype === 'date' ||
          equStopsContentItem.ctrltype === 'number') {
          <p class="mb-0">
            {{
            equStopsContentItem.response != " "
            ? (equStopsContentItem.response | datetimeFormat:'mm/dd/yyyy')
            : equStopsContentItem.response
            }}
          </p>
          } @else {
          <p class="mb-0">{{ equStopsContentItem.response }}</p>

          }
        </div>
        }
        @if(equStopsContentItem.ctrltype === 'text'){
        <div class="col response-sec ms-3 mt-0" class="custom-{{equStopsContentItem.controlname}}">
          @if(equStopsContentItem.ctrltype === 'date' ||
          equStopsContentItem.ctrltype === 'number') {
          <p class="mb-0">
            {{
            equStopsContentItem.response != " "
            ? (equStopsContentItem.response | datetimeFormat:'mm/dd/yyyy')
            : equStopsContentItem.response
            }}
          </p>
          } @else {
          <p class="mb-0">{{ equStopsContentItem.response }}</p>

          }
        </div>
        }
        @if(equStopsContentItem.ctrltype === 'select'){
          <div class="col response-sec ms-3 mt-0 quality-select">
            @if(equStopsContentItem.ctrltype === 'date' ||
            equStopsContentItem.ctrltype === 'number') {
            <p class="mb-0">
              {{
              equStopsContentItem.response != " "
              ? (equStopsContentItem.response | datetimeFormat:'mm/dd/yyyy')
              : equStopsContentItem.response
              }}
            </p>
            } @else {
            <p class="mb-0">{{ equStopsContentItem.response }}</p>
  
            }
          </div>
          }
      </div>
      }
      @if(equStopsContentItem.ctrltype === 'label' && equStopsContentItem.type
      ==='defect' ){
      <div class="row py-3 px-0 spacing-pt-10">
        <label class="safety-header">{{ equStopsContentItem.labeltext }}</label>
      </div>
      @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
      {
      <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
        [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
      } @else{
      <div class="row p-0 d-flex mt-0 zero-report-wrapper">
        <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
        0 {{ "app.previous_hoff.reported" | translate }}
      </div>
      } } 
      @if(equStopsContentItem.ctrltype === 'rdbutton' && 
      (equStopsContentItem.labeltext == 'Any other Food Safety Quality(FSQ)
      observation or risk identified?' || equStopsContentItem.controlname ==
      'quality-fsq' )) {
        <div class="row pt-3">
          <div class="col px-0">
            <label class="label">{{ equStopsContentItem.labeltext }}</label>
          </div>
        </div>
        <div class="row d-flex w-50">
          <div class="col response-sec mt-0" >
            <p class="mb-0">{{ equStopsContentItem.response }}</p>
          </div>
        </div>
      } 
    }
    </div>
    }

  </div>
  } @else if( positionCode === 'FRY' || positionCode === 'SADR' || positionCode
  === 'BTR' || positionCode === 'PL' || positionCode === 'MR' || positionCode
  ==='CR' || positionCode=='PKG1' || positionCode=='NTYR' || positionCode =='CDR' || positionCode=='PKG3' ||
  positionCode=='MR' 
  || positionCode=='SPDT' || positionCode=='CF' || positionCode=='CC' || positionCode=='CM' || positionCode=='BMN' ||
  positionCode=='AFTR' || positionCode=='EL' || positionCode=='QC' || positionCode =='PKG5')
  { 
    @if(selectedPlantCode=='MAT' && (positionCode=='PKG1'|| positionCode=='NTYR'|| positionCode =='CDR'
  ||positionCode=='PKG3' || positionCode=='CR' || positionCode=='MR' || positionCode=='CC' || positionCode=='SPDT' ||
  positionCode=='CF' || positionCode=='CM'
  || positionCode=='BMN' || positionCode=='PL' || positionCode=='AFTR' || positionCode =='PKG5' || positionCode=='EL' ||
  positionCode=='SADR' || positionCode=='QC' ))
  {
  <div class="handoff-container category-container spl-frying-container">
    @for (controlItem of qualityResponse; track controlItem;let i = $index) {
    <div [ngClass]="
        qualityResponse[i][0]?.widget == 'input-table-header'
          ? 'row quality-custom-table'
          : 'row'
      ">
      @for (equStopsContentItem of controlItem; track equStopsContentItem;let j
      = $index) { @if(equStopsContentItem.widget ==='input-table-header' &&
      equStopsContentItem.ctrltype === "label"){
      <div class="col p-0">
        <div class="col p-0 d-flex textbox-content  textbox-wrapper    label-header custom-{{
            equStopsContentItem.controlname
          }}">
          <label
            class="form-control h-75 label-head quality-widget-align rounded-0 d-flex custom-label-{{equStopsContentItem.controlname}}">{{
            equStopsContentItem.labeltext
            }}</label>
        </div>
      </div>
      } @if(equStopsContentItem.widget ==='input-table' ||
      equStopsContentItem.ctrltype === "text") { @if(equStopsContentItem.value)
      {
      <div class="col p-1 target-container col-width-23">
        <div
          class="col p-0 d-flex textbox-content textbox-wrapper align-items-center custom-{{equStopsContentItem.controlname}}">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } } @if(equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "rdbutton") {
      @if(equStopsContentItem.controlname === "equipment-readiness-11-val" ||
      equStopsContentItem.controlname === "equipment-readiness-12-val"||
      equStopsContentItem.controlname === "equipment-readiness-adr-val" ||
      equStopsContentItem.controlname === "tegra-isgrade-issue" ||
      equStopsContentItem.controlname === "cutter-wheel-val" ||
      equStopsContentItem.controlname === "antiform-verify-istarget" ){
      <div class="col-5 radio-col-width p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            {{ equStopsContentItem.response }}
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center justify-content-end unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } @else{
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            {{ equStopsContentItem.response }}
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center justify-content-end unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } } @if(equStopsContentItem.ctrltype ==='blank' ) {
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">&nbsp;</div>
        </div>
      </div>
      } @if((equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text") && ( equStopsContentItem.disabled!=="true"

      )) {
      <div class="col p-1 text-widget target-container comment-width {{
          equStopsContentItem.controlname
        }}">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.response) {
            {{ equStopsContentItem.response }}}
          </div>
        </div>
      </div>

      } @if((equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text") && (
      equStopsContentItem.controlname==="tegra-screen-size-val" )) {
      <div class="col-6 p-1 target-container  comment-width {{
          equStopsContentItem.controlname
        }}">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.response) {
            {{ equStopsContentItem.response }} }
          </div>
        </div>
      </div>

      } @if(equStopsContentItem.ctrltype == "flagicon") {
      <span class="enableFlag-{{
          equStopsContentItem.response == true ? true : false
        }}">
      </span>
      } @if(equStopsContentItem.ctrltype === 'number') {
      @if(equStopsContentItem.controlname=== "tegra-screen-size-val"){
      <div class="col-5 radio-col-width1 p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            @if(equStopsContentItem.response || equStopsContentItem.response ==
            '0') {
            {{ equStopsContentItem.response }}
            }
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      }
      @else if(equStopsContentItem.controlname=="quality-blocks" || equStopsContentItem.controlname=="quality-blades" ){
      <div class="row spacing-pt-20">
        <div class="col p-0">{{ equStopsContentItem.labeltext }}</div>
      </div>
      <div class="row">
        <div class="col response-sec">
          <p class="mb-0">
            {{ equStopsContentItem.response ? equStopsContentItem.response : "" }}
          </p>
        </div>
      </div>
      }
      @else if(equStopsContentItem.widget){
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            @if(equStopsContentItem.response || equStopsContentItem.response ==
            '0') {
            {{ equStopsContentItem.response }}
            }
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } } }
    </div>
    }
  </div>
  <div class="handoff-container quality-prev">
    <div class="row spacing-pt-20">
      <div class="col-6 items-section">
        @if(selectedPlantCode !=='MAT' && positionCode !== 'MR'){
        <h3 class="mb-0">Items</h3>
        }
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) { @if
        (!controlItem1.widget){ @if((controlItem1.ctrltype === 'rdbutton' ||
        controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'select'
        || controlItem1.controlname === 'quality-variety'
        || controlItem1.ctrltype === 'number') && controlItem1.controlname !==
        "quality-fsq") {
        @if( controlItem1.controlname !==
        "quality-blades" && controlItem1.controlname !=="quality-blocks"){
        <div class="row spacing-pt-20">
          <div class="col">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row m-0">
          <div class="col response-sec">
            @if(controlItem1.ctrltype === 'date') {
            <p class="mb-0 d-flex justify-content-between">
              <span>{{
                controlItem1.response != " " &&
                moment(controlItem1.response, moment.ISO_8601, true).isValid()
                ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                : controlItem1.response
                }}</span>
              @if(controlItem1?.unit){<span class="unit-txt">{{
                controlItem1?.unit
                }}</span>}
            </p>
            } @else if(controlItem1.ctrltype === 'number') {
            <p class="mb-0 d-flex justify-content-between">
              <span>{{ controlItem1.response }}</span>
              @if(controlItem1?.unit){<span class="unit-txt">{{
                controlItem1?.unit
                }}</span>}
            </p>
            } @else {
            <p class="mb-0 d-flex justify-content-between">
              <span>{{ controlItem1.response }}</span>
            </p>
            }
          </div>
        </div>
        } } } } }
      </div>
      <div class="col-6 comments-section">
        @if(selectedPlantCode !=='MAT' && positionCode !== 'MR'){
        <h3 class="mb-0 comments-header">Comments</h3>
        }
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i]; track controlItem1) { @if
        (!controlItem1.widget){ @if(controlItem1.ctrltype === 'text' && controlItem1.controlname !== 'quality-variety'){
        <div class="row spacing-pt-20">
          <div class="col p-0">{{ controlItem1.labeltext }}</div>
        </div>
        <div class="row costom-response-{{controlItem1.controlname}}" [ngClass]="controlItem1.labeltext ? '' : 'spacing-pt-20'">
          <div class="col response-sec">
            <p class="mb-0">
              {{ controlItem1.response ? controlItem1.response : "" }}
            </p>
          </div>
        </div>
        } } } }
      </div>
    </div>
  </div>
  <div class="handoff-container spl-frying-container quality-prev">
    <div class="row spacing-pt-20">
      @for(item of qualityResponse; track item; let i= $index) { @for
      (controlItem1 of qualityResponse[i+1]; track controlItem1) {
      @if(controlItem1.ctrltype === 'label' && controlItem1.type
      ==='defect' ){
      <div class="row py-3 px-0 spacing-pt-10">
        <label class="safety-header custome-margin">{{ controlItem1.labeltext }}</label>
      </div>
      @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
      {
      <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
        [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
      } @else{
      <div class="row p-0 d-flex mt-0 zero-report-wrapper">
        <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
        0 {{ "app.previous_hoff.reported" | translate }}
      </div>
      } }
      @if(controlItem1.ctrltype === 'rdbutton' &&
      (controlItem1.controlname === "quality-fsq")) {
      <div class="row spacing-pt-10">
        <div class="col px-0">
          <label class="label">{{ controlItem1.labeltext }}</label>
        </div>
      </div>
      <div class="row label-container">
        <div class="col-6 response-sec" class="custom-{{controlItem1.controlname}}">
          <p class="mb-0">{{ controlItem1.response }}</p>
        </div>
      </div>
      }
      }
      }
    </div>
  </div>
  } @else{
  <div class="handoff-container category-container spl-frying-container">
    @for (controlItem of qualityResponse; track controlItem;let i = $index) {
    <div [ngClass]="
        qualityResponse[i][0]?.widget == 'input-table-header'
          ? 'row quality-custom-table'
          : 'row'
      ">
      @for (equStopsContentItem of controlItem; track equStopsContentItem;let j
      = $index) { @if(equStopsContentItem.widget ==='input-table-header' &&
      equStopsContentItem.ctrltype === "label"){
      <div class="col p-0">
        <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header custom-{{
            equStopsContentItem.controlname
          }}">
          <label
            class="form-control h-75 label-head rounded-0 d-flex custom-head-label-{{equStopsContentItem.controlname}}">{{
            equStopsContentItem.labeltext
            }}</label>
        </div>
      </div>
      } @if(equStopsContentItem.widget ==='input-table' ||
      equStopsContentItem.ctrltype === "text") { @if(equStopsContentItem.value)
      { @if(((equStopsContentItem.controlname === "fryer-crumb-press" || equStopsContentItem.controlname === 'fryer-grade') &&
      selectedPlantCode === 'FVL') || equStopsContentItem.controlname ===
      "fryer-oil-change" ){
      <div class="col-7 radio-col-width p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } @else if(equStopsContentItem.controlname === 'paperwork-issues-hdr' &&
      selectedPlantCode === 'FVL' ){
      <div class="col-4 radio-col-width p-1 target-container col-table-width">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } @else if(equStopsContentItem.controlname === "antiform-verify-lbl"){
      <div class="col p-1 target-container col-width-23">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } @else{        
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center labelcol-{{
            equStopsContentItem.controlname
          }}">
          {{ equStopsContentItem.value }}
        </div>
      </div>
      } } } @if(equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "rdbutton") {
      @if(equStopsContentItem.controlname === "equipment-readiness-11-val" ||
      equStopsContentItem.controlname === "equipment-readiness-12-val"||
      equStopsContentItem.controlname === "equipment-readiness-adr-val" ||
      equStopsContentItem.controlname === "tegra-isgrade-issue" ||
      equStopsContentItem.controlname === "cutter-wheel-val" ||
      equStopsContentItem.controlname === "antiform-verify-istarget" ){
      <div class="col-5 radio-col-width p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            {{ equStopsContentItem.response }}
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center justify-content-end unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } @else{
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            {{ equStopsContentItem.response }}
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center justify-content-end unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } } @if(equStopsContentItem.ctrltype ==='blank' ) {
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">&nbsp;</div>
        </div>
      </div>
      } @if((equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text") && (
      equStopsContentItem.controlname==="tegra-settings-s-val" ||
      equStopsContentItem.controlname==="tegra-settings-s-val" ||
      equStopsContentItem.controlname === "tegra-settings-n-val"||
      equStopsContentItem.controlname === "projected-defect-val" ||
      equStopsContentItem.controlname==="freezer-comments"
      ||equStopsContentItem.controlname==="packaging-issue-cmt"
      ||equStopsContentItem.controlname==="peeler-bypass-value"||
      equStopsContentItem.controlname==="cutter-bladechange-date"||equStopsContentItem.controlname==="peeler-load-value"||equStopsContentItem.controlname==="peeler-comments"||equStopsContentItem.controlname==="peeler-load-comments"||
      equStopsContentItem.controlname=== "adr-settings-new-val" ||
      equStopsContentItem.controlname=== "adr-settings-old-val" ||
      equStopsContentItem.controlname=== "adr-settings-old-istarget-cmt" ||
      equStopsContentItem.controlname=== "adr-settings-new-istarget-cmt" ||
      equStopsContentItem.controlname=== "equipment-readiness-adr-cmt" ||
      equStopsContentItem.controlname=== "equipment-readiness-12-cmt" ||
      equStopsContentItem.controlname=== "equipment-readiness-11-cmt" ||
      equStopsContentItem.controlname=== "projected-defect-istarget-cmt"
      ||equStopsContentItem.controlname===
      "tegra-settings-n-istarget-cmt"||equStopsContentItem.controlname===
      "tegra-settings-s-istarget-cmt"||equStopsContentItem.controlname===
      "club-loss-percentage-istarget-cmt"||equStopsContentItem.controlname===
      "screen-size-cmt" || equStopsContentItem.controlname===
      "cutter-wheel-val-cmt" ||
      equStopsContentItem.controlname==="equipment-readiness-adr-val-cmt"
      ||equStopsContentItem.controlname==="equipment-readiness-12-val-cmt"
      ||equStopsContentItem.controlname==="equipment-readiness-11-val-cmt"||equStopsContentItem.controlname===
      "gb-ratio-istarget-cmt" || equStopsContentItem.controlname===
      "screen-size-cmt" || equStopsContentItem.controlname===
      "potato-defect-istarget-cmt" ||
      equStopsContentItem.controlname==="tegra-isgrade-issue-cmt" ||
      equStopsContentItem.controlname === "freezer-bypass-istarget-cmt" ||
      equStopsContentItem.controlname === "peeler-load-istarget-cmt" ||
      equStopsContentItem.controlname === "peeler-bypass-istarget-cmt" ||
      equStopsContentItem.controlname === "paperwork-issues-comments" ||
      equStopsContentItem.controlname === "batter-basket-istarget-cmt" ||
      equStopsContentItem.controlname === "ispkg-issue-cmt" ||
      equStopsContentItem.controlname === "broken-pallet-check-cmt" ||
      equStopsContentItem.controlname === "chep-pallet-count-cmt" ||
      equStopsContentItem.controlname==="fryer-oil-change-istarget-cmt" ||
      equStopsContentItem.controlname=="paperwork-issues-val-cmt" ||
      equStopsContentItem.controlname==="packaging-issues-val-cmt" ||
      equStopsContentItem.controlname==="fryer-crumb-press-istarget-cmt" ||
      equStopsContentItem.controlname==="fryer-grade-istarget-cmt" ||
      equStopsContentItem.controlname==="fryer-ffa-istarget-cmt" ||
      equStopsContentItem.controlname==="fryer-solid-istarget-cmt" ||
      equStopsContentItem.controlname==="fryer-salt-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-flume-salt-setting-istarget-cmt" ||
      equStopsContentItem.controlname==="fryer-color-istarget-cmt" ||
      equStopsContentItem.controlname==="blancher-ph-istarget-cmt" ||
      equStopsContentItem.controlname==="blancher-sugar-istarget-cmt" ||
      equStopsContentItem.controlname==="blancher-annatto-istarget-cmt" ||
      equStopsContentItem.controlname==="blancher-sapp-istarget-cmt" ||
      equStopsContentItem.controlname==="blancher-flume-salt-istarget-cmt" ||
      equStopsContentItem.controlname==="blancher-color-istarget-cmt" ||
      equStopsContentItem.controlname==="batter-hang-istarget-cmt" ||
      equStopsContentItem.controlname === "packaging-issues-comments"||
      equStopsContentItem.controlname==="broken-pallet-check-val-cmt" ||
      equStopsContentItem.controlname==="chep-pallet-count-val-cmt" ||
      equStopsContentItem.controlname==="palletizer-reciepe-cmt" ||
      equStopsContentItem.controlname==="chep-pallet-count-blank" ||
      equStopsContentItem.controlname==="palletizer-reciepe-val" ||
      equStopsContentItem.controlname ===
      "blancher-incoming-gravity-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-ysi-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-sodium-meter-istarget-cmt"
      || equStopsContentItem.controlname === "hydration-time-istarget-cmt" ||
      equStopsContentItem.controlname === "batter-pickup-istarget-cmt" ||
      equStopsContentItem.controlname === "batter-arizona-istarget-cmt" ||
      equStopsContentItem.controlname === "b6-cup-istarget-cmt" ||
      equStopsContentItem.controlname === "antiform-verify-istarget-cmt" ||
      equStopsContentItem.controlname === "total-line-rate-istarget-cmt" ||
      equStopsContentItem.controlname=="tunnel-retention-istarget-cmt" ||
      equStopsContentItem.controlname=="tunnel-freezer-istarget-cmt" ||
      equStopsContentItem.controlname=="tunnel-temp-istarget-cmt" ||
      equStopsContentItem.controlname === "YSI-sugar-rate-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-dry-matter-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-TGO/AGL-alert-threshold-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-AR-dryer-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-color-fryer-istarget-cmt" ||
      equStopsContentItem.controlname === "blancher-AR-fryer-istarget-cmt" )) {
      <div class="col p-1 text-widget target-container comment-width {{
          equStopsContentItem.controlname
        }}">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.response) {
            {{ equStopsContentItem.response }}
            }
          </div>
        </div>
      </div>

      } @if((equStopsContentItem.widget ==='input-table' &&
      equStopsContentItem.ctrltype === "text") && (
      equStopsContentItem.controlname==="tegra-screen-size-val" )) {
      <div class="col-6 p-1 target-container  comment-width {{
          equStopsContentItem.controlname
        }}">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-10">
            @if(equStopsContentItem.response) {
            {{ equStopsContentItem.response }}
            }
          </div>
        </div>
      </div>

      } @if(equStopsContentItem.ctrltype == "flagicon") {
      <span class="enableFlag-{{
          equStopsContentItem.response == true ? true : false
        }}">
      </span>
      } @if(equStopsContentItem.ctrltype === 'label' && equStopsContentItem.type
      ==='defect' ){
      <div class="row py-3 px-0 spacing-pt-10">
        <label class="safety-header">{{ equStopsContentItem.labeltext }}</label>
      </div>
      @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
      {
      <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
        [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
      } @else{
      <div class="row p-0 d-flex mt-0 zero-report-wrapper">
        <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
        0 {{ "app.previous_hoff.reported" | translate }}
      </div>
      } } 
      @if(equStopsContentItem.ctrltype === 'rdbutton' &&
      (equStopsContentItem.labeltext === "Any other Food Safety Quality(FSQ)
      observation or risk identified?"|| equStopsContentItem.labeltext === "Any other Food Safety/Quality (FSQ) observation or risk identified?")){
      <div class="row spacing-pt-10 ">
        <div class="col px-0">
          <label class="label">{{ equStopsContentItem.labeltext }}</label>
        </div>
      </div>
      <div class="row label-container any">
        <div class="col-6 response-sec" class="custom-{{equStopsContentItem.controlname}}">
          <p class="mb-0">{{ equStopsContentItem.response }}</p>
        </div>
      </div>
     } @if(equStopsContentItem.ctrltype === 'number') {
      @if(equStopsContentItem.controlname=== "tegra-screen-size-val"){
      <div class="col-5 radio-col-width1 p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            @if(equStopsContentItem.response || equStopsContentItem.response ==
            '0') {
            {{ equStopsContentItem.response }}
            }
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } @else{
      <div class="col p-1 target-container">
        <div class="col p-0 d-flex textbox-content textbox-wrapper">
          <div class="d-flex align-items-center col-9">
            @if(equStopsContentItem.response || equStopsContentItem.response ==
            '0') {
            {{ equStopsContentItem.response }}
            }
          </div>
          @if(equStopsContentItem.unit) {
          <div class="d-flex align-items-center unit-bg">
            {{ equStopsContentItem.unit }}
          </div>
          }
        </div>
      </div>
      } } }
    </div>
    }
  </div>
  } }}

  @if(positionCode=='PCMPN') {
    <div class="handoff-container quality-prev">
      <div class="row spacing-pt-20">
        <div class="col-6 items-section">
          
          <h3 class="mb-0">Items</h3>
          
          @for(item of qualityResponse; track item; let i= $index) { 
            @for(controlItem1 of qualityResponse[i]; track controlItem1) { 
              @if (!controlItem1.widget){ 
                @if(controlItem1.ctrltype === 'rdbutton' ||
          controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'select'
          || controlItem1.controlname === 'quality-variety'
          || controlItem1.ctrltype === 'number' || (controlItem1.ctrltype === 'text' && !controlItem1.controlname.includes("-cmt")) ) {
            @if(controlItem1.controlname != "quality-fsq"){
          <div class="row spacing-pt-20">
            <div class="col">
              <label class="label">{{ controlItem1.labeltext }}</label>
            </div>
          </div>
          <div class="row m-0">
            <div class="col response-sec">
              @if(controlItem1.ctrltype === 'date') {
              <p class="mb-0 d-flex justify-content-between">
                <span>{{
                  controlItem1.response != " " &&
                  moment(controlItem1.response, moment.ISO_8601, true).isValid()
                  ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                  : controlItem1.response
                  }}</span>
                @if(controlItem1?.unit){<span class="unit-txt">{{
                  controlItem1?.unit
                  }}</span>}
              </p>
              } @else if(controlItem1.ctrltype === 'number') {
              <p class="mb-0 d-flex justify-content-between">
                <span>{{ controlItem1.response }}</span>
                @if(controlItem1?.unit){<span class="unit-txt unit-txt1">{{
                  controlItem1?.unit
                  }}</span>}
              </p>
              }
              @else if(controlItem1.ctrltype === 'text') {
              <p class="mb-0 d-flex justify-content-between">
                <span>{{ controlItem1.response }}</span>
                @if(controlItem1?.unit){<span class="unit-txt">{{
                  controlItem1?.unit
                  }}</span>}
              </p>
              }@else {
                <p class="mb-0 d-flex justify-content-between">
                  <span>{{ controlItem1.response }}</span>
                </p>
              
              }
            </div>
          </div>
        }
          } } } } 
        </div>
        <div class="col-6 comments-section">
          
          <h3 class="mb-0 comments-header">Comments</h3>
        
          @for(item of qualityResponse; track item; let i= $index) { 
            @for(controlItem1 of qualityResponse[i]; track controlItem1) { 
              @if(!controlItem1.widget){
                  @if(controlItem1.ctrltype === 'text'){
                    <div class="row spacing-pt-20">
                      <div class="col p-0">{{ controlItem1.labeltext }}</div>
                    </div>
                    <div class="row custom-quality-class-{{controlItem1.controlname}}" [ngClass]="controlItem1.labeltext ? '' : 'spacing-pt-20'">
                      <div class="col response-sec">
                        <p class="mb-0 d-flex justify-content-between">
                          {{ controlItem1.response ? controlItem1.response : "" }}
                        </p>
                      </div>
                    </div>
                  } 
                } 
              } 
            }
        </div>
      </div>
    </div>
    <div class="handoff-container spl-frying-container quality-prev">
      <div class="row spacing-pt-20">
        @for(item of qualityResponse; track item; let i= $index) { @for
        (controlItem1 of qualityResponse[i+1]; track controlItem1) {
        @if(controlItem1.ctrltype === 'label' && controlItem1.type
        ==='defect' ){
        <div class="row py-3 px-0 spacing-pt-10">
          <label class="safety-header">{{ controlItem1.labeltext }}</label>
        </div>
        @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
        {
        <app-reported-defect-view [tableId]="'PHF_Qualitydefect_Table'" [selectedPlantCode]="selectedPlantCode"
          [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
        } @else{
        <div class="row p-0 d-flex mt-0 zero-report-wrapper">
          <span class="safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span>
          0 {{ "app.previous_hoff.reported" | translate }}
        </div>
        } }
        @if(controlItem1.ctrltype === 'rdbutton' &&
        (controlItem1.controlname === "quality-fsq")) {
        <div class="row spacing-pt-10">
          <div class="col px-0">
            <label class="label">{{ controlItem1.labeltext }}</label>
          </div>
        </div>
        <div class="row label-container">
          <div class="col-6 response-sec" class="custom-{{controlItem1.controlname}}">
            <p class="mb-0">{{ controlItem1.response }}</p>
          </div>
        </div>
        }
        }
        }
      </div>
    </div>
  }
}
</span>