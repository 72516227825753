<div class="handoff-container category-container">
  <div class="row ">
    <div class="col">
      @for(item of centerlineResponse; track item; let i= $index) {
      @for (controlItem1 of centerlineResponse[i]; track controlItem1) {
      @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
      <div class="row py-3 ">
        <label class="safety-header report-header">{{ "app.previous_hoff.reported_centerline_deviations" | translate
          }}</label>
      </div>


      @if(reportedCLDeviationArray && reportedCLDeviationArray.length > 0)
      {
      @for (cldeviationItem of reportedCLDeviationArray; track cldeviationItem; let k = $index) {
      <div class="row-11 defect-repoted-table-flex">
        <div class="col ">
          <table id="CLD_prv_DefectTable{{k+1}}" class="table report-defect-table">
            <tbody>
              <tr class="report-defect-tr">
                <td class="report-defect-td">{{ "app.previous_hoff.report_defect" | translate }}</td>
                <td class="defect-value">{{cldeviationItem.reportId}}</td>
                <td>
                  @if(cldeviationItem.isJustified == "true"){
                  <h4 class="defect-justified-table justified">{{ "app.previous_hoff.justified" | translate }}</h4>
                  }
                  @else{
                  <h4 class="defect-justified-table notJustified">{{ "app.previous_hoff.not_justified" | translate }}
                  </h4>
                  }
                </td>
              </tr>
              <tr>
                <td class="report-defect-td">{{ "app.previous_hoff.sensor" | translate }}</td>
                <td class="defect-value">{{cldeviationItem.sensorName}}</td>
                <td>
                  @if(cldeviationItem.deviationFlow == "LOW"){
                  <span class="defect-arrow-table low d-flex justify-content-end"><i class="bi bi-arrow-down"></i>
                  </span>
                  }
                  @else{
                  <span class="defect-arrow-table high d-flex justify-content-end"><i class="bi bi-arrow-up"></i>
                  </span>
                  }
                  <h4 class="defect-count-table ">{{cldeviationItem?.sensorCount| number:'0.3-3' | noComma}}
                  </h4>
                </td>
              </tr>
              <tr>
                <td class="report-defect-td">{{ "app.previous_hoff.cause" | translate }}</td>
                <td colspan="2" class="defect-value">{{cldeviationItem.cause}}</td>
              </tr>
              <tr>
                <td class="report-defect-td">{{ "app.previous_hoff.action_taken" | translate }}</td>
                <td colspan="2" class="defect-value">{{cldeviationItem.actionstaken}}</td>
              </tr>
              <tr>
                <td class="report-defect-td">{{ "app.previous_hoff.reported_by" | translate }}</td>
                @if(selectedPlantCode === 'MAT' || selectedPlantCode === 'HAR'){
                <td class="text-capitalize" colspan="2">{{cldeviationItem.reportedBy}}</td>
                }
                @else{
                <td class="text-capitalize">{{cldeviationItem.reportedBy}}</td>
                <td>
                  <h4 class="defect-date-table ">{{getDateFromEpoch(cldeviationItem.reportedDate)}}
                  </h4>
                </td>
                }
              </tr>
              @if(selectedPlantCode === 'MAT' || selectedPlantCode === 'HAR'){
              <tr>
                <td class="report-defect-td">{{ "app.previous_hoff.report_datetime" | translate }}</td>
                <td colspan="2" class="defect-value">{{getDateFromEpoch(cldeviationItem.reportedDate)}}</td>
              </tr>
            }
            </tbody>
          </table>
        </div>
        <div class="col-1 ps-1 flag-margin-top">
          <div id="CLD_prv_DefectTableFlag{{k+1}}" class="show-{{cldeviationItem.controls[1].response ==true?true:false}}  flagclass">
          </div>
        </div>
      </div>
      <div class="row-11 mb-3">
        <div class="col-11 p-0">
          <div class=" form-group p-0">
            <label for="nextSteps">{{ "app.previous_hoff.suggested_next_step" | translate }} </label>

            <div class="row comments-section m-0 ">
              <div class="col response-sec report-defect-table">
                <label id="cid_dev_prev_select_{{k+1}}" class="mb-0">{{cldeviationItem.controls[0].response}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      }

      }
      @else{
      <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
        <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
        "app.previous_hoff.reported" | translate }}
      </div>
      }
      }
      }
      }


    </div>

  </div>

</div>