import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cost-previous',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './cost-previous.component.html',
  styleUrl: './cost-previous.component.scss'
})
export class CostPreviousComponent {
  @Input() costResponse: any;
  @Input() isSupervisorPosition: any;
  @Input() isOperatorPosition: any;
  @Input() selectedPlantCode:any;
  @Input() positionCode: any;

}
