import { Component, Inject, OnInit, Output, inject } from '@angular/core';
import { HomeComponent } from '../../home/home.component';
import { Router } from '@angular/router';
import { RouterLink } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { MockApiService } from '../../../services/mock-api.service';
import { ShiftPosition } from '../../../models/shift-position';
import { TranslateService } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { JsonPipe } from '@angular/common';
import { UserSettingsComponent } from '../../user-settings/user-settings.component';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { AuthApiService } from '../../../services/auth-api.service';
import * as APP_CONST from '../../../../../app/constants/app.constants';
import { getPositionList } from '../../../../helper/getPositionList';
import { getUserDetails } from '../../../../helper/getUserDetails';
import { LanguageSelectionService } from '../../../services/language-selection.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ShowAlertMessageComponent } from '../../../../shared/components/modalpopup/show-alert-message/show-message.component';
import { DataService } from '../../../../shared/services/data-service.service';
import { FetchResponseApiService } from '../../../../features/DDDS/services/fetch-response-api.service';
import { getCurrentShiftDetails } from '../../../../helper/getCurrentShiftDetails';
import { ModalService } from '../../../../shared/components/modalpopup/modalservice';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    UserSettingsComponent,
  ],
  providers: [BsModalService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  private authapiService = inject(AuthApiService);
  private router = inject(Router);
  private languageSelectionService = inject(LanguageSelectionService);
  private fetchResponseApiService = inject(FetchResponseApiService);
  public isAutoLogout = false;
  subscription: Subscription;
  public finalResponse: any[] = [];
  public shiftPositionData: any = [];
  public userDetailsData: any;
  public selectedPlantCode: string = '';
  public selectedLang = 'en';
  public title = 'McCain-app';
  public selectedShiftPositions: any = [];
  public shiftPositions = new FormControl('');
  public shiftPositionList: any[] = [];
  public selectedItems: any;
  public dropdownSettings: any;
  public selectedPosition: ShiftPosition[] = [];
  public userName: string | undefined = '';
  public loginNameFromAd: string = '';
  public userInfoDetails: any;
  public isLangchange = false;
  public selectedLanguage: any;
  public modalRef = new BsModalRef<ShowAlertMessageComponent>;
  // Login Form Group
  public loginForm: FormGroup = new FormGroup({
    shiftPosition: new FormControl('', Validators.required),
  });
  public privilegeCode: string[] = [];

  constructor(private modalHelperService:ModalService,private modalService: BsModalService, private translateService: TranslateService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private msalBroadcastService: MsalBroadcastService, private authService: MsalService, private dataService: DataService) {
    this.userAzureADSignin();
    this.getUserDetails();
  }

  ngOnInit(): void { 
    this.setLanguage();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      itemsShowLimit: 3,
      limitSelection: 3,
      enableCheckAll: false,
    };
    this.isAutoLogout = false;
    this.subscription = this.dataService.autoLogoutStatus.subscribe((data: any) => {
      this.isAutoLogout = data.isAutoLogout
    if(this.isAutoLogout == true)
      {
        sessionStorage.setItem(
          'CurrentShiftInfo',
          JSON.stringify(null)
        );
        sessionStorage.setItem(
          'autoLoggedOut',
          JSON.stringify(true)
        );
      }
    }); 
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removeNotification(): void { 
    this.isAutoLogout = false; 
  }

  //fetch username from Azure AD
  userAzureADSignin(): void {
    let activeAccount = this.authService.instance?.getAllAccounts()?.[0];
    let inputName = activeAccount?.name;
    let nameArr:any = inputName?.split(',');
    let camelCaseop = nameArr?.map((str:any) =>this.camelCase(str));
    let reversedString:any = camelCaseop?.reverse().join(' ');
    this.userName = reversedString;
    this.loginNameFromAd = activeAccount?.username;
  }
  camelCase(str:any) {
    return str.charAt(0).toUpperCase() +str.slice(1).toLowerCase() ;
  }

  setLanguage() {
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage');
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
    }
    if (this.selectedLanguage && this.selectedLanguage !== 'undefined') {
      if (this.selectedLanguage !== getUserDetails(this.userDetailsData).languageCode) {
        this.isLangchange = true;
      }
      this.languageSelectionService.setLanguage(this.userDetailsData.result?.preLangCode);
    } else {
      if (this.userDetailsData && this.userDetailsData.result) {
        this.languageSelectionService.setLanguage(this.userDetailsData.result?.preLangCode);
      } else {
        this.languageSelectionService.setLanguage('en');
      }

    }
  }

  onItemSelect(item: any) {
    this.isAutoLogout=false;
  }

  // onLogin(): void {
  //   this.router.navigate(['/landing-page/current-handoff']);
  // }

  getPositionData() {
    this.userInfoDetails = getUserDetails(this.userDetailsData);
    sessionStorage.setItem("UserRolesList",this.userInfoDetails.roles)
    if (this.selectedLanguage && this.selectedLanguage !== 'undefined') {
      this.userInfoDetails.languageCode = this.selectedLanguage;
    }
    this.authapiService
      .getPositions(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.SETTINGS_SUBFEATURE,
        getUserDetails(this.userDetailsData)
      )
      .subscribe({
        next: (data: any[]) => {
          this.shiftPositionData = data;
          this.shiftPositionList = getPositionList(this.shiftPositionData);
          this.selectedItems = this.selectPreviousShift();
          this.dataService.pisitionInfoList.next(this.shiftPositionList);
        },
      });
  }

  selectPreviousShift() {
    const userDetailsData = getUserDetails(this.userDetailsData);
    let previousSelectedShifts: any = [];
    if (userDetailsData.positionId1 > 0) {
      let tempPosition = this.getPreviousSelectedShift(userDetailsData.positionId1);
      if (tempPosition) { previousSelectedShifts.push(tempPosition); }

    }
    if (userDetailsData.positionId2 > 0) {
      let tempPosition = this.getPreviousSelectedShift(userDetailsData.positionId2);
      if (tempPosition) { previousSelectedShifts.push(tempPosition); }
    }
    if (userDetailsData.positionId3 > 0) {
      let tempPosition = this.getPreviousSelectedShift(userDetailsData.positionId3);
      if (tempPosition) { previousSelectedShifts.push(tempPosition); }
    }
    return previousSelectedShifts;
  }

  getPreviousSelectedShift(CurrentPositionId: any) {
    const selectedItem = this.shiftPositionList.find(
      (x) => x.id == CurrentPositionId
    );
    return selectedItem;
  }

  getUserDetails() {
    this.authapiService
      .getUserDetails(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.USER_SUBFEATURE,
        APP_CONST.DEFAULT_LANG,
        this.loginNameFromAd
      )
      .subscribe({
        next: (data: any[]) => {
          this.userDetailsData = data;
          this.userDetailsData.userProfileName = this.userName;
          this.userInfoDetails = getUserDetails(
            this.userDetailsData
          );
          this.selectedPlantCode = this.userInfoDetails.plantCode;
          sessionStorage.setItem(
            'userDetails',
            JSON.stringify(this.userDetailsData)
          );
          sessionStorage.setItem(
            'selectedPlantCode',
            JSON.stringify(this.selectedPlantCode)
          );
          sessionStorage.setItem(
            'Apitoken', JSON.stringify({
              token: this.userInfoDetails.apiToken, tokenExpiryTime: this.userInfoDetails.tokenExpiryTime
            }));
        },
        complete: () => {
          this.setLanguage();
          this.getPositionData();
        },
      });
  }

  getUserSelectedShifts() {
    let positionIdArray: any = [0, 0, 0];
    sessionStorage.setItem(
      'SelectedItems',
      JSON.stringify(this.selectedItems)
    );

    for (let i = 0; i < this.selectedItems?.length; i++) {
      positionIdArray[i] = this.shiftPositionList.find(
        (x) => x.name === this.selectedItems[i].text
      )?.id;
    }
    return positionIdArray;
  }

  getHandoffData(userName:any,roles:any): void {
    this.fetchResponseApiService.getShiftTimesByPlantId(this.userInfoDetails)
      .subscribe({
        next: (data: any) => {
          if (data.isSuccess === APP_CONST.SUCCESS_TRUE) {
            let shiftInfo = getCurrentShiftDetails(data);
            sessionStorage.setItem(
              'CurrentShiftInfo',
              JSON.stringify(shiftInfo)
            );
          }
          else {
            let shiftInfo = null;
            sessionStorage.setItem(
              'CurrentShiftInfo',
              JSON.stringify(shiftInfo)
            );
          }
        },
        complete: () => {
          this.dataService.userProfileInfo.next({ userName: userName, roles: roles });
              
        }
         
      });
  }

  onSubmit(): void {
    sessionStorage.setItem(
      'autoLoggedOut',
      JSON.stringify(false)
    );
    let isSuccess = false;
    let response: any;
    this.userInfoDetails = getUserDetails(this.userDetailsData);
    [
      this.userInfoDetails.positionId1,
      this.userInfoDetails.positionId2,
      this.userInfoDetails.positionId3,
    ] = this.getUserSelectedShifts();

    this.authapiService
      .saveShiftPosition(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.SETTINGS_SUBFEATURE,
        this.userInfoDetails,
        this.userName,
        !APP_CONST.IS_SETTINGS
      )
      .subscribe({
        next: (position) => {
          this.selectedPosition = position;
          isSuccess = position.isSuccess;
          response = position;
        },
        complete: () => {
          if (isSuccess === APP_CONST.SUCCESS_TRUE) {
            if (this.loginForm.valid) {
              this.getHandoffData(this.userName,this.userInfoDetails.roles); 
             // this.dataService.userProfileInfo.next({ userName: this.userName, roles: this.userInfoDetails.roles });
              let selectedPositions = JSON.parse(JSON.stringify(this.selectedItems));
              if (selectedPositions && selectedPositions.length > 0) {
                for (let i = 0; i < selectedPositions.length; i++) {
                  if (selectedPositions[i].id) {
                    selectedPositions[i] = this.getPreviousSelectedShift(selectedPositions[i].id);
                  }
                }
                sessionStorage.setItem(
                  'SelectedItemsForKPI',
                  JSON.stringify(selectedPositions)
                );
              } 
              let kpiIndex=-1;
              let isPlantLead=false;
              let isPEML=false;
              let isPKGML = false;
              let isPROML = false;
              let isPlp = false;
              let isHarnesMaintenanceLead = false;
              let isHarnesPlanteLead = false;
              let redirectTOReport =false;
              let redirectToDDH = false;
              if (selectedPositions && selectedPositions.length > 0) { 
               
                kpiIndex  =selectedPositions.findIndex((x: { value: string | string[]; }) => x.value.includes('KPIV'));
                isPlantLead = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=='PLDR' || x.value=='PLDR2' || x.value=='SNTLDR' || x.value=='BLRMLDR' || x.value=='PLTMGR' || x.value=='PRDMGR' || x.value=='SFTLDR' || x.value=='SFTMGR' || x.value=='QLTLDR' || x.value=="MTSPL" || x.value=='MTSSR' || x.value=='PRENGG' || x.value=='PWRENGG' || x.value=='PWRENGG2' || x.value=='PLTMGR' || x.value=='QLTMGR' || x.value=='HACCP' || x.value=='SFTMGR1' || x.value=='SFTMGR2' || x.value=='SFTMGR3' || x.value=='SFTMGR4' || x.value=='SFTCORD' || x.value=='SNSFTCORD' || x.value=='OCCHLTHLEAD' || x.value=='RWCOR' || x.value=='SNTSPR' || x.value=='SNTMGR' || x.value=='FSQMGR' || x.value=='QASPR' || x.value=='SSMGR' || x.value=='SFTCDR' || x.value=='PTMGR' || x.value=='ANSCT' || x.value=='ANQLT') >= 0;
                isPEML = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=="PEML") >= 0;
                isPKGML = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=="PKGML") >= 0;
                isPROML = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=="PML") >= 0;
                isPlp = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=="PLPCS" || x.value=="PF" || x.value=="PLPKG" || x.value=="PRDMGRFRY" || x.value=="CNTIMPRMGR" || x.value=="MAINTMGR" || x.value=="QLTMGR" || x.value=="RPP" || x.value=="STRSUPMGR" || x.value=="HRBP" || x.value=="QLTCNTLR" || x.value=="CHDMAINT" ) >= 0;
                isHarnesMaintenanceLead = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=='MLPFL1' || x.value=="MLPRFL2"||x.value=='MLPKFL1' ||x.value=='MLPKFL2' ||x.value=='MLFLK' ||x.value=='RZEPL1' ||x.value=='RZEPL2' ||x.value=='RZTOL1' ||x.value=='RZTOL2' ||x.value=='RZDBL1' ||x.value=='RZDBL2' ||x.value=='RZPKGL1' ||x.value=='RZPKGL2' ||x.value=='NTPRFL1' ||x.value=='NTPRFL2' ||x.value=='NTPKL1' ||x.value=='NTPKL2' ||x.value=='NTFL' || x.value == 'RSPLR') >= 0;
                isHarnesPlanteLead = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=='PLTFL1' || x.value=="PLTFL2"||x.value=='PLTPL1' ||x.value=='PLTPL2' ||x.value=='PLTFL' || x.value=='PLT1' || x.value=='PLT2' || x.value=='PLT3') >= 0;
                redirectTOReport = selectedPositions.length == 1 &&  selectedPositions.findIndex((x: { value: string; })=>x.value=="PCL"|| x.value=="MDI"|| x.value=="LPFL1"|| x.value=="LPFL1"|| x.value=="LLPKFL1"|| x.value=="LLPKFL2"|| x.value=="LLFLK"|| x.value=="PCLPRFL1"|| x.value=="PCLPRFL2"|| x.value=="PCLPKFL1"|| x.value=="PCLPKFL2"|| x.value=="PCLFL"|| x.value=="MDIFL1"|| x.value=="MDIFL2"|| x.value=="MDIPL1"|| x.value=="MDIPL2"|| x.value=="MDIFL" || x.value=="MDIFRTS" || x.value=="SPLPCL"  || x.value=="MDIMGR" || x.value=="PCLOSIPI" || x.value=="PCLFRYL" || x.value=="PCLSPECL" || x.value=="PCLPACK" || x.value=="PCLMGR" || x.value=="LCLD" || x.value=="PACKMGR" || x.value=="PRDMGRSPEC" || x.value=="CNTIMPRCORD" || x.value=="PRCENGG" || x.value=="PRCMGR") >= 0;
              } 
              redirectToDDH = isPlantLead || isPEML || isPKGML || isPROML || isPlp || isHarnesMaintenanceLead || isHarnesPlanteLead;
              this.setRouting(kpiIndex >= 0 ?true:false,redirectToDDH,redirectTOReport);
            }
          }
          else if (isSuccess === APP_CONST.SUCCESS_FALSE) {
            const initialState: any = {
              title: this.translateService.instant('app.shift_position_selection.shift_already_in_use'),
              message: response.message,
              ok: this.translateService.instant('app.shift_position_selection.confirm_ok'),
            };
            this.modalRef = this.modalService.show(ShowAlertMessageComponent, {
              initialState: initialState,
              class: 'modal-dialog-centered'
            });
            this.modalHelperService.addModalRef(this.modalRef);

          }
        },
        error: (response) => {

        }
      });
  }
  setRouting(isKpi:any,redirectToDDH:any,redirectTOReport:any) {
    let userDetails: any = sessionStorage.getItem('userDetails');
    let privCode = JSON.parse(userDetails);
    this.privilegeCode = this.removeDuplicates(privCode.result.privileges);
    if (this.privilegeCode.length > 0) {
      if (this.privilegeCode.includes('DDS_KPIVW') && isKpi) {
        this.router.navigate(['/huddle-board']);
      }
      else if (this.privilegeCode.includes('DDH_DEFCT_SOR') && redirectToDDH) {
        this.router.navigate(['/landing-page/defect-log']);
      }
      else if (this.privilegeCode.includes('DDS_REPORT') && redirectTOReport) {
        this.router.navigate(['/landing-page/reports']);
      }
      else if (this.privilegeCode.includes('DDS_PREHOF')) {
        this.router.navigate(['/landing-page/previous']);
      }
      else if (this.privilegeCode.includes('DDS_CURHOF')) {
        this.router.navigate(['/landing-page/current']);
      }
      else {
        this.router.navigate(['/landing-page/dds-meeting']);
      }
    }
  }
  removeDuplicates(arr: any) {
    let unique: any = [];
    arr.forEach((element: any) => {
      if (!unique.includes(element)) {
        unique.push(element);
      }
    });
    return unique;
  }
}
