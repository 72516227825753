import { Component, OnInit, inject, OnDestroy } from '@angular/core';
import { HandoffHeaderComponent } from '../handoff-header/handoff-header.component';
import { getUserDetails } from '../../../../../../helper/getUserDetails';
import { FetchResponseApiService } from '../../../../services/fetch-response-api.service';
import { SummaryViewComponent } from './components/summary-view/summary-view.component';
import { DetailedViewComponent } from './components/detailed-view/detailed-view.component';
import { CommonModule } from '@angular/common';
import { DataService } from '../../../../../../shared/services/data-service.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-previous-handoff',
  standalone: true,
  imports: [CommonModule, HandoffHeaderComponent, SummaryViewComponent, DetailedViewComponent,TranslateModule],
  templateUrl: './previous-handoff.component.html',
  styleUrl: './previous-handoff.component.scss'
})
export class PreviousHandoffComponent implements OnInit, OnDestroy {
  private fetchResponseApiService = inject(FetchResponseApiService);
  public currentHandOffDetails: any = [];
  public previousHandOffDetails: any = [];
  public templateDetails: any;
  public result: any;
  public reportedRTDuetDefectObj:any;
  public reportedCLDeviationArray: any = [];
  public SelectedPosition: any;
  public toggleSummaryDetailView = false;
  public selectedTab: any;
  public userDetailsData: any;
  public positionId: number = 24;
  public categoryData: [] = [];
  public reportedDefectArray: [] = [];
  public createdBy: any;
  public responseData: any;
  public role?: string;
  public showPrevious: boolean = false;
  public positionNamesSelected: [] = [];
  public selectedPoistionId?: number;
  public positionList: any;
  public positionName?: string;
  public isLoading?: boolean;
  public positionCode?:any;
  public isCIDCompleted=false;
  public userInfoDetailsSession:any;
  public selectedPlantCode:any;
  private getResponseDetailsSubscription: any;
  private getTemplateDetailsSubscription: any;
  private getHandoffDefectPreviousResponseSubscription: any;
  private getHandoffCLDeviationResponseSubscription: any;
  private GetCidStatusCompletionListSubscription: any;
  private GetEquipmentDataRTDuetSubscription: any;

  constructor(private alertService: DataService ) {
  }
  ngOnInit() {
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
      this.userInfoDetailsSession = getUserDetails(
        this.userDetailsData
      );
      this.selectedPlantCode = this.userInfoDetailsSession.plantCode;
    }
   

  }

  getFirstPosition(id: any) {
    this.isLoading = true;
    if (id) {
      this.selectedPoistionId = id;
      this.getTemplateData(this.selectedPoistionId);
    }
  }

  getData(id: any) {
    this.isLoading = true;
    this.getResponseDetailsSubscription = this.fetchResponseApiService.getResponseDetails(
      getUserDetails(this.userDetailsData),
      id
    ).subscribe({
      next: (data: any) => {
        if (data.result != null) {
          this.responseData = JSON.parse(data.result);
          this.createdBy = this.responseData.createdby;
          this.categoryData = this.responseData.dddshandofftemplate.categories;
          this.role = this.responseData.rolename;
          this.positionNamesSelected = this.responseData.positionnames;
          this.positionCode = this.responseData.positioncode;
          this.isLoading = false;
        }
        else {
          this.isLoading = false;
        }
      },
      error: (err: any) => {
        this.isLoading = false;
        this.alertService.pushAlertMessage("Something went wrong while fetching Handoff response  data", "error");
      }
    });
  }

  getTemplateData(id: any) {
    this.isLoading = true;
    this.getTemplateDetailsSubscription = this.fetchResponseApiService.getTemplateDetails(
      getUserDetails(this.userDetailsData),
      id
    ).subscribe({
      next: (data: any) => {
        if (data.result != null) {
          this.currentHandOffDetails = JSON.parse(data.result);
          this.templateDetails = this.currentHandOffDetails;
          this.isLoading = false;
          this.getData(this.selectedPoistionId);
        }
        else {
          this.isLoading = false;
        }
      },
      complete:() =>{
        this.getReportedDefects();
        this.GetCidStatus(id);
        this.GetEquipmentDataRTDuet(id);
        this.getCLDReportedDefects(id);

      },
      error: (err: any) => {
        this.isLoading = false;
        this.alertService.pushAlertMessage("Something went wrong while fetching Handoff data", "error");
      }
    });
  }


  
  GetEquipmentDataRTDuet(id: any){
    this.GetEquipmentDataRTDuetSubscription = this.fetchResponseApiService.GetEquipmentDataRTDuet(
      getUserDetails(this.userDetailsData),
      id,
      false 
    ).subscribe({
      next: (data: any) => {
        if (data.isSuccess == true && data.result && data.result) {

          if(data.result.equipmentStops)
            {

            this.reportedRTDuetDefectObj=data.result;          
            }
            else{
              this.reportedRTDuetDefectObj=data.result;     
            }
        }
        else {
           
        }
      },
      complete: () => {
      },
      error: (err: Error) => {
        this.alertService.pushAlertMessage("Something went wrong while fetching EStop Data", "error");
      }
    });
  }

  getCLDReportedDefects(id:any) {
    this.reportedCLDeviationArray = [];
    this.isLoading = true;
    this.getHandoffCLDeviationResponseSubscription = this.fetchResponseApiService.getHandoffCLDeviationResponse(
      getUserDetails(this.userDetailsData),
      id,
      false

    ).subscribe({
      next: (data: any) => {

        let cldeviationData =data;
        if (cldeviationData && cldeviationData?.deviation?.length > 0) {
          this.reportedCLDeviationArray = cldeviationData.deviation;
        }
        else {
          this.reportedCLDeviationArray = [];
        }        
        this.isLoading = false;
      },
      error: (err: Error) => {
        this.reportedCLDeviationArray = [];
        this.isLoading = false;
        this.alertService.pushAlertMessage("Something went wrong while fetching CLDeviation data", "error");
      }
    });
  }

  GetCidStatus(id: any) { 
    this.GetCidStatusCompletionListSubscription = this.fetchResponseApiService.GetCidStatusCompletionList(
      getUserDetails(this.userDetailsData),
      id,
      false

    ).subscribe({
      next: (data: any) => {
        if (data.isSuccess == true && data.result && data.result.length > 0) { 
          this.isCIDCompleted=false;
          if(data.result[0].routeStatusCode =='CBO')
            {
              this.isCIDCompleted=true;
            } 
        }
        else{
          this.isCIDCompleted=false;
        } 
      },
      complete: () => { 
      },
      error: (err: Error) => { 
        this.isCIDCompleted=false;
        this.alertService.pushAlertMessage("Something went wrong while fetching CID status", "error");
      }
    });
  }


  getReportedDefects() {
    this.reportedDefectArray=[];
    this.isLoading=true;
    this.getHandoffDefectPreviousResponseSubscription = this.fetchResponseApiService.getHandoffDefectPreviousResponse(
      getUserDetails(this.userDetailsData),
      this.selectedPoistionId

    )?.subscribe({
      next: (data: any) => {
       
       let reportDefectData=  data.result;
       if(reportDefectData && reportDefectData.length > 0)
        {
          this.reportedDefectArray=reportDefectData;
        }
        else{
          this.reportedDefectArray=[];
        }
       
       this.isLoading=false;
      },
      error: (err: Error) => {
        this.reportedDefectArray=[];
        this.isLoading=false;
        this.alertService.pushAlertMessage("Something went wrong while fetching Defect data", "error");
      }
    });
  }

  getSelectedPosition(event: any) {
    this.SelectedPosition = event;
    this.positionName = this.SelectedPosition;
    this.fetchSelectedPositonData(this.SelectedPosition);
  }

  fetchSelectedPositonData(selectedPosition: any): any {
    let data;
    if (sessionStorage.getItem('SelectedItems') !== null) {
      data = JSON.parse(
        sessionStorage.getItem('SelectedItems') || ''
      );
    }
    data.forEach((item: any) => {
      if (selectedPosition === item.text) {
        this.selectedPoistionId = item.id;
      }
    });
    if (this.selectedPoistionId) {
      this.responseData = null;
      this.currentHandOffDetails = null;

      this.getTemplateData(this.selectedPoistionId);

    }
  }

  getSummaryDetailView(event: any) {
    this.toggleSummaryDetailView = event;
  }

  getpositionName(event: any) {
    this.positionName = event;
  }

  ngOnDestroy(): void {
    if (this.getResponseDetailsSubscription) {
      this.getResponseDetailsSubscription.unsubscribe();
    }
    if (this.getTemplateDetailsSubscription) {
      this.getTemplateDetailsSubscription.unsubscribe();
    }
    if (this.getHandoffDefectPreviousResponseSubscription) {
      this.getHandoffDefectPreviousResponseSubscription.unsubscribe();
    }
    if (this.getHandoffCLDeviationResponseSubscription) {
      this.getHandoffCLDeviationResponseSubscription.unsubscribe();
    }
    if (this.GetCidStatusCompletionListSubscription) {
      this.GetCidStatusCompletionListSubscription.unsubscribe();
    }
    if (this.GetEquipmentDataRTDuetSubscription) {
      this.GetEquipmentDataRTDuetSubscription.unsubscribe();
    }
  }
}
