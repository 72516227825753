import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ReportedDefectUiComponent } from '../../../reported-defect-ui/reported-defect-ui.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatetimeFormatPipe } from '../../../../../../../../../../shared/pipes/datetime-format.pipe';
@Component({
  selector: 'app-safety-previous',
  standalone: true,
  imports: [CommonModule,ReportedDefectUiComponent,TranslateModule,DatetimeFormatPipe],
  templateUrl: './safety-previous.component.html',
  styleUrl: './safety-previous.component.scss'
})
export class SafetyPreviousComponent implements OnChanges{
  @Input() safetyResponse:any;
  @Input() roleName: any;
  @Input() isSupervisorPosition: any;
  @Input() isOperatorPosition: any;
  @Input() positionCode: any;
  @Input() reportedDefectArray: any;
  @Input() selectedPlantCode:any;
  public reportedCategoryDefectArray:any=[];
  

  ngOnChanges() {
     
    if (this.reportedDefectArray && this.reportedDefectArray.length > 0) {
      this.reportedCategoryDefectArray =this.reportedDefectArray.filter((eachItem:any)=>eachItem.impact?.code=='SFT');
     }
  }

  getIndex(index: number, safetyResponse: any ): number {
    console.log(safetyResponse);
    
   if(this.positionCode === 'CDR' && this.selectedPlantCode === 'MAT' && safetyResponse[0][0].controlname === 'people-safety-unsafe') {
    return index;
    }else {
      return index + 1;
    }
  }

}
