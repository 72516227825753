import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from '../../../core/models/user-details';
import { getHttpHeaders } from '../../../http/header-config';
import { getHttpBodyData } from '../../../http/body-config';
import * as APP_CONST from '../../../../app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class FetchResponseApiService {

  private http = inject(HttpClient);
  constructor() { }

  getShiftTimesByPlantId(
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetShiftTimesByPlantIdAsync?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  getResponseDetails(
    userDetails: UserDetails,
    positionId1: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetHandOffResponse?plantId=${userDetails.plantId}&positionId=${positionId1}&isCurrentHandOff=false`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }

  getSavedHandoffDetails(
    userDetails: UserDetails,
    positionId1: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetHandOffResponse?plantId=${userDetails.plantId}&positionId=${positionId1}&isCurrentHandOff=true`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  getTemplateDetails(
    userDetails: UserDetails,
    positionId1: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetHandoffTemplate?plantId=${userDetails.plantId}&positionId=${positionId1}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  getMasterDetailsForhoff(
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetHandoffMasterDataAsync?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  saveHandoff(
    userDetails: UserDetails,
    response: any
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dds/api/HandOff/saveCurrentHandOffResponse`,

      getHttpBodyData({
        handOffVlaue: JSON.stringify(response)
      }), getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  getKpiResponseDetails(
    userDetails: UserDetails,
    positionId1: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetKpiViewresponse?plantId=${userDetails.plantId}&lineId=${positionId1}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  } ///
  getRecapViewResponseDetails(userDetails: UserDetails, positionId1: any): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HuddleBoard/GetRecapDetails?plantId=${userDetails.plantId}&lineId=${positionId1}&tabname=recap`,
      getHttpHeaders({
        loginName: userDetails.loginName,
        languageCode: userDetails.languageCode
      })
    );
  }
  UpdatHuddleBoardItemStatus(userDetails: UserDetails, defect: any): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dds/api/HuddleBoard/UpdatHuddleBoardItemStatus`,
      getHttpBodyData(defect),
      getHttpHeaders({
        loginName: userDetails.loginName,
        languageCode: userDetails.languageCode
      })
    );
  }
  getLineDetails(
    userDetails: UserDetails,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetLines?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  /**
   * MDI PCL Reports Section 
   * Get MDI PCL Request 
   * @param userDetails 
   * @returns 
   */
  getMeetingLineDetails(
    userDetails: UserDetails,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetActionItemDdsMeetingLines?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getMdiPclReport(
    userDetails: UserDetails,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOffReports/GetHandOffPCLRequestList?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getMdiPclReportItem(
    userDetails: UserDetails,
    response: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOffReports/GetHandOffPCLRequestItem?plantId=${userDetails.plantId}&date=${response.date}&type=${response.type}&shift=${response.shift}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  createReport(
    userDetails: UserDetails,
    response: any
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dds/api/HandOffReports/CreateHandOffReportRequest`,
      getHttpBodyData(response), getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }
  downloadReport(
    userDetails: UserDetails,
    response: any
  ): Observable<Blob> {
    return this.http.get(`${APP_CONST.API_URL}/dds/api/HandOffReports/GetDownloadPCLReportFile?id=${String(response.id)}&plantCode=${userDetails.plantCode}`,
      {
        headers: {
          languageCode: userDetails.languageCode,
          loginName: userDetails.loginName
        },
        responseType: 'blob'
      }
    );
  }
  getMdiPclReportShifts(
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/auth/api/Settings/GetShiftByPlantId?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getActionItemStatus(
    userDetails: UserDetails,
    isDdsMeeting: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetActionItemStatus?plantId=${userDetails.plantId}&isDdsMeeting=${isDdsMeeting}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  saveMeetingDetails(
    userDetails: UserDetails,
    response: any
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dds/api/DdsMeeting/SaveUpdateDdsMeetingLines`,
      getHttpBodyData(response), getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }
  getUsers(
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetUsers?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        loginName: userDetails.loginName,
      })
    );
  }

  getActionItemsCount(
    userDetails: UserDetails, reqParam: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetActionItemsCount?plantId=${userDetails.plantId}&lineId=${reqParam.lineId}&categoryName=${reqParam.categoryName}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }

  getActionLogDetails(
    userDetails: UserDetails,
    positionId1: any,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HuddleBoard/GetActionLogDetails?plantId=${userDetails.plantId}&lineId=${positionId1}&tabname=actionlog`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }

  getHandoffCLDeviationResponse(
    userDetails: UserDetails,
    positionId: any,
    iscurrenthoff:any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/DeviationRouteStatus/GetReportedDeviations?plantId=${userDetails.plantId}&positionId=${positionId}&userId=${userDetails.userId}&languageCode=${userDetails.languageCode}&isCurrentHandOff=${iscurrenthoff}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  getHandoffDefectResponse(
    userDetails: UserDetails,
    positionId: any,

  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/ddh/api/Defects/GetDefectsByPositionId?plantId=${userDetails.plantId}&positionId=${positionId}&isCurrentHandOff=true`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }
  getHandoffDefectPreviousResponse(
    userDetails: UserDetails,
    positionId: any,

  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/ddh/api/Defects/GetDefectsByPositionId?plantId=${userDetails.plantId}&positionId=${positionId}&isCurrentHandOff=false`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  saveOrUpdateActionItem(
    userDetails: UserDetails, reqParam: any, isDefect: any, huddleBoardItemId:any
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dds/api/ActionItem/SaveOrUpdateActionItem?isDefect=${isDefect}&huddleBoardItemId=${huddleBoardItemId}`,
      getHttpBodyData(reqParam),
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }

  saveActionItem(
    userDetails: UserDetails, reqParam: any, isDefect: any, huddleBoardItemId:any
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dds/api/ActionItem/SaveActionItem?isDefect=${isDefect}&huddleBoardItemId=${huddleBoardItemId}`,
      getHttpBodyData(reqParam),
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }

  updateActionItem(
    userDetails: UserDetails, reqParam: any, isDefect: any, huddleBoardItemId:any
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dds/api/ActionItem/UpdateActionItem?isDefect=${isDefect}&huddleBoardItemId=${huddleBoardItemId}`,
      getHttpBodyData(reqParam),
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  /**
   * DDS Meeting 24 hr Recap Section
   * 
   */
  getDDSRecapView(
    userDetails: UserDetails,
    resParam: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/DdsMeeting/GetDdsMeeting?plantId=${userDetails.plantId}&lineCode=${resParam.lineId}&meetingDate=${resParam.date}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getActionCount(
    userDetails: UserDetails,
    resParam: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetActionItemsCount?plantId=${userDetails.plantId}&lineId=${resParam.lineId}&categoryName=${resParam.name}&createdDate=${resParam.createdDate}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getLineId(
    userDetails: UserDetails,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetActionItemDdsMeetingLines?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getActionItemListMeeting(
    userDetails: UserDetails,
    resParam: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetActionItems?plantId=${userDetails.plantId}&lineId=${resParam.lineId}&categoryName=${resParam.categoryName}&subCategoryName=${resParam.subCategoryName}&createdDate=${resParam.createdDate}&lineCode=${resParam.lineCode}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  /**
   * Escalated Huddle Items Count
   */
  getEscalatedHuddleBoardCount(
    userDetails: UserDetails,
    lineId: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/ActionItem/GetEscalatedHuddleItemsCount?plantId=${userDetails.plantId}&lineId=${lineId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  /**
   * Escalated Huddle Items Section
   */
  getEscalatedHuddleItems(
    userDetails: UserDetails,
    lineId: any,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/DdsMeeting/GetEscalatedHuddleItems?plantId=${userDetails.plantId}&lineId=${lineId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getActionPlanDetails(
    userDetails: UserDetails,
    lineCode: any,
    sortingOrder: any,
    createdDate: any,
    assignToId: any,
    isDeleted: any,
    sortingField?: any
  ): Observable<any> {
    let url = `${APP_CONST.API_URL}/dds/api/ActionItem/GetActionItems?plantId=${userDetails.plantId}&lineCode=${lineCode}&sortingOrder=${sortingOrder}&createdDate=${createdDate}&assignToId=${assignToId}&isDeleted=${isDeleted}`;
    if (sortingField && sortingField == 'updatedDate') {
      url = url + `&sortingField=${sortingField}`;
    }
    return this.http.get(url,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }

  public GetCidStatusCompletionList(
    userDetails: any,
    positionId: any,
    iscurrenthoff:any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/CidRouteStatus/GetCidStatusList?plantId=${userDetails.plantId}&position=${positionId}&languageCode=${userDetails.languageCode}&isCurrentHandOff=${iscurrenthoff}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }

  public GetEquipmentDataRTDuet(
    userDetails: any,
    positionId: any,
    iscurrenthoff:any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetEquipmentStops?plantId=${userDetails.plantId}&positionId=${positionId}&isCurrentHandOff=${iscurrenthoff}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName,
      })
    );
  }
  getSupervisorsForhoff(
    userDetails: UserDetails,
    positionId: any,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dds/api/HandOff/GetSupervisors?plantId=${userDetails.plantId}&positionId=${positionId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }
}
