@if(isSupervisorPosition || (isOperatorPosition && (positionCode === 'PKGL1' || positionCode === 'PKGL2' || positionCode ==='LPL1' || positionCode ==='LPL2'))) {
@if(positionCode === 'PLH') {
<div class="handoff-container delivery-prev">
    <div class="row spacing-pt-20">
        <div class="col-6 items-section">
            <h3 class="m-0 ">{{ "app.previous_hoff.items" | translate }}</h3>

            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if((controlItem1.ctrltype === 'number' && !controlItem1.widget) || controlItem1.ctrltype === 'rdbutton' ||
            (controlItem1.ctrltype === 'date' && controlItem1.controlname !== "delivery-possible-defrost-time") ) {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date'|| (controlItem1.ctrltype === 'number' && !controlItem1.widget)
                    ) {
                    @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                    "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                    controlItem1.controlname === "delivery-target-shift" || controlItem1.controlname ===
                    "delivery-Volume" || controlItem1.controlname === "delivery-consumable-SKU") {
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                        <div class="col d-flex justify-content-end ">
                            @if(controlItem1.unit === 'KG/HR') {
                            <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                            } @else if(controlItem1.unit === "MIN") {
                            <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                            } @else if(controlItem1.unit === "LBS") {
                            <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "T") {
                            <span class="unit-bg">{{ "app.previous_hoff.T" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "Mins") {
                            <span class="unit-bg">{{ "app.previous_hoff.mins" | translate }}</span>
                            }
                        </div>

                    </div>

                    } @else if(controlItem1.controlname === "delivery-defrost-time" || controlItem1.controlname ===
                    "delivery-planned-stop" || controlItem1.controlname === "delivery-changeover-time"){
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid() ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy' ):
                        controlItem1.response}}</p>
                    }@else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                    } @else if(controlItem1.controlname === "delivery-planned-stop"){
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid() ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):
                        controlItem1.response}}</p>
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'number' && (controlItem1.controlname ===
            "delivery-changeover-duration")) {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'number') {
                    <p class="mb-0 d-flex justify-content-between"><span>{{controlItem1.response}}</span>
                        @if(controlItem1?.unit){<span class="unit-txt">{{controlItem1?.unit}}</span>}</p>
                    } @else {
                    <p class="mb-0 d-flex justify-content-between"><span>{{controlItem1.response}}</span>
                        @if(controlItem1?.unit){<span class="unit-txt">{{controlItem1?.unit}}</span>}</p>
                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'select' && (controlItem1.controlname === "delivery-changeover-sku" ||
            controlItem1.controlname === "delivery-current-sku")) {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                    <p class="mb-0">{{ controlItem1.ctrltype === 'date' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') : controlItem1.response  }}</p>
                    } @else {
                    <p class="mb-0 d-flex justify-content-between"><span>{{controlItem1.response}}</span>
                        @if(controlItem1?.unit){<span class="unit-txt">{{controlItem1?.unit}}</span>}</p>
                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'text' && (controlItem1.controlname === "delivery-changeover-prod-desc"
            || controlItem1.controlname === "delivery-target-shift")) {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>
            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    <p class="mb-0 d-flex justify-content-between"><span>{{controlItem1.response}}</span>
                        @if(controlItem1?.unit){<span class="unit-txt">{{controlItem1?.unit}}</span>}</p>
                </div>
            </div>
            }
            }
            }
        </div>
        <div class="col-6 comments-section">
            <h3 class="m-0 ">{{ "app.previous_hoff.comments" | translate }}</h3>
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' && ( ( ( selectedPlantCode !== 'MAT') && (controlItem1.controlname ===
            "delivery-target-shift-cmt" || controlItem1.controlname === "delivery-prod-target-cmt" ||
            controlItem1.controlname === "quality-rework-cmt" || controlItem1.controlname ===
            "delivery-planned-stop-cmt" || controlItem1.controlname === "delivery-current-sku-cmt" ||
            controlItem1.controlname === "delivery-changeover-time-cmt" || controlItem1.controlname ===
            "delivery-changeover-duration-cmt" || controlItem1.controlname === "delivery-changeover-sku-cmt" ||
            controlItem1.controlname ==="cattle-feed-trailer-checked-cmt" || controlItem1.controlname
            ==="is-saap-correct-cmt"
            )) || selectedPlantCode == 'MAT')) {
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>

            <div class="row spacing-pt-20">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>

            }@else if(controlItem1.ctrltype === 'date' && controlItem1.controlname === "delivery-possible-defrost-time")
            {
            <div class="row spacing-pt-20 ">
                <div class="col px-0">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>
            </div>

            <div class="row">
                <div class="col response-sec mt-0">
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid() ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') :
                        controlItem1.response}}</p>
                </div>
            </div>

            }
            }
            }

        </div>

    </div>
    <div class="col-12 accord-container mt-4 ">
        @for (controlItem of deliveryResponse; track controlItem;) {
        <div class="row">
            @for (deliveryTableContent of controlItem; track deliveryTableContent) {

            @if(deliveryTableContent.widget ==='input-table-header' && deliveryTableContent.ctrltype === "label"){
            <div class="col p-1">
                <div class="col p-0 d-flex textbox-content  textbox-wrapper">
                    <label
                        class=" form-control h-75 label-head rounded-0  d-flex justify-content-center">{{deliveryTableContent.labeltext}}</label>
                </div>
            </div>

            }


            @if(deliveryTableContent.widget ==='input-table' && (deliveryTableContent.ctrltype === "text" ||
            deliveryTableContent.ctrltype === "select")){
            @if(deliveryTableContent.value) {
            <div class="col p-1 target-container">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                    {{deliveryTableContent.value}}

                </div>
            </div>
            } @else if(deliveryTableContent.response) {
            <div class="col p-1 target-container">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                    {{deliveryTableContent.response}}

                </div>
            </div>
            }

            }

            @if(deliveryTableContent.widget ==='input-table' && deliveryTableContent.ctrltype === "number")
            {
            <div class="col p-1 target-container">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                    <div class="d-flex align-items-center col-10">
                        @if(deliveryTableContent.response) {
                        {{deliveryTableContent.response}}

                        }
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                        {{deliveryTableContent.unit}}
                    </div>

                </div>
            </div>
            }

            }

        </div>
        }


    </div>
    <div class="row spacing-pt-20">
        <div class="col">
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {

            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
            </div>
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                "app.previous_hoff.reported" | translate }}
            </div>
            }
            }
            }

        </div>

    </div>
</div>
} @else if(positionCode === 'SLS'){
<div class="handoff-container delivery-prev" [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">
    <div class="row spacing-pt-20">
        <div class="col-6 items-section">
            @if(selectedPlantCode==='HAR' && positionCode==='SLS'){}
            @else{
            <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3> 
            }

            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'rdbutton' || (controlItem1.ctrltype === 'date' && controlItem1.controlname
            !== "delivery-possible-defrost-time")|| controlItem1.ctrltype ===
            'number') {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                    @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                    "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                    controlItem1.controlname === "delivery-target-shift" ||
                    controlItem1.controlname === "delivery-line-rate" || controlItem1.controlname ===
                    "delivery-packaged-goal" || controlItem1.controlname === "delivery-packaged-achieved" ||
                    controlItem1.controlname === "delivery-down-time-last" || selectedPlantCode == 'HAR' && (controlItem1.controlname ==="delivery-efficency-shift" || controlItem1.controlname ==="delivery-volume-emb")) {
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                        <div class="col d-flex justify-content-end ">
                            @if(controlItem1.unit === 'KG/HR') {
                            <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                            } @else if(controlItem1.unit === "MIN") {
                            <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                            } @else if(controlItem1.unit === "LBS") {
                            <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "T") {
                            <span class="unit-bg">{{ "app.previous_hoff.T" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "Mins") {
                            <span class="unit-bg">{{ "app.previous_hoff.mins" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "%") {
                                <span class="unit-bg">{{controlItem1.unit}}</span>
                                }
                                @else if(controlItem1.unit === "Kg/h") {
                                    <span class="unit-bg">{{controlItem1.unit}}</span>
                                    }
                                    @else if(controlItem1.unit === "t") {
                                        <span class="unit-bg">{{ "app.previous_hoff.t" | translate }}</span>
                                        }
                        </div>

                    </div>

                    } @else if(controlItem1.controlname === "delivery-defrost-time" || controlItem1.controlname ===
                    "delivery-planned-stop" || controlItem1.controlname === "delivery-changeover-time"){
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                    }@else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                    } @else if(controlItem1.controlname === "delivery-planned-stop"){
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'select') {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0">{{ controlItem1.ctrltype === 'date' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') : controlItem1.response  }}</p>
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'text' && ((controlItem1.controlname === "delivery-changeover-prod-desc"
            || controlItem1.controlname ===
            'delivery-variete-sku' ) || ( (selectedPlantCode == 'HAR' && controlItem1.ctrltype === 'text'
            && !controlItem1.controlname.includes('-cmt')))))
            {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>
            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>
                </div>
            </div>
            }

            }
            }
        </div>
        <div class="col-6 comments-section">
            @if(selectedPlantCode==='HAR' && positionCode==='SLS'){}
            @else{
            <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
            }
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' || (controlItem1.ctrltype === 'date' && controlItem1.controlname ===
            "delivery-possible-defrost-time") || controlItem1.ctrltype === "select" ) {
            @if((selectedPlantCode !== 'MAT' && controlItem1.controlname === "delivery-target-shift-cmt" ||
            controlItem1.controlname === "delivery-prod-target-cmt" || controlItem1.controlname ===
            "delivery-planned-stop-cmt" || controlItem1.controlname === "delivery-current-line-rate-cmt" ||
            controlItem1.controlname === "delivery-current-sku-cmt" || controlItem1.controlname ===
            "delivery-current-cut-size-cmt" || controlItem1.controlname === "delivery-changeover-time-cmt" ||
            controlItem1.controlname === "delivery-changeover-duration-cmt" || controlItem1.controlname ===
            "delivery-changeover-linerate-cmt" || controlItem1.controlname === "delivery-changeover-sku-cmt" ||
            controlItem1.controlname === "delivery-changeover-cutsize-cmt" || controlItem1.controlname ===
            "delivery-changeover-prod-desc-cmt" || controlItem1.controlname ==="delivery-packaging-material-cmt") ||
            (selectedPlantCode == 'MAT' && controlItem1.controlname.includes('-cmt')) || (selectedPlantCode == 'HAR' && controlItem1.controlname.includes('-cmt'))){
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>

            <div class="row spacing-pt-20">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>
            } @else if (controlItem1.controlname === "delivery-possible-defrost-time") {
            <div class="row spacing-pt-20 ">
                <div class="col px-0">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>
            </div>

            <div class="row">
                <div class="col response-sec mt-0">

                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid() ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy' ):
                        controlItem1.response}}</p>

                </div>
            </div>
            }


            }
            @else if(controlItem1.ctrltype === 'date' && controlItem1.ctrltype === "delivery-possible-defrost-time") {
            <div class="row spacing-pt-20 ">
                <div class="col">
                    {{controlItem1.labeltext}}
                </div>
            </div>

            <div class="row spacing-pt-20">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>
            }
            }
            }

        </div>

    </div>
    <div class="row spacing-pt-20">
        <div class="col">
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {

            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
            </div>
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                "app.previous_hoff.reported" | translate }}
            </div>
            }
            }
            }

        </div>

    </div>

</div>
} @else if(selectedPlantCode=='BUR' && positionCode === 'PLS'){
    <div class="handoff-container delivery-prev">
        <div class="row spacing-pt-20">
            <div class="col-6 items-section">
                <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3>
    
                @for(item of deliveryResponse; track item; let i= $index) {
                @for (controlItem1 of deliveryResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'rdbutton' || (selectedPlantCode == 'MAT' && controlItem1.ctrltype === 'text'
                && !controlItem1.controlname.includes('-cmt') ) || (controlItem1.ctrltype === 'date' &&
                controlItem1.controlname !== "delivery-possible-defrost-time")|| controlItem1.ctrltype ===
                'number') {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                        "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                        controlItem1.controlname === "delivery-target-shift" || controlItem1.controlname ===
                        "delivery-changeover-time" || controlItem1.controlname ===
                        "delivery-volume" || controlItem1.controlname ===
                        "delivery-team" || controlItem1.controlname ===
                        "delivery-currentLine" || controlItem1.controlname ===
                        "delivery-linerate-volumepack" || controlItem1.controlname ===
                        "delivery-peeler-linerate" || controlItem1.controlname ===
                        "delivery-lastshift-downtime") {
                        <div class="row">
                            <div class="col">
                                <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                                    ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                @if(controlItem1.unit === 'KG/HR') {
                                <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                                } @else if(controlItem1.unit === "MIN") {
                                <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                                } @else if(controlItem1.unit === "LBS") {
                                <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                                }
                                @else if(controlItem1.unit === "T") {
                                <span class="unit-bg">{{ "app.previous_hoff.T" | translate }}</span>
                                }
                                @else if(controlItem1.unit === "Mins") {
                                <span class="unit-bg">{{ "app.previous_hoff.mins" | translate }}</span>
                                }
                                @else if(controlItem1.unit === "T/HR") {
                                <span class="unit-bg">{{ "app.previous_hoff.t_hr" | translate }}</span>
                                }
                            </div>
    
                        </div>
    
                        } @else if(controlItem1.controlname === "delivery-planned-stop"){
                        <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                            ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                            ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
    
                        }
                        } @else if(controlItem1.controlname === "delivery-planned-stop"){
                        <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                            ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                        } @else {
                        @if(selectedPlantCode==='MAT' && positionCode === 'FLS' && (controlItem1.controlname ===
                        "delivery-volume" || controlItem1.controlname ===
                        "delivery-team" || controlItem1.controlname ===
                        "delivery-currentLine")){
                        <div class="row">
                            <div class="col">
                                <p class="mb-0">{{controlItem1.response}}</p>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                @if(controlItem1.unit === "T") {
                                <span class="unit-bg">{{ "app.previous_hoff.T" | translate }}</span>
                                }
                                @else if(controlItem1.unit === "T/HR") {
                                <span class="unit-bg">{{ "app.previous_hoff.t_hr" | translate }}</span>
                                }
                            </div>
                        </div>
                        }@else{
                        <p class="mb-0">{{controlItem1.response}}</p>
                        }
                        }
                    </div>
                </div>
                }@else if(controlItem1.ctrltype === 'select') {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                            <p class="mb-0">{{ controlItem1.ctrltype === 'date' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') : controlItem1.response  }}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
                }@else if(controlItem1.ctrltype === 'text' && controlItem1.controlname === "delivery-changeover-prod-desc")
                {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        <p class="mb-0">{{controlItem1.response}}</p>
                    </div>
                </div>
                }
    
                }
                }
            </div>
            <div class="col-6 comments-section">
                <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
                @for(item of deliveryResponse; track item; let i= $index) {
                @for (controlItem1 of deliveryResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'text' || (controlItem1.ctrltype === 'date' && controlItem1.controlname ===
                "delivery-possible-defrost-time") || controlItem1.ctrltype === "select" ) {
                @if(
                (( selectedPlantCode !== 'MAT') && controlItem1.controlname === "delivery-target-shift-cmt" ||
                controlItem1.controlname === "delivery-prod-target-cmt" || controlItem1.controlname ===
                "delivery-planned-stop-cmt" || controlItem1.controlname === "delivery-current-line-rate-cmt" ||
                controlItem1.controlname === "delivery-current-sku-cmt" || controlItem1.controlname ===
                "delivery-current-cut-size-cmt" || controlItem1.controlname === "delivery-changeover-time-cmt" ||
                controlItem1.controlname === "delivery-changeover-duration-cmt" || controlItem1.controlname ===
                "delivery-changeover-linerate-cmt" || controlItem1.controlname === "delivery-changeover-sku-cmt" ||
                controlItem1.controlname === "delivery-changeover-cutsize-cmt"
                || controlItem1.controlname === "delivery-changeover-prod-desc-cmt") || (selectedPlantCode=='MAT' && (
                controlItem1.controlname.includes('-cmt') ))|| (selectedPlantCode=='BUR' && (
                controlItem1.controlname.includes('-cmt') ))){
                <div class="row spacing-pt-20 ">
                    <div class="col"></div>
                </div>
    
                <div class="row spacing-pt-20">
                    <div class="col response-sec">
                        <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                    </div>
                </div>
                } @else if (controlItem1.controlname === "delivery-possible-defrost-time") {
                <div class="row spacing-pt-20 ">
                    <div class="col px-0">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col response-sec mt-0">
                        <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                            ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                    </div>
                </div>
                }
    
    
                }
                @else if(controlItem1.ctrltype === 'date' && controlItem1.ctrltype === "delivery-possible-defrost-time") {
                <div class="row spacing-pt-20 ">
                    <div class="col">
                        {{controlItem1.labeltext}}
                    </div>
                </div>
    
                <div class="row spacing-pt-20">
                    <div class="col response-sec">
                        <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                    </div>
                </div>
                }
                }
                }
    
            </div>
    
        </div>
        <div class="row spacing-pt-20">
            <div class="col">
                @for(item of deliveryResponse; track item; let i= $index) {
                @for (controlItem1 of deliveryResponse[i]; track controlItem1) {
    
                @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
                <div class="row py-3 ">
                    <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
                </div>
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                    "app.previous_hoff.reported" | translate }}
                </div>
                }
                }
                }
    
            </div>
    
        </div>
    
    </div>
}
@else if(positionCode === 'FLS' || positionCode === 'PLS' || positionCode === 'FLS1' || positionCode === 'FLS2' || positionCode === 'PKGL1' || positionCode === 'PKGL2' || positionCode ==='LPL1' || positionCode ==='LPL2' || positionCode === 'PLH1' || positionCode === 'PLH2' ) {
<div class="handoff-container delivery-prev">
    <div class="row spacing-pt-20">
        <div class="col-6 items-section">
            @if(selectedPlantCode==='HAR' && (positionCode==='FLS1' || positionCode === 'FLS2' || positionCode === 'PKGL1' || positionCode === 'PKGL2' || positionCode ==='LPL1' || positionCode ==='LPL2' || positionCode === 'PLH1' || positionCode === 'PLH2')){}
            @else{
            <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3>
            }
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'rdbutton' || (selectedPlantCode == 'MAT' && controlItem1.ctrltype === 'text'
            && !controlItem1.controlname.includes('-cmt') ) || (controlItem1.ctrltype === 'date' &&
            controlItem1.controlname !== "delivery-possible-defrost-time")|| controlItem1.ctrltype ===
            'number' || (selectedPlantCode == 'HAR' && controlItem1.ctrltype === 'text'
            && !controlItem1.controlname.includes('-cmt') )) {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number' || (selectedPlantCode==='HAR' && controlItem1.ctrltype === 'text' && ( positionCode === 'PLH1' || positionCode === 'PLH2' || positionCode === 'PKGL1' || positionCode === 'PKGL2'))) {
                    @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                    "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                    controlItem1.controlname === "delivery-target-shift" || controlItem1.controlname ===
                    "delivery-changeover-time" || controlItem1.controlname ===
                    "delivery-volume" || controlItem1.controlname ===
                    "delivery-team" || controlItem1.controlname ===
                    "delivery-currentLine" || controlItem1.controlname ===
                    "delivery-linerate-volumepack" || controlItem1.controlname ===
                    "delivery-peeler-linerate" || controlItem1.controlname ===
                    "delivery-lastshift-downtime" || ((controlItem1.controlname ===
                    "delivery-line-rate" || controlItem1.controlname ===
                    "delivery-remaining-tonnage" || controlItem1.controlname ===
                    "delivery-peleuse" || controlItem1.controlname ===
                    "delivery-tonnage" || controlItem1.controlname ===
                    "delivery-sku-capacity") && selectedPlantCode === 'HAR')) {
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response != ' ' && (controlItem1.ctrltype === 'date' && moment(controlItem1.response
                                ,moment.ISO_8601,true).isValid())? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                        </div>
                        <div class="col d-flex justify-content-end ">
                            @if(controlItem1.unit === 'KG/HR') {
                            <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                            } @else if(controlItem1.unit === "MIN") {
                            <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                            } @else if(controlItem1.unit === "LBS") {
                            <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "T") {
                            <span class="unit-bg">{{ "app.previous_hoff.T" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "Mins") {
                            <span class="unit-bg">{{ "app.previous_hoff.mins" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "T/HR") {
                            <span class="unit-bg">{{ "app.previous_hoff.t_hr" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "t/H") {
                                <span class="unit-bg">{{ "app.previous_hoff.t_h" | translate }}</span>
                                }
                                @else if(controlItem1.unit === "t") {
                                    <span class="unit-bg">{{ "app.previous_hoff.t" | translate }}</span>
                                    }
                        </div>

                    </div>

                    } @else if(controlItem1.controlname === "delivery-planned-stop"){
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                    } @else {
                    <p class="mb-0">{{controlItem1.response != ' ' && (controlItem1.ctrltype === 'date' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid())? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>

                    }
                    } @else if(controlItem1.controlname === "delivery-planned-stop"){
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                    } @else {
                    @if(selectedPlantCode==='MAT' && positionCode === 'FLS' && (controlItem1.controlname ===
                    "delivery-volume" || controlItem1.controlname ===
                    "delivery-team" || controlItem1.controlname ===
                    "delivery-currentLine")){
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                        <div class="col d-flex justify-content-end ">
                            @if(controlItem1.unit === "T") {
                            <span class="unit-bg">{{ "app.previous_hoff.T" | translate }}</span>
                            }
                            @else if(controlItem1.unit === "T/HR") {
                            <span class="unit-bg">{{ "app.previous_hoff.t_hr" | translate }}</span>
                            }
                        </div>
                    </div>
                    }@else{
                    <p class="mb-0">{{controlItem1.response}}</p>
                    }
                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'select') {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0">{{ controlItem1.ctrltype === 'date' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') : controlItem1.response  }}</p>
                    } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>
                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'text' && controlItem1.controlname === "delivery-changeover-prod-desc")
            {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>
            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>
                </div>
            </div>
            }

            }
            }
        </div>
        <div class="col-6 comments-section custom-{{selectedPlantCode}}-{{positionCode}}">
            @if(selectedPlantCode==='HAR' && (positionCode==='FLS1' || positionCode === 'FLS2' || positionCode === 'PKGL1' || positionCode === 'PKGL2' || positionCode ==='LPL1' || positionCode ==='LPL2' || positionCode === 'PLH1' || positionCode === 'PLH2')){}
            @else{
            <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
            }
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' || (controlItem1.ctrltype === 'date' && controlItem1.controlname ===
            "delivery-possible-defrost-time") || controlItem1.ctrltype === "select" ) {
            @if(
            (( selectedPlantCode !== 'MAT') && controlItem1.controlname === "delivery-target-shift-cmt" ||
            controlItem1.controlname === "delivery-prod-target-cmt" || controlItem1.controlname ===
            "delivery-planned-stop-cmt" || controlItem1.controlname === "delivery-current-line-rate-cmt" ||
            controlItem1.controlname === "delivery-current-sku-cmt" || controlItem1.controlname ===
            "delivery-current-cut-size-cmt" || controlItem1.controlname === "delivery-changeover-time-cmt" ||
            controlItem1.controlname === "delivery-changeover-duration-cmt" || controlItem1.controlname ===
            "delivery-changeover-linerate-cmt" || controlItem1.controlname === "delivery-changeover-sku-cmt" ||
            controlItem1.controlname === "delivery-changeover-cutsize-cmt"
            || controlItem1.controlname === "delivery-changeover-prod-desc-cmt") || (selectedPlantCode=='MAT' && (
            controlItem1.controlname.includes('-cmt') ))|| (selectedPlantCode=='BUR' && (
            controlItem1.controlname.includes('-cmt') )) || (selectedPlantCode=='HAR' && (
                controlItem1.controlname.includes('-cmt') ))){
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>
            <div class="row spacing-pt-20">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>
            } @else if (controlItem1.controlname === "delivery-possible-defrost-time") {
            <div class="row spacing-pt-20 ">
                <div class="col px-0">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>
            </div>

            <div class="row">
                <div class="col response-sec mt-0">
                    <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                        ,moment.ISO_8601,true).isValid()? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response}}</p>
                </div>
            </div>
            }


            }
            @else if(controlItem1.ctrltype === 'date' && controlItem1.ctrltype === "delivery-possible-defrost-time") {
            <div class="row spacing-pt-20 ">
                <div class="col">
                    {{controlItem1.labeltext}}
                </div>
            </div>

            <div class="row spacing-pt-20">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>
            }
            }
            }

        </div>

    </div>
    <div class="row spacing-pt-20">
        <div class="col">
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {

            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
            </div>
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                "app.previous_hoff.reported" | translate }}
            </div>
            }
            }
            }

        </div>

    </div>

</div>
}

} @else if(isOperatorPosition) {
<div class="handoff-container delivery-prev" [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">
    <div class="row spacing-pt-20">
        <div class="col-6 items-section">
            @if((positionCode === 'PLEBL1' || positionCode ==='PLEBL2' || positionCode.includes('ENSM') || positionCode ==='BTR'
            || positionCode ==='TRPL1' ||  positionCode ==='TRPL2'  || positionCode ==='EBFLK' 
            || positionCode ==='PLTRL1' || positionCode ==='PLTRL2' 
            || positionCode =='MLFR1' || positionCode =='MLFR2' || positionCode.includes('ENSM2') || positionCode.includes('ENSM3') || positionCode.includes('ENSM4') || positionCode.includes('ENSM5') || positionCode.includes('ENSM6')
            || positionCode =='FTRL1' || positionCode =='FTRL2' || positionCode =='EPRL1' || positionCode =='EPRL2'
            || positionCode === 'CTXL1' || positionCode === 'CTXL2')  &&
            selectedPlantCode === 'HAR'){
            }@else{
            <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3>
            }
            @for(item of deliveryResponse; track item; let i= $index) {
            @if(((positionCode ==='TRPL1' ||  positionCode ==='TRPL2'  || positionCode ==='EBFLK' || positionCode ==='PLTRL1' || positionCode ==='PLTRL2'
            || positionCode =='MLFR1' || positionCode =='MLFR2'  || positionCode.includes('ENSM2') || positionCode.includes('ENSM3') || positionCode.includes('ENSM4') || positionCode.includes('ENSM5') || positionCode.includes('ENSM6')
            || positionCode =='FTRL1' || positionCode =='FTRL2' || positionCode =='MLGFLK'|| positionCode ==='BTR'
            || positionCode === 'CTXL1' || positionCode ==='CTXL2' || positionCode =='EPRL1' || positionCode =='EPRL2' || positionCode.includes('CYLRL'))  &&
            selectedPlantCode === 'HAR') || ((positionCode ==='FORK' || positionCode ==='ATSPL') && selectedPlantCode === 'BUR')){
                @for (controlItem1 of deliveryResponse[i]; track controlItem1) {
                    @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
                    'number' ||  (controlItem1.ctrltype === 'text' && !controlItem1.controlname.includes("-cmt"))) {
                    <div class="row spacing-pt-20">
                        <div class="col">
                            <label class="label">{{controlItem1.labeltext}}</label>
                        </div>
        
                    </div>
                    <div class="row  m-0">
                        <div class="col response-sec">
                            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number' || controlItem1.ctrltype === 'text') {
                            @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                            "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                            controlItem1.controlname === "delivery-target-shift" ||  controlItem1.controlname === "delivery-peeler-tonnage" ||  controlItem1.controlname === "delivery-efficiency"
                             || (selectedPlantCode == 'HAR' && controlItem1.ctrltype === 'text'
                            && !controlItem1.controlname.includes('-cmt') )) {
                            <div class="row">
                                <div class="col">
                                    <p class="mb-0">{{controlItem1.response}}</p>
                                </div>
                                <div class="col d-flex justify-content-end ">
                                    @if(controlItem1.unit === 'KG/HR') {
                                    <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                                    } @else if(controlItem1.unit === "MIN") {
                                    <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                                    } @else if(controlItem1.unit === "LBS") {
                                    <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                                    } @else if(controlItem1?.unit){
                                        <span class="unit-txt">{{controlItem1?.unit}}</span>
                                    }
                                    
                                </div>
        
                            </div>
        
                            } @else {
                            <p class="mb-0">{{(controlItem1.response!=' ' && controlItem1.ctrltype === 'date') ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') :controlItem1.response}}</p>
        
                            }
                            } @else {
                            <p class="mb-0">{{controlItem1.response}}</p>
        
                            }
                        </div>
                    </div>
                    }@else if(controlItem1.ctrltype === 'select') {
                    <div class="row spacing-pt-20">
                        <div class="col">
                            <label class="label">{{controlItem1.labeltext}}</label>
                        </div>
        
                    </div>
                    <div class="row  m-0">
                        <div class="col response-sec">
                            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                                <p class="mb-0">{{ controlItem1.ctrltype === 'date' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') : controlItem1.response  }}</p>
                            } @else {
                            <p class="mb-0">{{controlItem1.response}}</p>
        
                            }
                        </div>
                    </div>
                    }@else if(controlItem1.ctrltype === 'text'  && controlItem1.controlname === "delivery-changeover-prod-desc")
                    {
                    <div class="row spacing-pt-20">
                        <div class="col">
                            <label class="label">{{controlItem1.labeltext}}</label>
                        </div>
                    </div>
                    <div class="row  m-0">
                        <div class="col response-sec">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                    </div>
                    }
                    }
            }
            @else{
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number' ||  (controlItem1.ctrltype === 'text' && !controlItem1.controlname.includes("-cmt"))) {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number' || controlItem1.ctrltype === 'text') {
                    @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                    "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                    controlItem1.controlname === "delivery-target-shift" || controlItem1.controlname === "delivery-cook-selectionned" || controlItem1.controlname === "delivery-dtank-selectionned"
                    || controlItem1.controlname === "delivery-sliver-losses-cmt") {
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                        <div class="col d-flex justify-content-end ">
                            @if(controlItem1.unit === 'KG/HR') {
                            <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                            } @else if(controlItem1.unit === "MIN") {
                            <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                            } @else if(controlItem1.unit === "LBS") {
                            <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                            } @else if(controlItem1?.unit){
                                <span class="unit-txt">{{controlItem1?.unit}}</span>
                            }
                            
                        </div>

                    </div>

                    } @else {
                        @if(controlItem1.ctrltype === 'date'){
                            <p class="mb-0">{{controlItem1.response | datetimeFormat:'mm/dd/yyyy' }}</p>
                        }
                        @else{
                            <p class="mb-0">{{controlItem1.response}}</p>
                        }
                    }
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'select') {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date'  || controlItem1.ctrltype === 'number') {
                    <p class="mb-0">{{ controlItem1.ctrltype === 'date' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy') : controlItem1.response  }}</p>
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }@else if(controlItem1.ctrltype === 'text'  && controlItem1.controlname === "delivery-changeover-prod-desc")
            {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>
            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>
                </div>
            </div>
            }
            }
        }
            }
        </div>
        <div class="col-6 comments-section">
            @if((positionCode === 'PLEBL1' || positionCode ==='PLEBL2' || positionCode.includes('ENSM') || positionCode ==='BTR'
            || positionCode ==='TRPL1' ||  positionCode ==='TRPL2'  || positionCode ==='EBFLK' 
            || positionCode ==='PLTRL1' || positionCode ==='PLTRL2'
             || positionCode =='MLFR1' || positionCode =='MLFR2' || positionCode.includes('ENSM2') || positionCode.includes('ENSM3') || positionCode.includes('ENSM4') || positionCode.includes('ENSM5') || positionCode.includes('ENSM6')
             || positionCode =='FTRL1' || positionCode =='FTRL2' || positionCode =='EPRL1' || positionCode =='EPRL2'
             || positionCode === 'CTXL1' || positionCode ==='CTXL2') &&
            selectedPlantCode === 'HAR'){
            }
            @else{
            <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
            }
            @for(item of deliveryResponse; track item; let i= $index) {
                @if(((positionCode ==='TRPL1' ||  positionCode ==='TRPL2'  || positionCode ==='EBFLK' || positionCode ==='PLTRL1' || positionCode ==='PLTRL2'
                || positionCode =='MLFR1' || positionCode =='MLFR2' || positionCode.includes('ENSM2') || positionCode.includes('ENSM3') || positionCode.includes('ENSM4') || positionCode.includes('ENSM5') || positionCode.includes('ENSM6')
                || positionCode =='FTRL1' || positionCode =='FTRL2' || positionCode =='MLGFLK'|| positionCode ==='BTR'
                || positionCode === 'CTXL1' || positionCode ==='CTXL2' || positionCode =='EPRL1' || positionCode =='EPRL2' || positionCode.includes('CYLRL'))  &&
                selectedPlantCode === 'HAR')|| ((positionCode ==='FORK'|| positionCode ==='ATSPL' )&& selectedPlantCode === 'BUR')){
                    @for (controlItem1 of deliveryResponse[i]; track controlItem1) {
                        @if(controlItem1.ctrltype === 'text' && controlItem1.controlname.includes("-cmt")) {
                        <div class="row spacing-pt-20 ">
                            <div class="col"></div>
                        </div>
            
                        <div class="row spacing-pt-20">
                            <div class="col response-sec" id="custom-{{controlItem1.controlname}}" >
                                <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                            </div>
                        </div>
            
                        }
                        }
                }
            @else{    
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' && controlItem1.controlname.includes("-cmt")) {
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>

            <div class="row spacing-pt-20">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>

            }
            }
        }
            }

        </div>

    </div>
    <div class="row spacing-pt-20">
        <div class="col">
            @for(item of deliveryResponse; track item; let i= $index) {
            @for (controlItem1 of deliveryResponse[i+1]; track controlItem1) {

            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
            </div>
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                "app.previous_hoff.reported" | translate }}
            </div>
            }
            }
            }

        </div>

    </div>
</div>

}