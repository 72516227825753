import { Component, OnDestroy, OnInit, Renderer2, inject } from '@angular/core';
import { Router, RouterLink, RouterOutlet,NavigationEnd } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { MockApiService } from '../../services/mock-api.service';
import { ShiftPosition } from '../../models/shift-position';
import { LoginComponent } from '../layout/login/login.component';
import { TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SideNavComponent } from '../layout/side-nav/side-nav.component';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { DataService } from '../../../shared/services/data-service.service';
import { Subscription } from 'rxjs';
import { NgClass } from '@angular/common';
import { AuthApiService } from '../../../core/services/auth-api.service';
import * as APP_CONST from '../../../../app/constants/app.constants';
import { getUserDetails } from '../../../helper/getUserDetails';
import { MSAL_INSTANCE, MsalService } from '@azure/msal-angular';
import { environment } from '../../../../environments/environment';
import { MSALInstanceFactory } from '../../azureAD/auth-config';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink, LoginComponent, FormsModule, TranslateModule, SideNavComponent, RouterOutlet, UserSettingsComponent,NgClass],
  providers: [{
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  }],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {
  private apiService = inject(ApiService);
  private mockapiService = inject(MockApiService);
  private authapiService = inject(AuthApiService);
  public finalResponse: any[] = [];
  public shiftPositionData: ShiftPosition[] = [];
  public selectedLang = 'en';
  public title = 'McCain-app';
  public isLoggedIn = true;
  public alertMessageSubscription!: Subscription;
  public successMessage:any = {};
  private router = inject(Router);
  private userDetailsData:any;
  private activePositionsSubscription!: Subscription;
  private logoutSubscription!: Subscription;

   constructor(private translate: TranslateService,private alertMessageService: DataService, private authService: MsalService,public renderer: Renderer2) {
    translate.setDefaultLang('en');
    this.checkUserSession();
  }

  checkUserSession(){    
    const getPosition = sessionStorage.getItem('SelectedItemsForKPI')?sessionStorage.getItem('SelectedItemsForKPI'):'';
    
    if(getPosition && getPosition!='') {        
        const positions = JSON.parse(getPosition).map((e:any)=>e.id)        
        if(positions.length>=0)
        {
          this.getActivePositions();
        }
    } else {
      this.router.navigate(['']);
    }
  }

  getActivePositions() {
    if (!this.userDetailsData) {
      const userDetailsString = sessionStorage.getItem('userDetails');
      if (userDetailsString && userDetailsString.trim() !== '') {
        try {
          const userDetails = JSON.parse(userDetailsString);
          this.userDetailsData = getUserDetails(userDetails);
        } catch (error) {
          this.userDetailsData = getUserDetails({}); 
        }
      } else {
        this.userDetailsData = getUserDetails({}); 
      }
    }

    this.activePositionsSubscription = this.authapiService
      .getActivePositions(
        APP_CONST.AUTH_FEATURE,
        'Settings',
        APP_CONST.DEFAULT_LANG,
        this.userDetailsData
      )
      ?.subscribe({
        next: (data: any[]) => {
          const userDetails:any = data;
          const positionData:any = [];         
          
          if(userDetails.isSuccess==true && userDetails.result ){
            if(userDetails.result.positionId1!='') {
              positionData.push(parseInt(userDetails.result.positionId1))
            }
            if(userDetails.result.positionId2!='') {
              positionData.push(parseInt(userDetails.result.positionId2))
            }
            if(userDetails.result.positionId3!='') {
              positionData.push(parseInt(userDetails.result.positionId3))
            }
           
            const getPosition = sessionStorage.getItem('SelectedItemsForKPI')?sessionStorage.getItem('SelectedItemsForKPI'):'';
            
            if(getPosition && getPosition!='') {  
                const positions = JSON.parse(getPosition).map((e:any)=>e.id)
                let missing = positions.filter((item:any) => positionData.indexOf(item) < 0);
              
                if(missing.length>0 &&positionData.length!==positions.length) {
                  this.logout();
                }
                
            }
          }     
        }                                                     
      });
  }

  logout() {
    this.renderer.addClass(document.body, 'overlay-logout-class');
    const env = environment;
    if(!this.userDetailsData) {
     const userDetails = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
      this.userDetailsData = getUserDetails(userDetails);
    }      
    this.logoutSubscription = this.authapiService
      .logout(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.USER_SUBFEATURE,
        this.userDetailsData
      )
      .subscribe({
        next: (logoutDataResponse: any) => {
          if(logoutDataResponse.isSuccess==true) {
          sessionStorage.removeItem('SelectedItemsForKPI');
          sessionStorage.removeItem('SelectedItems');
          this.router.navigate(['']);
          }
          this.renderer.removeClass(document.body, 'overlay-logout-class');
        },
        
        error: (response) => {
          console.log('error');
          this.router.navigate(['']);
          this.renderer.removeClass(document.body, 'overlay-logout-class');
        },
    });
  }

  ngOnInit(): void {
    this.router.events?.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
        this.checkUserSession();
        console.log('route change')
        /* Your code goes here on every router change */}
    });
    this.translate.setDefaultLang('en');
    this.alertMessageSubscription = this.alertMessageService.alertMessage$.subscribe(res => {
      this.successMessage = res;
      setTimeout(() => {

        this.successMessage.message = '';
      }, 5000); // Hide after 5 seconds
    });

   /*  if(sessionStorage.getItem('selectedLanguage') !== ""){
      let lang = sessionStorage.getItem('selectedLanguage');
      this.translate.use(String(lang));
    } */

  }

  changeLang() {
    this.translate.use(this.selectedLang);
  }

  loadData() {
    this.apiService.getData().subscribe({
      // next callback will be executed when the request is successful.
      next: (data: any) => {
        // We receive the data from the mock API.
        this.finalResponse = data;
        console.log('Data fetched successfully!!!');
      },
      // error callback will be executed when there is an error.
      error: (error) => {
        console.log('Error fetching data:', error);
      }
    })
  }

  getPositionData() {
    this.mockapiService.getData().subscribe({
      next: (data: ShiftPosition[]) => {
        this.shiftPositionData = data;
      }
    })
  }

  getShiftPosition(id: number) {
    this.mockapiService.getDataByKey(id).subscribe({
      next: (shiftPosition: ShiftPosition) => {
        console.log(`shift position with id, ${id} ${shiftPosition}`);
      },
      error: (err) => {
        console.log('shift positon with id has not fetched', err);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.alertMessageSubscription) {
      this.alertMessageSubscription.unsubscribe();
    }
    if (this.activePositionsSubscription) {
      this.activePositionsSubscription.unsubscribe();
    }
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }
}
