export const environment = {
    production: false,
    baseURL: 'https://jsonplaceholder.typicode.com/',
    apiKey: '', // is used to authenticate the user
    apiUrl: 'https://dev-mdi-ai.mccain.com/mdiaiapidev',
    imgUrl: 'https://image.tmdb.org/t/pw500',
    mockApiUrl: 'http://localhost:3000',
    envCode: 'DEV',
    appVersion: '1.935',
    clientId: 'a2066245-430d-4ece-ad53-a6e8ae353570',
    tenantId: '59fa7797-abec-4505-81e6-8ce092642190',
    redirectUri:  'https://dev-mdi-ai.mccain.com/',//'http://localhost:4200/',
    postLogoutRedirectUri:  'https://dev-mdi-ai.mccain.com/logout',//'https://localhost:4200/logout',
    appInsightInstrumentationKey: '2e4e87c3-3558-482a-8892-31c5531d43c1'
}
