import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-show-message',
  standalone: true,
  imports: [],
  providers: [BsModalService],
  templateUrl: './show-message.component.html',
  styleUrl: './show-message.component.scss',
})
export class ShowAlertMessageComponent implements OnInit, OnDestroy{
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();
  public title: string = '';
  public message: string = '';
  public ok: string = '';
  public modalRef1: any;
  public status: any = '';

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {
  }
  onClose() {
    this.modalRef.hide();
    this.modalClosed.emit();
  }
  ngOnDestroy() {
    this.onClose()
 }
}
