import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { NoCommaPipe } from '../../../../../../../../../../shared/pipes/no-comma.pipe';
@Component({
  selector: 'app-centerline-deviations',
  standalone: true,
  imports: [CommonModule, TranslateModule, NoCommaPipe],
  templateUrl: './centerline-deviations.component.html',
  styleUrl: './centerline-deviations.component.scss'
})
export class CenterlineDeviationsComponent {
  @Input() centerlineResponse:any;
  @Input() reportedCLDeviationArray:any;
  @Input() centerlineControls:any;
  @Input() selectedPlantCode:any;
  @Input() positionCode: any;
  public selectedLang: string;

  constructor(){
    if(sessionStorage.getItem('selectedLanguage') !== ""){
      this.selectedLang = String(sessionStorage.getItem('selectedLanguage'));
    }
  }
  ngOnChanges() {
    if(this.reportedCLDeviationArray && this.reportedCLDeviationArray.length > 0 )
      { 
        let reportedCLDeviationArrayTemp=[];
        if (this.centerlineControls &&this.centerlineControls.length > 0) {
          reportedCLDeviationArrayTemp=this.reportedCLDeviationArray.filter((x: { reportId: any; }) => this.centerlineControls.findIndex((y: { cldRPTId: any; }) => y.cldRPTId ===  x.reportId) >= 0);
          this.reportedCLDeviationArray=reportedCLDeviationArrayTemp;
        }
   
      }
    if (this.reportedCLDeviationArray && this.reportedCLDeviationArray.length > 0) {
      this.reportedCLDeviationArray.forEach((element: any, index: any) => {
        element.controls = [];
        let nextStep = '';
        let nextStepindex = this.centerlineControls.findIndex((x: { controlname: string; }) => x.controlname == 'suggestedSteps' + index);
        if (nextStepindex >= 0) {
          nextStep = this.centerlineControls[nextStepindex].response;
        }
        let flag = '';
        let flagindex = this.centerlineControls.findIndex((x: { controlname: string; }) => x.controlname == 'suggestedStepsflg' + index);
        if (flagindex >= 0) {
          flag = this.centerlineControls[flagindex].response;
        }
        let suggestedSteps = '';
        let suggestedflag = '';
        let suggestedStepsindex = this.centerlineControls.findIndex((x: {
          controltype: string; cldRPTId: string;
        }) => x.cldRPTId == element.reportId && x.controltype == 'suggestedStep');
        if (suggestedStepsindex >= 0) {
          suggestedSteps = this.centerlineControls[suggestedStepsindex].response;
        }
        let suggestedFlagindex = this.centerlineControls.findIndex((x: {
          controltype: string; cldRPTId: string;
        }) => x.cldRPTId == element.reportId && x.controltype == 'flagicon');

        if (suggestedFlagindex >= 0) {
          suggestedflag = this.centerlineControls[suggestedFlagindex].response;
        }
        element.controls.push({response: suggestedSteps });
        element.controls.push({response: suggestedflag});
      
      });       
    }
  }
  getDateFromEpoch(epochtime: any) {
    if (epochtime) {
      if(this.selectedLang === 'fr'){
        return moment.unix(parseInt(epochtime)).format("DD/MM/YYYY HH:mm")
      }else{
        return moment.unix(parseInt(epochtime)).format('MM/DD/YYYY HH:mm');
      }
    }
    else {
      return '';
    }
  }
}
