import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, LOCALE_ID, OnChanges, OnInit, Renderer2, inject } from '@angular/core';
import { getUserDetails } from '../../../../../../../../helper/getUserDetails';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '../../../../../../../../core/services/language-selection.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';

// import { registerLocaleData } from '@angular/common';
// import localeFr from '@angular/common/locales/fr';

// registerLocaleData(localeFr)


@Component({
  selector: 'app-summary-view',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [DatePipe],
  templateUrl: './summary-view.component.html',
  styleUrl: './summary-view.component.scss',
})
export class SummaryViewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() responseData: any;
  @Input() createdBy: any;
  @Input() tabSelected: any;
  @Input() result: any;
  @Input() roleName?: string;
  @Input() positionCode: any;
  @Input() isCIDCompleted: any;
  @Input() selectedPlantCode: any;
  @Input() reportedRTDuetDefectObj: any;
  @Input() reportedCLDeviationArray: any;
  private languageSelectionService = inject(LanguageSelectionService);
  public informationData: any;
  public CIDdata: any = false;
  public isOperatorPosition?: boolean;
  public isSupervisorPosition?: boolean;
  public selectedLanguage: any;
  public userDetailsData: any;
  public isLangchange: any;
  @Input() reportedDefectArray: any;
  public reportedDefectSafetyCount = 0;
  public reportedDefectQualityCount = 0;
  public reportedDefectENVCount = 0;
  public reportedDefectEDCount = 0;
  public userInfoDetails: any;
  public totalNumberofRows: any = 0;
  public accordianArray: any[] = [];
  public equipmenetStopsData: any[] = [];
  public HandoffEquipmentStopsDowntime=0;
  public HandoffequipmentStopsCount=0;
  constructor(private translateService: TranslateService, public datePipe: DatePipe, private renderer: Renderer2, private el: ElementRef) { }
  ngOnInit() {
    this.setLanguage();
    this.checkOperatorPositions(this.positionCode);
    this.checkSupervisorPositions(this.positionCode);
    this.totalNumberofRows = Math.floor((this.responseData.length - 1) / 2) + ((this.responseData.length - 1) % 2);
    this.accordianArray = Array(this.totalNumberofRows).fill(0).map((_, i) => i + 1);
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
      this.userInfoDetails = getUserDetails(this.userDetailsData);
    }
  }

  ngAfterViewInit() {
    this.addIdsToDivs();
  }

  addIdsToDivs() {
    this.addIdToDivs('.col-md-auto', 'col-md-auto');
    this.addIdToDivs('.qn-question', 'qn-question');
  }

  private addIdToDivs(selector: string, prefix: string) {
    const elements = this.el.nativeElement.querySelectorAll(`div${selector}`);
    elements.forEach((element: HTMLElement, index: number) => {
      this.renderer.setAttribute(element, 'id', `${prefix}-${index}`);
    });
  }

  ngOnChanges() {
   
    for (let i = 0; i < this.responseData.length; i++) {
      if (this.responseData[i].type === 'INFORMATION') {
        this.informationData = this.responseData[i].controls;
      }else if (this.responseData[i].type == 'EQUIPMENT STOPS'){
        this.equipmenetStopsData = this.responseData[i].controls;
      }
      if(this.equipmenetStopsData && this.equipmenetStopsData.length > 0){
        let equipmentStopsCountIndex = this.equipmenetStopsData.findIndex((x: { esstopcontrolname: string; }) => x.esstopcontrolname === 'es-position-stop');
        if(equipmentStopsCountIndex >= 0){
          this.HandoffequipmentStopsCount = this.equipmenetStopsData[equipmentStopsCountIndex].response;
        }
        let equipmentStopsMinutesIndex = this.equipmenetStopsData.findIndex((x: { esstopcontrolname : string; }) => x.esstopcontrolname === 'es-position-downtime');
        if(equipmentStopsMinutesIndex >= 0){
          this.HandoffEquipmentStopsDowntime = this.equipmenetStopsData[equipmentStopsMinutesIndex].response;
        }
      }
    }
    if (this.isCIDCompleted && this.isCIDCompleted == true) {
      this.CIDdata = true;
    } else {
      this.CIDdata = false;
    }

    if (this.reportedDefectArray && this.reportedDefectArray.length > 0) {
      let reportdED;
      if (this.selectedPlantCode == 'FVL') {
        reportdED = this.reportedDefectArray.filter(
          (eachItem: any) =>
            eachItem.impact?.code == 'DEL' ||
            eachItem.impact?.code == 'CST' ||
            eachItem.impact?.code == 'PPL' ||
            eachItem.impact?.code == 'ENV'
        );
      } else if (this.selectedPlantCode != 'FVL') {
        reportdED = this.reportedDefectArray.filter(
          (eachItem: any) =>
            eachItem.impact?.code == 'DEL' ||
            eachItem.impact?.code == 'CST' ||
            eachItem.impact?.code == 'PPL'
        );
      }
      let reportdSFT = this.reportedDefectArray.filter(
        (eachItem: any) => eachItem.impact?.code == 'SFT'
      );
      let reportdQLT = this.reportedDefectArray.filter(
        (eachItem: any) => eachItem.impact?.code == 'QLT'
      );
      let reportdENV = this.reportedDefectArray.filter(
        (eachItem: any) => eachItem.impact?.code == 'ENV'
      );
      if (reportdED && reportdED.length > 0) {
        this.reportedDefectEDCount = reportdED.length;
      }
      if (reportdSFT && reportdSFT.length > 0) {
        this.reportedDefectSafetyCount = reportdSFT.length;
      }
      if (reportdQLT && reportdQLT.length > 0) {
        this.reportedDefectQualityCount = reportdQLT.length;
      }
      if (reportdENV && reportdENV.length > 0) {
        this.reportedDefectENVCount = reportdENV.length;
      }
    }
  }

  public getAccrdianIconCount(positionCode: any) {
    let calculatedValue: any = 100;
    switch (positionCode) {
      case 'SAFETY':
        calculatedValue = this.reportedDefectSafetyCount;
        break;
      case 'QUALITY':
        calculatedValue = this.reportedDefectQualityCount;
        break;
      case 'DELIVERY':
        calculatedValue = 'D';
        break;
      case 'COST':
        calculatedValue = 'C';
        break;
      case 'CENTERLINE DEVIATIONS':
        calculatedValue = this.getSummaryCountCLD();
        break;
      case 'CID':
        calculatedValue = 'D';
        break;
      case 'EQUIPMENT STOPS':
        calculatedValue = this.getSummaryCount("EQUIPMENT STOPS");
        break;
      case 'EQUIPMENT DEFECTS':
        calculatedValue = this.reportedDefectEDCount;
        break;
      case 'ENVIRONMENT':
        calculatedValue = this.reportedDefectENVCount;
        break;
      case 'PEOPLE':
        calculatedValue = 'P';
        break;
      default:
        break;
    }
    return calculatedValue;
  }

  public getAccrdianIconName(positionCode: any) {
    let translatedItemText = '';
    switch (positionCode) {
      case 'SAFETY':
        translatedItemText = 'app.previous_hoff.safety';
        break;
      case 'QUALITY':
        translatedItemText = 'app.previous_hoff.quality';
        break;
      case 'DELIVERY':
        translatedItemText = 'app.previous_hoff.delivery';
        break;
      case 'COST':
        translatedItemText = 'app.previous_hoff.cost';
        break;
      case 'CENTERLINE DEVIATIONS':
        translatedItemText = 'app.previous_hoff.centerline_deviations';
        break;
      case 'CID':
        translatedItemText = 'app.previous_hoff.cid';
        break;
      case 'EQUIPMENT STOPS':
        translatedItemText = 'app.previous_hoff.equipment_stops';
        break;
      case 'EQUIPMENT DEFECTS':
        translatedItemText = 'app.previous_hoff.equipment_defects';
        break;
      case 'ENVIRONMENT':
        translatedItemText = 'app.previous_hoff.environment';
        break;
      case 'PEOPLE':
        translatedItemText = 'app.previous_hoff.people';
        break;
      default:
        break;
    }
    return translatedItemText;
  }


  public getHeadName(positionCode: any) {
    let headText = 'W';
    switch (positionCode) {
      case 'SAFETY':
        headText = 'safety';
        break;
      case 'QUALITY':
        headText = 'quality';
        break;
      case 'DELIVERY':
        headText = 'delivery';
        break;
      case 'COST':
        headText = 'cost';
        break;
      case 'CENTERLINE DEVIATIONS':
        headText = 'delivery';
        break;
      case 'CID':
        headText = 'delivery';
        break;
      case 'EQUIPMENT STOPS':
        headText = 'delivery';
        break;
      case 'EQUIPMENT DEFECTS':
        headText = 'delivery';
        break;
      case 'ENVIRONMENT':
        headText = 'env';
        break;
      case 'PEOPLE':
        headText = 'people';
        break;
      default:
        break;
    }
    return headText;
  }

  public getsummaryId(positionCode: any) {
    let idText = 'W';
    switch (positionCode) {
      case 'SAFETY':
        idText = 'safety';
        break;
      case 'QUALITY':
        idText = 'quality';
        break;
      case 'DELIVERY':
        idText = 'delivery';
        break;
      case 'COST':
        idText = 'cost';
        break;
      case 'CENTERLINE DEVIATIONS':
        idText = 'centerline-deviations';
        break;
      case 'CID':
        idText = 'cid';
        break;
      case 'EQUIPMENT STOPS':
        idText = 'equipment-stops';
        break;
      case 'EQUIPMENT DEFECTS':
        idText = 'equipment-defects';
        break;
      case 'ENVIRONMENT':
        idText = 'env';
        break;
      case 'PEOPLE':
        idText = 'people';
        break;
      default:
        break;
    }
    return idText;
  }

  public getAccrdianIconText(positionCode: any) {
    let iconText = 'S';
    switch (positionCode) {
      case 'SAFETY':
        iconText = 'S';
        break;
      case 'QUALITY':
        iconText = 'Q';
        break;
      case 'DELIVERY':
        iconText = 'D';
        break;
      case 'COST':
        iconText = 'C';
        break;
      case 'CENTERLINE DEVIATIONS':
        iconText = 'D';
        break;
      case 'CID':
        iconText = 'D';
        break;
      case 'EQUIPMENT STOPS':
        iconText = 'D';
        break;
      case 'EQUIPMENT DEFECTS':
        iconText = 'D';
        break;
      case 'ENVIRONMENT':
        iconText = 'E';
        break;
      case 'PEOPLE':
        iconText = 'P';
        break;
      default:
        break;
    }
    return iconText;
  }


  setLanguage() {
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage');
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
    }
    if (this.selectedLanguage && this.selectedLanguage !== 'undefined') {
      if (
        this.selectedLanguage !==
        getUserDetails(this.userDetailsData).languageCode
      ) {
        this.isLangchange = true;
      }
      this.languageSelectionService.setLanguage(this.selectedLanguage);
    } else {
      if (this.userDetailsData && this.userDetailsData.result) {
        this.languageSelectionService.setLanguage(this.userDetailsData.result.preLangCode);
      } else {
        this.languageSelectionService.setLanguage('en');
      }
    }
  }

  public checkOperatorPositions(positionCode: any): void {
    switch (positionCode) {
      case 'SPLS':
        this.isOperatorPosition = true;
        break;
      case 'SPLMIX':
        this.isOperatorPosition = true;
        break;
      case 'SPLFRY':
        this.isOperatorPosition = true;
        break;
      case 'PKG1':
        this.isOperatorPosition = true;
        break;
      case 'PKG2':
        this.isOperatorPosition = true;
        break;
      case 'PKG3':
        this.isOperatorPosition = true;
        break;
      case 'PKG4':
        this.isOperatorPosition = true;
        break;
      case 'PKG5':
        this.isOperatorPosition = true;
        break;
      case 'PKG6':
        this.isOperatorPosition = true;
        break;
      case 'PKG7':
        this.isOperatorPosition = true;
        break;
      case 'PKG8':
        this.isOperatorPosition = true;
        break;
      case 'PKG9':
        this.isOperatorPosition = true;
        break;
      case 'CDR':
        this.isOperatorPosition = true;
        break;
      case 'MR':
        this.isOperatorPosition = true;
        break;
      case 'PL':
        this.isOperatorPosition = true;
        break;
      case 'BTR':
        this.isOperatorPosition = true;
        break;
      case 'CR':
        this.isOperatorPosition = true;
        break;
      case 'SADR':
        this.isOperatorPosition = true;
        break;
      case 'CT':
        this.isOperatorPosition = true;
        break;
      case 'FRY':
        this.isOperatorPosition = true;
        break;
      case 'PH':
        this.isOperatorPosition = true;
        break;
      case 'PSL':
        this.isOperatorPosition = true;
        break;
      case 'SPDT':
        this.isOperatorPosition = true;
        break;
      case 'CF':
        this.isOperatorPosition = true;
        break;
      case 'EL':
        this.isOperatorPosition = true;
        break;
      case 'BMN':
        this.isOperatorPosition = true;
        break;
      case 'AFTR':
        this.isOperatorPosition = true;
        break;
      case 'CC':
        this.isOperatorPosition = true;
        break;
      case 'CM':
        this.isOperatorPosition = true;
        break;
      case 'NTYR':
        this.isOperatorPosition = true;
        break;
      case 'QC':
        this.isOperatorPosition = true;
        break;
      case 'PLR':
        this.isOperatorPosition = true;
        break;
      case 'FORK':
        this.isOperatorPosition = true;
        break;
      case 'PCMPN':
        this.isOperatorPosition = true;
        break;
      case 'STR':
        this.isOperatorPosition = true;
        break;
      case 'PSA':
        this.isOperatorPosition = true;
        break;
      case 'PKG41':
        this.isOperatorPosition = true;
        break;
      case 'PKG42':
        this.isOperatorPosition = true;
        break;
      case 'PKG43':
        this.isOperatorPosition = true;
        break;
      case 'PKG44':
        this.isOperatorPosition = true;
        break;
      case 'PKG45':
        this.isOperatorPosition = true;
        break;
      case 'PKG46':
        this.isOperatorPosition = true;
        break;
      case 'ATSPL':
        this.isOperatorPosition = true;
        break;
      case 'CTR':
        this.isOperatorPosition = true;
        break;
      case 'FROP':
        this.isOperatorPosition = true;
        break;
      case 'FRMS':
        this.isOperatorPosition = true;
        break;
      case 'PLEBL1':
        this.isOperatorPosition = true;
        break;
      case 'PLEBL2':
        this.isOperatorPosition = true;
        break;
      case 'FTRL1':
        this.isOperatorPosition = true;
        break;
      case 'FTRL2':
        this.isOperatorPosition = true;
        break;
      case 'CTXL1':
        this.isOperatorPosition = true;
      break;
      case 'CTXL2':
        this.isOperatorPosition = true;
      break;
      case 'ENSM2L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM3L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM4L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM5L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM6L2':
        this.isOperatorPosition = true;
        break;
      case 'MLFR1':
        this.isOperatorPosition = true;
        break;
      case 'CTNRL1':
        this.isOperatorPosition = true;
        break;
      case 'CTNRL2':
        this.isOperatorPosition = true;
        break;
      case 'MLFR2':
        this.isOperatorPosition = true;
        break;
      case 'MLGFLK':
        this.isOperatorPosition = true;
        break;
      case 'NTRL1':
        this.isOperatorPosition = true;
        break;
      case 'NTRL2':
        this.isOperatorPosition = true;
        break;
      case 'CTOTL1':
        this.isOperatorPosition = true;
        break;
      case 'DCIL1':
        this.isOperatorPosition = true;
        break;

      case 'PKGL1':
        this.isOperatorPosition = true;
        break;
      case 'PKGL2':
        this.isOperatorPosition = true;
        break;
      case 'EPRL1':
        this.isOperatorPosition = true;
        break;
      case 'EPRL2':
        this.isOperatorPosition = true;
        break;
        case 'DCIL2':
        this.isOperatorPosition = true;
        break;
        case 'CYLRL1':
        this.isOperatorPosition = true;
        break;
      case 'CYLRL2':
        this.isOperatorPosition = true;
        break; 
      case 'CTOTL1':
          this.isOperatorPosition = true;
          break;
      case 'CTOTL2':
          this.isOperatorPosition = true;
          break;    
      default:
        break;
    }
  }

  public checkSupervisorPositions(positionCode: any): void {
    switch (positionCode) {
      case 'FLS':
        this.isSupervisorPosition = true;
        break;
      case 'SLS':
        this.isSupervisorPosition = true;
        break;
      case 'PLH':
        this.isSupervisorPosition = true;
        break;
      case 'PLS':
        this.isSupervisorPosition = true;
        break;
      case 'FLS1':
        this.isSupervisorPosition = true;
        break;
      case 'FLS2':
        this.isSupervisorPosition = true;
        break;
        case 'PLH1':
          this.isSupervisorPosition = true;
          break; 
          case 'PLH2':
          this.isSupervisorPosition = true;
          break; 
      default:
        break;
    }
  }

  public getSummaryCountCLD() {
    if (
      this.reportedCLDeviationArray &&
      this.reportedCLDeviationArray?.length > 0
    ) {
      const centerlineControls = this.responseData.find(
        (item: any) => item.type === 'CENTERLINE DEVIATIONS'
      );
      let reportedCLDeviationArrayTemp = [];
      if (
        centerlineControls.controls &&
        centerlineControls.controls.length > 0
      ) {
        reportedCLDeviationArrayTemp = this.reportedCLDeviationArray.filter(
          (x: { reportId: any }) =>
            centerlineControls.controls.findIndex(
              (y: { cldRPTId: any }) => y.cldRPTId === x.reportId
            ) >= 0
        );
        this.reportedCLDeviationArray = reportedCLDeviationArrayTemp;
      }
      return this.reportedCLDeviationArray.length;
    } else {
      return 0;
    }
  }

  public getSummaryCount(itemType: string) {
    const safetyItem = this.responseData?.find(
      (item: any) => item.type === itemType
    );
    if (itemType === 'EQUIPMENT STOPS') {
      if (
        this.reportedRTDuetDefectObj &&
        (this.reportedRTDuetDefectObj?.lineCode == 'FRY' ||
          this.reportedRTDuetDefectObj?.lineCode == 'SPEC')
      ) {
        return (
          this.HandoffequipmentStopsCount +
          ' / ' +
          this.HandoffEquipmentStopsDowntime
        );
      } else {
        const noOfStops = safetyItem?.controls?.find(
          (item: any) => item.controlname === 'es-stopno-val'
        );
        const totalDownTime = safetyItem?.controls?.find(
          (item: any) => item.controlname === 'es-downtime-val'
        );
        return (
          (noOfStops ? noOfStops.response : 0) +
          ' / ' +
          (totalDownTime ? totalDownTime.response : 0)
        );
      }
    } else {
      return safetyItem ? safetyItem.controls.length : 0;
    }
  }

  formateDate(date:string){
    if(this.selectedLanguage == 'fr'){

      let modified = date.substring(date.indexOf('.') + 1).trim();
      let parts = modified.split('');
      if(parts.length > 3){
        parts.splice(7,0,',');
      }
      return parts.join('');
    }else{
      return moment(date).format('MMM DD, YYYY');
    }
  }
}
