import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
 
@Pipe({
  name: 'datetimeFormat',
  standalone: true
})
export class DatetimeFormatPipe implements PipeTransform {
  transform(value: any, dateFormat:string = ''): any {
    let selectedLang;
    let formattedDate;
    if(sessionStorage.getItem('selectedLanguage') !== ""){
      selectedLang = String(sessionStorage.getItem('selectedLanguage'));
    }
    if(!(value.includes("-") || value.includes(":"))){
      value = Number(value);
    }

    if(selectedLang == 'fr'){
      moment.locale('fr');
      if(dateFormat === 'mm/dd/yyyy'){
        formattedDate = moment(value).format('DD/MM/YYYY, H:mm');
      }else{
        formattedDate = moment(value).format('MMM DD YYYY, H:mm');
      }
    }else{
      formattedDate = moment(value).format('MMM DD YYYY, h:mm A');
    }
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }
}