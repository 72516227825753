<div class="handoff-container category-container" [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">
    @if((selectedPlantCode == 'BUR' && positionCode.includes('PKG')) || selectedPlantCode == 'HAR'|| selectedPlantCode === 'MAT' || selectedPlantCode === 'BUR'
    || (selectedPlantCode === 'FVL' && positionCode.includes('PKG'))
    || (selectedPlantCode === 'FVL' && positionCode === 'SADR') ){

    }@else{
    <div class="row">
        <div class="col">
            @for(item of equDefectResponse; track item; let i= $index) {
            @for (controlItem1 of equDefectResponse[i]; track controlItem1) {
            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header report-header">{{controlItem1.labeltext}}</label>
            </div>
            @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
            {
            <app-reported-defect-view [tableId]="'PHF_Equipment_defects_Table'"
                [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
            }
            @else{
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }}
            </div>
            }
            <div class="row spacing-pt-10">
                <div class="col-6 items-section">
                    @for(item of equDefectResponse; track item; let i= $index) {
                    @for (controlItem1 of equDefectResponse[i]; track controlItem1) { 
                    @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
                    'number')&& controlItem1.controlname =='ed-other-risk') {
                    <div class="row custom-row-{{i}}">
                        <div class="col">
                            <label class="label">{{controlItem1.labeltext}}</label>
                        </div>
                    </div>
                    <div class="row  m-0 custom-row-{{i}}">
                        <div class="col response-sec">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                    </div>
                    }
                    }
                    }
                </div>           
            </div>
            }
            }
            }

        </div>

    </div>
    }
    <div class="row spacing-pt-10">
        <div class="col-6 items-section">
            @for(item of equDefectResponse; track item; let i= $index) {
            @for (controlItem1 of equDefectResponse[i]; track controlItem1) {
            @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number') &&(controlItem1.controlname!='ed-other-risk' && controlItem1.controlname !=='ed-report' || (controlItem1.controlname ==='ed-other-risk' && positionCode === 'CR'))) {
                <div class="row">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        @if(controlItem1.controlname === "safety-fryer-scrape") {
                        <div class="row">
                            <div class="col">
                                <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                                    ,moment.ISO_8601,true).isValid()?(controlItem1.response | date : 'MM/dd/yyyy h:mm a')
                                    :controlItem1.response}}</p>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                <span class="unit-bg">{{ "app.previous_hoff.hrs" | translate }}</span>
    
                            </div>
    
                        </div>
    
                        }@else {
                        <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response
                            ,moment.ISO_8601,true).isValid()?(controlItem1.response | date: 'MM/dd/yyyy h:mm a')
                            :controlItem1.response }}</p>
                        }
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
        
           
            }
            }
        }
        </div>
        <div class="col-6 comments-section">
            @for(item of equDefectResponse; track item; let i= $index) {
            @for (controlItem1 of equDefectResponse[i]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text') {
            <div class="row spacing-pt-20">
                <div class="col"></div>
            </div>
            <div class="row spacing-pt-20 custom-{{controlItem1.controlname}}">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>
                </div>
            </div>
            }
            }
            }

        </div>

    </div>

    @if((selectedPlantCode == 'BUR' && positionCode.includes('PKG')) ||  selectedPlantCode == 'HAR' || selectedPlantCode === 'MAT' || selectedPlantCode === 'BUR' || selectedPlantCode === 'CR'
    || (selectedPlantCode === 'FVL' && positionCode.includes('PKG'))
    || (selectedPlantCode === 'FVL' && positionCode === 'SADR')){
    <div class="row">
        <div class="col">
            @for(item of equDefectResponse; track item; let i= $index) {
            @for (controlItem1 of equDefectResponse[i]; track controlItem1) {
            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header report-header">{{controlItem1.labeltext}}</label>
            </div>
            @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
            {
            <app-reported-defect-view [tableId]="'PHF_Equipment_defects_Table'" [selectedPlantCode]="selectedPlantCode"
                [reportedCategoryDefectArray]="reportedCategoryDefectArray"></app-reported-defect-view>
            }
            @else{
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }}
            </div>
            }
            <div class="row spacing-pt-10">
                <div class="col-6 items-section">
                    @for(item of equDefectResponse; track item; let i= $index) {
                    @for (controlItem1 of equDefectResponse[i]; track controlItem1) {
                    @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
                    'number')&& controlItem1.controlname =='ed-other-risk' || controlItem1.controlname =='ed-report' || controlItem1.controlname =='ed-other-report') {
                    <div class="row custom-row-{{i}}">
                        <div class="col">
                            <label class="label">{{controlItem1.labeltext}}</label>
                        </div>
        
                    </div>
                    <div class="row  m-0 custom-row-{{i}}">
                        <div class="col response-sec">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                    </div>
                    }
                    }
                    }
                </div>
                
        
            </div>
            }
            }
            }

        </div>

    </div>
    }
</div>