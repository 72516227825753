import { Component, ElementRef, Input, Renderer2, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ApiService } from '../../../services/api.service';
import { MockApiService } from '../../../services/mock-api.service';
import { TranslateModule } from '@ngx-translate/core';
import { UserSettings } from '../../../models/user-settings';
import { Plant } from '../../../models/plant';
import { Language } from '../../../models/language';
import { Router } from '@angular/router';
import { AuthApiService } from '../../../services/auth-api.service';
import { LanguageSelectionService } from '../../../services/language-selection.service';
import { getLanguageList } from '../../../../helper/getLanguageList';
import { getPlantListFromUserDetails } from '../../../../helper/getPlantListFromUserDetails';
import * as APP_CONST from '../../../../../app/constants/app.constants';
import { getUserDetails } from '../../../../helper/getUserDetails';
import { UtilityService } from '../../../../shared/services/utility.service';

@Component({
  selector: 'app-user-side-nav',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    TranslateModule,
  ],
  templateUrl: './user-side-nav.component.html',
  styleUrl: './user-side-nav.component.scss',
})
export class UserSideNavComponent {
  // dropdownList: any = [];
  // private mockapiService = inject(MockApiService);
  private authapiService = inject(AuthApiService);
  private router = inject(Router);
  @Input() userDetailsProfileData: any;
  private languageSelectionService = inject(LanguageSelectionService)
  public languageList: any = [];
  public plantList: any = [];
  public userSettingsList: UserSettings[] = [];
  public selectedLanguage: Language[] = [];
  public selectedPlant: Plant[] = [];
  public userDetailsData: any;
  public languageDataList: any;
  public userInfoDetails: any;
  public lineList: any = [];
  public landingScreenList: any = [];
  public selectedItems: any = [];
  public plantSettings: any = [];
  public language: any = [];
  public languageSettings: any = {};
  public userData: any;
  public userSettingsForm: FormGroup = new FormGroup({
    language: new FormControl('', Validators.required),
    plant: new FormControl('', Validators.required),
  });

  constructor(private el: ElementRef, private renderer: Renderer2, private utilityService: UtilityService) { }


  ngOnInit() {    
    this.getPlantList();
    // this.getLineList();
    // this.getLandingScreenList();
    this.plantSettings = {
      singleSelection: true,
      idField: 'plantId',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false,
      disabled: false,
      closeDropDownOnSelection:true
    };
    this.languageSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      extCode: 'value',
      allowSearchFilter: false,
      closeDropDownOnSelection: true,
      enableCheckAll: false,
    };
  }
  ngAfterViewInit() {
    this.addUniqueIds();
  }

  private addUniqueIds() {
    const idPrefix: string = 'login-sidenav'; 
    const selectors = [
      '.dropdown-btn',
      '.multiselect-dropdown',
      '.dropdown-list'
    ];

    this.utilityService.addUniqueIdsToElements(this.el, this.renderer, idPrefix, selectors);
  }
  onItemSelect(item: any) {
  }
  onPlantSelection(item: any) {
    this.userData = getUserDetails(this.userDetailsData)
    this.userData.plantId = item.plantId;
    this.getLanguages();
  }

  getLanguages() {
    this.userData = this.userData ? this.userData : getUserDetails(this.userDetailsData);
    this.authapiService
      .getLanguages(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.SETTINGS_SUBFEATURE,
        this.userData
      )
      .subscribe({
        next: (data: any[]) => {
          this.languageDataList = data;
          this.languageList = getLanguageList(this.languageDataList);
          if (this.userDetailsData.result.preLangCode) {
            let selectedTempLang =this.languageList[0];
            this.selectedLanguage = [              
              { id: selectedTempLang.id, name: selectedTempLang.name, code: selectedTempLang.id }
            ];
          }
        },
      });
  }
  getLanguagesInitial(){
    this.userData = this.userData ? this.userData : getUserDetails(this.userDetailsData);
    this.authapiService
      .getLanguages(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.SETTINGS_SUBFEATURE,
        this.userData
      )
      .subscribe({
        next: (data: any[]) => {
          this.languageDataList = data;
          this.languageList = getLanguageList(this.languageDataList);
          if (this.userDetailsData.result.preLangCode) {
            let selectedTempLang = this.languageList.find(
              (x: { id: any }) => x.id === this.userDetailsData.result.preLangCode);
            this.selectedLanguage = [              
               { id: selectedTempLang.id, name: selectedTempLang.name, code: selectedTempLang.id }
            ];
          }
        },
      });
  }

  async onNavigationOpen() {
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
    }
    await this.getPlantList();
    this.getLanguagesInitial();
  }

  getPlantList() {
    this.plantList = getPlantListFromUserDetails(this.userDetailsData);
    if (this.plantList.length == 1) {
      this.selectedPlant = [
        { id: this.plantList[0].plantId, name: this.plantList[0].name, plantId:this.plantList[0].plantId, },
      ];
      this.plantSettings.disabled = true;
    } else {
      let selectedTempPlant = this.plantList.find(
        (x: { plantId: any }) => x.plantId === this.userDetailsData.result.prePlantId);
        if(selectedTempPlant)
        {
          this.selectedPlant = [
            { id: selectedTempPlant.plantId, name: selectedTempPlant.name,plantId:selectedTempPlant.plantId}
          ];
        }      
    }
  }
  // getLineList() {
  //   this.mockapiService.getLineList().subscribe({
  //     next: (data: any) => {
  //       this.lineList = data;
  //     },
  //     error: (error) => {
  //       console.error('Line list error msg', error);
  //     },
  //   });
  // }
  // getLandingScreenList() {
  //   this.mockapiService.getLandingScreenList().subscribe({
  //     next: (data: any) => {
  //       this.landingScreenList = data;
  //     },
  //     error: (error) => {
  //       console.error('Landing screen error msg', error);
  //     },
  //   });

  // }
  onUserSubmit() {
    this.setUserInfoDetailsForSubmit();
    this.authapiService
      .saveShiftPosition(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.SETTINGS_SUBFEATURE,
        this.userInfoDetails,this.userDetailsProfileData.userProfileName,
        APP_CONST.IS_SETTINGS
      )
      .subscribe({
        next: (position) => { },
        complete: () => {
          this.router.navigate(['']).then(() => {
            window.location.reload();
          });
        },
      });
  }

  setUserInfoDetailsForSubmit() {
    this.selectedLanguage = this.userSettingsForm.value.language;
    this.selectedPlant = this.userSettingsForm.value.plant;
    this.userInfoDetails = getUserDetails(this.userDetailsData);
    this.languageSelectionService.setLanguage(this.selectedLanguage[0].id);
    this.userInfoDetails.languageCode = this.selectedLanguage[0].id;
    this.userInfoDetails.plantId = this.plantList.find(
      (x: { plantId: any }) => x.plantId === this.userSettingsForm.value.plant[0].plantId
    ).plantId;
    let localUserSetting: any = sessionStorage.getItem('userDetails');
    let tempUserDetails:any = JSON.parse(localUserSetting);
    tempUserDetails.result.preLangCode = this.selectedLanguage[0].id;
    let tempPLantId:any = this.selectedPlant[0].id;
    tempUserDetails.result.prePlantId =tempPLantId;
    sessionStorage.setItem('userDetails', JSON.stringify(tempUserDetails));
  }
}
