import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthApiService } from '../../core/services/auth-api.service';
import * as APP_CONST from '../../constants/app.constants';
import { getUserDetails } from '../../helper/getUserDetails'; 
import { getApitoken } from '../../helper/getApitoken';
import { getAccessToken, isAccessTokenExpired, updateRequestWithToken } from '../../helper/apiTokenValidation';
import { ApplicationInsightsService } from '../../appInsight.service';

 
@Injectable()
export class authInterceptor implements HttpInterceptor { 
  private authapiService = inject(AuthApiService); 
  private applicationInsightsService = inject(ApplicationInsightsService);
  public userDetailsData: any;
  public apiTokenSession: any;
  public apiTokenCall = false;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (sessionStorage.getItem('Apitoken') !== null && request.url.includes(environment.apiUrl) && !(request.url.includes(APP_CONST.GETUSER_API))  && !(request.url.includes(APP_CONST.GETTOKEN_API))) {
      let accessToken = getAccessToken();
      if (accessToken != null && isAccessTokenExpired()) {
        this.apiTokenCall = false;
        request = updateRequestWithToken(request);
       
        return next.handle(request).pipe(
          catchError((error) => {  
            this.applicationInsightsService.trackException(error?.message);
             return throwError(() => error);
          })
        )
      }
      else {
        return this.getAPItoken().pipe(
          switchMap(token => {
            let newToken = getApitoken(
              token
            )
            sessionStorage.setItem(
              'Apitoken', JSON.stringify({
                token: newToken.apiToken, tokenExpiryTime: newToken.tokenExpiryTime
              }));
            request = updateRequestWithToken(request);
            return next.handle(request).pipe(
              catchError((error) => {  
                this.applicationInsightsService.trackException(error?.message);
                 return throwError(() => error);
              })
            )
          })
        );
      }
    }
    else {
      return next.handle(request).pipe(
        catchError((error) => {  
          this.applicationInsightsService.trackException(error?.message);
           return throwError(() => error);
        })
      )
    }
  }
  
  getAPItoken(): Observable<any> { 
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
    }
    if (sessionStorage.getItem('Apitoken') !== null) {
      this.apiTokenSession = JSON.parse(
        sessionStorage.getItem('Apitoken') || ''
      );
    }
    let userInfoDetails = getUserDetails(
      this.userDetailsData
    );
    return this.authapiService
      .getAPItoken(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.USER_SUBFEATURE,
        userInfoDetails,
        this.apiTokenSession.token
      );
 
  }
}