import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-equipment-stops',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './equipment-stops.component.html',
  styleUrl: './equipment-stops.component.scss'
})
export class EquipmentStopsComponent {
  @Input() selectedPlantCode: any;
  @Input() positionCode: any;
  @Input() equStopsResponse: any;
  @Input() reportedRTDuetDefectObj: any;
  @Input() equipmentStopsControls: any;
  public RTDuetequipmentStops: any=[];
  public groupedByArea: any = []; 
  public selectedLang: string;

  constructor(private translateService: TranslateService){
    if(sessionStorage.getItem('selectedLanguage') !== ""){
      this.selectedLang = String(sessionStorage.getItem('selectedLanguage'));
    }
  }
  ngOnChanges() {
    if (this.reportedRTDuetDefectObj && this.reportedRTDuetDefectObj.equipmentStops?.length > 0) {
      this.RTDuetequipmentStops = this.reportedRTDuetDefectObj.equipmentStops;
      this.RTDuetequipmentStops.forEach((element: any, index: any) => {
        element.controls = [];
        let nextStep = '';
        let nextStepindex = this.equipmentStopsControls.findIndex((x: { controlname: string; }) => x.controlname == 'suggestedSteps' + index);
        if (nextStepindex >= 0) {
          nextStep = this.equipmentStopsControls[nextStepindex].response;
        }
        let flag = '';
        let flagindex = this.equipmentStopsControls.findIndex((x: { controlname: string; }) => x.controlname == 'suggestedStepsflg' + index);
        if (flagindex >= 0) {
          flag = this.equipmentStopsControls[flagindex].response;
        }
        element.controls.push({ controlname: 'suggestedSteps' + index, response: nextStep });
        element.controls.push({ controlname: 'suggestedStepsflg' + index, ctrltype: 'flagicon', response: flag });
      });
      this.getFirstIndexOfUniqueItems(this.RTDuetequipmentStops);
    }

  }


  getFirstIndexOfUniqueItems(items: any[]): Record<number, number> {
    const uniqueIndexes: Record<number, number> = {};

    items.forEach((item, index) => {
      if (uniqueIndexes[item.area] === undefined) {
        uniqueIndexes[item.area] = index;
        this.groupedByArea.push(index);
      }
    });

    return uniqueIndexes;
  }

  getDateFromEpoch(epochtime: any) {
    if (epochtime) {
      if(this.selectedLang === 'fr'){
        return moment(parseInt(epochtime)).format('DD/MM/YYYY HH:mm');
      }else{
        return moment(parseInt(epochtime)).format('MM/DD/YYYY hh:mm:ss A');
      }
    }
    else {
      return '';
    }
  }
}
