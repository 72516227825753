<div class=" custom-{{selectedPlantCode}}-{{positionCode}}">
    @if(isOperatorPosition) {
        <div class="handoff-container category-container  safety-pre">
          @if(positionCode === 'PSA' ){
            <div class="col-12 accord-container">
                @for (controlItem of costResponse; track controlItem;) {
                <div class="row">
                    @for (costTableContentItem of controlItem; track costTableContentItem) {
             
                    @if(costTableContentItem.widget ==='input-table-header' && costTableContentItem.ctrltype === "label"){
                    <div class="col p-1" [ngClass]=" costTableContentItem.labeltext == 'Current Rework AMT/REASON' && costTableContentItem.controlname == 'cost-spectatermeal-header' ? 'col-3 amt-reason-col' : 'col'">
                        <div class="col p-0 d-flex textbox-content  textbox-wrapper">
                            <label
                                class=" form-control h-75 label-head rounded-0  d-flex ">{{costTableContentItem.labeltext}}</label>
                        </div>
                    </div>
             
                    }
             
             
                    @if(costTableContentItem.widget ==='input-table' && costTableContentItem.ctrltype === "text"){
                        @if(costTableContentItem.value) {
                            <div class="col p-1 target-container" [ngClass]="costTableContentItem.controlname.includes('cost-actual') && costTableContentItem.rowname.includes('cost-row3') ? 'pb-4': ''">
                                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                                    {{costTableContentItem.value}}
                                   
                                </div>
                            </div>
                        } @else if(costTableContentItem.response) {
                            <div class="p-1 target-container" [ngClass]="costTableContentItem.controlname == 'cost-fry-rework' || costTableContentItem.controlname == 'cost-spec-rework' ? 'col-3 col-width-3' : 'col'"S>
                                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                                    {{costTableContentItem.response}}
                                   
                                </div>
                            </div>
                        }
                   
                    }
             
                    @if(costTableContentItem.widget ==='input-table' && costTableContentItem.ctrltype === "number")
                    {
                    <div class="col p-1 target-container" [ngClass]="costTableContentItem.controlname.includes('cost-actual') && costTableContentItem.rowname.includes('cost-row3') ? 'pb-4': ''">
                        <div class="col  p-0 d-flex textbox-content textbox-wrapper justify-content-between ">
                           <div class="d-flex align-items-center ">
                            {{costTableContentItem.response}} 
                           </div>
                           <div class="unit-txt">
                            {{costTableContentItem.unit}}
                           </div>
                           
                        </div>
                    </div>
                    }
        
                    @if(costTableContentItem.widget ==='input-table' && costTableContentItem.ctrltype === "select")
                    {
                    <div class="col p-1 target-container" [ngClass]="costTableContentItem.controlname.includes('cost-actual') && costTableContentItem.rowname.includes('cost-row3') ? 'pb-4': ''">
                        <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                           <div class="d-flex align-items-center col-10">
                            {{costTableContentItem.response}} 
                           </div>
                          
                        
                        </div>
                    </div>
                    }
             
                    }
             
                </div>
                }
             
             
            </div>
          }@else {
            <div class="row spacing-pt-10">
                <div class="col-6 items-section">
                    <h3 class="mb-0 comments-header">{{ "app.previous_hoff.items" | translate }}</h3>
                    @for(item of costResponse; track item; let i= $index) {
                    @for (controlItem1 of costResponse[i]; track controlItem1) {
                    @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
                    'number' || controlItem1.ctrltype === 'select' || (controlItem1.ctrltype === 'text')  && (!controlItem1.controlname.includes("-cmt")))) {
                    <div class="row">
                       
                        @if(controlItem1.labeltextcmt) {
                          <div class="col">
                              <label class="label">{{controlItem1.labeltextcmt}}</label>
                          </div>
                        } @else {
                          <div class="col">
                              <label class="label">{{controlItem1.labeltext}}</label>
                          </div>
                        }
        
                    </div>
                    <div class="row  m-0 mb-3 ">
                        <div class="col response-sec">
                           @if(controlItem1.ctrltype === 'date') {
                           
                            <p class="mb-0">{{controlItem1.response!=' '?(controlItem1.response | date : 'MM/dd/yyyy h:mm a') :controlItem1.response}}</p>
                            
                           } @else {
                            <div class="row">
                                <div class="col">
                                    <p class="mb-0">{{controlItem1.response}}</p>
                                </div>
                                @if(controlItem1.unit){
                                <div class="col d-flex justify-content-end ">
                                    <span class="unit-bg">{{controlItem1.unit}}</span>
                                </div>
                                }
                            </div>        
                           }
                        </div>
                    </div>
                    }
                    }
                    }
                </div>
                <div class="col-6 comments-section comm-sect">
                    <h3 class="mb-0 comments-header">{{ "app.previous_hoff.comments" | translate }}</h3>
                    @for(item of costResponse; track item; let i= $index) {
                    @for (controlItem1 of costResponse[i]; track controlItem1) {
                    @if(controlItem1.ctrltype === 'text'  && (controlItem1.controlname.includes("-cmt"))) {
                    <div class="row">
                        <div class="col"><label class=" text-white">0</label></div>
                    </div>
                    <div class="row m-0 mb-3 ">
                        <div class="col response-sec custom-{{controlItem1.controlname}}">
                            <p class="mb-0">{{controlItem1.response}}</p>
                        </div>
                    </div>
                    }
                    }
                    } 
                </div> 
            </div>  
          }
          
          
        </div>
      } @else if(isSupervisorPosition) {
    <div class="handoff-container">
    
            <div class="col-12 accord-container">
                @for (controlItem of costResponse; track controlItem;) {
                <div class="row">
                    @for (costTableContentItem of controlItem; track costTableContentItem) {
             
                    @if(costTableContentItem.widget ==='input-table-header' && costTableContentItem.ctrltype === "label"){
                    <div class="col p-1" [ngClass]=" costTableContentItem.labeltext == 'Current Rework AMT/REASON' && costTableContentItem.controlname == 'cost-spectatermeal-header' ? 'col-3 amt-reason-col' : 'col'">
                        <div class="col p-0 d-flex textbox-content  textbox-wrapper custom-label-{{costTableContentItem.controlname}}">
                            <label
                                class=" form-control h-75 label-head rounded-0  d-flex ">{{costTableContentItem.labeltext}}</label>
                        </div>
                    </div>
             
                    }
             
             
                    @if(costTableContentItem.widget ==='input-table' && costTableContentItem.ctrltype === "text"){
                        @if(costTableContentItem.value) {
                            <div class="col p-1 target-container" [ngClass]="costTableContentItem.controlname.includes('cost-actual') && costTableContentItem.rowname.includes('cost-row3') ? 'pb-4': ''">
                                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                                    {{costTableContentItem.value}}
                                   
                                </div>
                            </div>
                        } @else if(costTableContentItem.response) {
                            <div class="p-1 target-container" [ngClass]="costTableContentItem.controlname == 'cost-fry-rework' || costTableContentItem.controlname == 'cost-spec-rework' ? 'col-3 col-width-3' : 'col'"S>
                                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                                    {{costTableContentItem.response}}
                                   
                                </div>
                            </div>
                        }
                   
                    }
             
                    @if(costTableContentItem.widget ==='input-table' && costTableContentItem.ctrltype === "number")
                    {
                    <div class="col p-1 target-container" [ngClass]="costTableContentItem.controlname.includes('cost-actual') && costTableContentItem.rowname.includes('cost-row3') ? 'pb-4': ''">
                        <div class="col  p-0 d-flex textbox-content textbox-wrapper justify-content-between ">
                           <div class="d-flex align-items-center ">
                            {{costTableContentItem.response}} 
                           </div>
                           <div class="unit-txt">
                            {{costTableContentItem.unit}}
                           </div>
                           
                        </div>
                    </div>
                    }
    
                    @if(costTableContentItem.widget ==='input-table' && costTableContentItem.ctrltype === "select")
                    {
                    <div class="col p-1 target-container" [ngClass]="costTableContentItem.controlname.includes('cost-actual') && costTableContentItem.rowname.includes('cost-row3') ? 'pb-4': ''">
                        <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                           <div class="d-flex align-items-center col-10">
                            {{costTableContentItem.response}} 
                           </div>
                          
                        
                        </div>
                    </div>
                    }
                    @if(costTableContentItem.widget ==='input-table' && costTableContentItem.ctrltype === "rdbutton")
                    {
                    <div class="col p-1 target-container"
                        [ngClass]="costTableContentItem.controlname.includes('cost-actual') && costTableContentItem.rowname.includes('cost-row3') ? 'pb-4': ''">
                        <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                            <div class="d-flex align-items-center col-10">
                                {{costTableContentItem.response}}
                            </div>
    
    
                        </div>
                    </div>
                    }
                    }
             
                </div>
                }
             
             
            </div>
            @if(selectedPlantCode === 'HAR' && (positionCode === 'FLS1' || positionCode === 'FLS2' ||  positionCode === 'SLS'  || positionCode === 'PLH1' || positionCode === 'PLH2')){
                <div class="row spacing-pt-10">
                    <div class="col-6 items-section">
                        @for(item of costResponse; track item; let i= $index) {
                        @for (controlItem1 of costResponse[i]; track controlItem1) {
                            @if(!controlItem1.widget){
                        @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype
                        ===
                        'number' || controlItem1.ctrltype === 'select' || (controlItem1.ctrltype === 'text') &&
                        (!controlItem1.controlname.includes("-cmt")))) {
                        
                        <div class="row">
        
                            @if(controlItem1.labeltextcmt) {
                            <div class="col">
                                <label class="label">{{controlItem1.labeltextcmt}}</label>
                            </div>
                            } @else {
                            <div class="col">
                                <label class="label">{{controlItem1.labeltext}}</label>
                            </div>
                            }
        
                        </div>
                        <div class="row  m-0 mb-3 ">
                            <div class="col response-sec">
                                @if(controlItem1.ctrltype === 'date') {
        
                                <p class="mb-0">{{controlItem1.response!=' '?(controlItem1.response | date : 'MM/dd/yyyy h:mm
                                    a') :controlItem1.response}}</p>
        
                                } @else {
                                    <div class="row">
                                        <div class="col">
                                            <p class="mb-0">{{controlItem1.response}}</p>
                                        </div>
                                        @if(controlItem1.unit){
                                        <div class="col d-flex justify-content-end ">
                                            <span class="unit-bg">{{controlItem1.unit}}</span>
                                        </div>
                                        }
                                    </div>
        
                                }
                            </div>
                        </div>
                        }
                        }
                        }
                        }
                    </div>
                    <div class="col-6 comments-section comm-sect">
                        @for(item of costResponse; track item; let i= $index) {
                        @for (controlItem1 of costResponse[i]; track controlItem1) {
                            @if(!controlItem1.widget){
                        @if(controlItem1.ctrltype === 'text' && (controlItem1.controlname.includes("-cmt"))) {
                        <div class="row m-0">
                            <div class="col"><label class=" text-white">0</label></div>
                        </div>
                        <div class="row m-0 mb-3 ">
                            <div class="col response-sec custom-{{controlItem1.controlname}}">
                                <p class="mb-0">{{controlItem1.response}}</p>
                            </div>
                        </div>
                        }
                        }
                    }
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>