import { Injectable, ElementRef, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  generateUniqueId(tagName: string, index: number, idPrefix: string): string {
    return `${idPrefix}-current-${tagName}-id-${index + 1}`;
  }

  addUniqueIdsToElements(el: ElementRef, renderer: Renderer2, idPrefix: string, selectors: string[]) {
    selectors.forEach(selector => {
      const elements = el.nativeElement.querySelectorAll(selector);
      elements.forEach((element: HTMLElement, index: number) => {
        const tagName = selector.startsWith('.') ? selector.slice(1) : element.tagName.toLowerCase();
        const id = this.generateUniqueId(tagName, index, idPrefix);
        renderer.setAttribute(element, 'id', id);
      });
    });
  }
}
