@if(isSupervisorPosition || (isOperatorPosition && positionCode === 'CDR')) {
<div class="handoff-container safety-pre"  [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">
    <div class="row spacing-pt-20">
        <div class="col-6 items-section cst-{{selectedPlantCode}}-{{positionCode}}">
            @if((selectedPlantCode==='MAT' && (positionCode==='PLH' || positionCode === 'SLS' || positionCode ===
            'FLS')) || (selectedPlantCode==='BUR' && positionCode==='PLS') || (selectedPlantCode==='HAR' && (positionCode==='FLS1' || positionCode === 'FLS2' || positionCode === 'PLH1' || positionCode === 'PLH2' || positionCode === 'SLS'))){

            }@else{
            <h3 class="mb-0 ">{{ "app.previous_hoff.items" | translate }}</h3>
            }
            @for(item of safetyResponse; track item; let i= $index) {
            @for (controlItem1 of safetyResponse[getIndex(i,safetyResponse)]; track controlItem1) {
            @if((controlItem1.ctrltype === 'rdbutton' ||controlItem1.ctrltype=='select'|| controlItem1.ctrltype ===
            'date' || controlItem1.ctrltype === 'text' || controlItem1.ctrltype ===
            'number' || (selectedPlantCode === 'HAR' && (positionCode === 'FLS1' || positionCode === 'FLS2'|| positionCode === 'PLH1' || positionCode === 'PLH2') && controlItem1.controlname === 'safety-additional')) && !controlItem1.controlname.includes("-cmt")
            && controlItem1.controlname !== "safety-other-risk" && controlItem1.labeltext !== "Any other safety observation or risk identified?") {
            <div class="row spacing-pt-20">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                    @if(controlItem1.controlname === "safety-fryer-scrape") {
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{(controlItem1.ctrltype === 'number') ? controlItem1.response :
                                (controlItem1.response!=' ' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response) }}</p>
                        </div>
                        @if(selectedPlantCode === 'MAT' && positionCode === 'FLS'){

                        }@else{
                        <div class="col d-flex justify-content-end ">
                            <span class="unit-bg">{{ "app.previous_hoff.hrs" | translate }}</span>

                        </div>
                    }
                    </div>

                    }@else {
                    <p class="mb-0">{{controlItem1.response!= ' '? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response }}</p>
                    }
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }
            }
            }
        </div>
        <div class="col-6 comments-section">
            @if((selectedPlantCode==='MAT' && (positionCode==='PLH' || positionCode === 'SLS' || positionCode ===
            'FLS')) || (selectedPlantCode==='BUR' && positionCode==='PLS') || (selectedPlantCode==='HAR' &&
             (positionCode==='FLS1' || positionCode === 'FLS2' || positionCode === 'PLH1' || positionCode === 'PLH2' || positionCode === 'SLS'))
             ){

            }@else{
            <h3 class="mb-0 ">{{ "app.previous_hoff.comments" | translate }}</h3>
            }

            @for(item of safetyResponse; track item; let i= $index) {
            @for (controlItem1 of safetyResponse[getIndex(i, safetyResponse)]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text' && controlItem1.controlname.includes("-cmt")) {
                @if(selectedPlantCode === 'HAR' && (positionCode === 'FLS1' || positionCode === 'FLS2' || positionCode === 'PLH1' || positionCode === 'PLH2') && controlItem1.controlname === 'safety-additional'){}@else{
            <div class="row spacing-pt-20 ">
                <div class="col"></div>
            </div>
            <div class="row spacing-pt-20">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                </div>
            </div>
        }
            }
            }
            }

        </div>

    </div>
    @if(selectedPlantCode=='BUR'){
        <div class="row">
            <div class="col">
                @for(item of safetyResponse; track item; let i= $index) {
                @for (controlItem1 of safetyResponse[i]; track controlItem1) {
    
                @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
                <div class="row py-3 ">
    
                    <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
                </div>
                @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
                {
                <app-reported-defect-view [tableId]="'PHF_Safety_Table'" [reportedCategoryDefectArray]="reportedCategoryDefectArray"
                    [selectedPlantCode]="selectedPlantCode"></app-reported-defect-view>
                }
                @else{
    
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                    "app.previous_hoff.reported" | translate }}
                </div>
                }
                }
    
                @if(controlItem1.ctrltype === 'rdbutton' && controlItem1.labeltext === "Any other safety observation or risk
                identified?" || controlItem1.controlname=='safety-other-risk' ) {
                <div class="">
                    <div class="col px-0">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class=" label-container mb-4">
                    <div class="col-6 response-sec resp-container">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0"> {{controlItem1.response!=' ' ? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response }}</p>
                        } @else {
                        <p class="mb-0"> {{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
                }
    
                }
                }
    
            </div>
    
        </div> 
    }@else {
        <div class="row">
            <div class="col">
                @for(item of safetyResponse; track item; let i= $index) {
                @for (controlItem1 of safetyResponse[i+1]; track controlItem1) {
    
                @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
                <div class="row py-3 ">
    
                    <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
                </div>
                @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
                {
                <app-reported-defect-view [tableId]="'PHF_Safety_Table'" [reportedCategoryDefectArray]="reportedCategoryDefectArray"
                    [selectedPlantCode]="selectedPlantCode"></app-reported-defect-view>
                }
                @else{
    
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                    "app.previous_hoff.reported" | translate }}
                </div>
                }
                }
    
                @if(controlItem1.ctrltype === 'rdbutton' && controlItem1.labeltext === "Any other safety observation or risk
                identified?" || controlItem1.controlname=='safety-other-risk' ) {
                <div class="">
                    <div class="col px-0">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class=" label-container mb-4">
                    <div class="col-6 response-sec resp-container">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0"> {{controlItem1.response!=' ' ? (controlItem1.response| datetimeFormat:'mm/dd/yyyy'):controlItem1.response }}</p>
                        } @else {
                        <p class="mb-0"> {{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
                }
    
                }
                }
    
            </div>
    
        </div>
    }
    
</div>
}@else if(isOperatorPosition) {

<div class="handoff-container category-container  safety-pre"  [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">
    <div class="row spacing-pt-10">
        <div class="col-6 items-section">
            @if(selectedPlantCode==='BUR' && positionCode==='PCMPN'){
            <h3 class="mb-0 custom-items">{{ "app.previous_hoff.items" | translate }}</h3>
            }
            @for(item of safetyResponse; track item; let i= $index) {
            @for (controlItem1 of safetyResponse[i]; track controlItem1) {
            @if((controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
            'number' || controlItem1.ctrltype === 'select') && (controlItem1.labeltext !== "Any other safety observation
            or risk identified?" && controlItem1.labeltext !== "D'autres observations ou risques liés à la sécurité
            ont-ils été identifiés ?" && controlItem1.controlname !=='safety-other-risk')) {
            <div class="row">
                <div class="col">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class="row  m-0 mb-3 ">
                <div class="col response-sec">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                    @if(controlItem1.controlname === "safety-fryer-scrape") {
                    <div class="row">
                        <div class="col">
                            <p class="mb-0">{{controlItem1.response!=' '?(controlItem1.response | datetimeFormat:'mm/dd/yyyy') :controlItem1.response}}</p>
                        </div>
                        <div class="col d-flex justify-content-end ">
                            <span class="unit-bg">{{ "app.previous_hoff.hrs" | translate }}</span>

                        </div>

                    </div>

                    }@else {
                    <p class="mb-0">{{controlItem1.response!=' '?(controlItem1.response | datetimeFormat:'mm/dd/yyyy')
                        :controlItem1.response}}</p>
                    }
                    } @else {
                    <p class="mb-0">{{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }
            }
            }
        </div>
        <div class="col-6 comments-section comm-sect">
            @if(selectedPlantCode==='BUR' && positionCode==='PCMPN'){
                <h3 class="mb-0 custom-comments">{{ "app.previous_hoff.comments" | translate }}</h3>
                }
            @for(item of safetyResponse; track item; let i= $index) {
            @for (controlItem1 of safetyResponse[i]; track controlItem1) {
            @if(controlItem1.ctrltype === 'text') {<div class="row">
                <div class="col"><label class=" text-white">0</label></div>
            </div>
            <div class="row m-0 mb-3 custom-{{controlItem1.controlname}}">
                <div class="col response-sec">
                    <p class="mb-0">{{controlItem1.response}}</p>
                </div>
            </div>
            }
            }
            }
        </div>
    </div>
    <div class="row">
        <div class="col">
            @for(item of safetyResponse; track item; let i= $index) {
            @for (controlItem1 of safetyResponse[i]; track controlItem1) {
            @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
            <div class="row py-3 ">
                <label class="safety-header report-header">{{ "app.previous_hoff.reported_safety_issues" | translate
                    }}</label>
            </div>
            @if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
            {
            <app-reported-defect-view [tableId]="'PHF_Safety_Table'" [reportedCategoryDefectArray]="reportedCategoryDefectArray"
                [selectedPlantCode]="selectedPlantCode"></app-reported-defect-view>
            }
            @else{

            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                "app.previous_hoff.reported" | translate }}
            </div>
            }
            }
            @if(controlItem1.ctrltype === 'rdbutton' && (controlItem1.labeltext === "Any other safety observation or
            risk identified?" || controlItem1.labeltext === "D'autres observations ou risques liés à la sécurité ont-ils
            été identifiés ?" || controlItem1.controlname=='safety-other-risk')) {
            <div class="">
                <div class="col px-0">
                    <label class="label">{{controlItem1.labeltext}}</label>
                </div>

            </div>
            <div class=" label-container">
                <div class="col-6 response-sec resp-container">
                    @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                    <p class="mb-0"> {{controlItem1.response!=' '? (controlItem1.response | datetimeFormat:'mm/dd/yyyy'):controlItem1.response }}</p>
                    } @else {
                    <p class="mb-0"> {{controlItem1.response}}</p>

                    }
                </div>
            </div>
            }

            }
            }

        </div>

    </div>

</div>
}