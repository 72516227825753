import { UserDetails } from '../core/models/user-details';
export function getUserDetails(userDetailsData: any) {
  let userData: UserDetails = {} as UserDetails;
  if (userDetailsData && userDetailsData.result) {
    userData.languageCode = userDetailsData.result.preLangCode;
    userData.userId = userDetailsData.result.id;
    userData.loginName = userDetailsData.result.loginName;
    if (
      userDetailsData.result.prePlantId &&
      userDetailsData.result.prePlantId !== ''
    ) {
      const plant = userDetailsData?.result?.plants?.find(
        (x: { id: any }) => x.id === userDetailsData.result.prePlantId
      );
      userData.plantId = plant?.id;
      userData.plantCode = plant?.code;
    } else {
      userData.plantCode = userDetailsData?.result?.plants?.[0].code;
      userData.plantId = userDetailsData?.result?.plants?.[0].id;
    }
    if (userDetailsData.result && userDetailsData.result.roles) {
      let roleIdArray: any = [];
      for (let roles of userDetailsData.result.roles) {
        roleIdArray.push(roles.id); // for keeping role id list
      }
      if (roleIdArray.length > 0) {
        userData.roleId = roleIdArray.join(",");
      }
    }
    if (userDetailsData.result && userDetailsData.result.roles) {
      let roleId_Names: any = [];
      //userData.roleId = userDetailsData.result.roles?.[0].id;
      for (let roles of userDetailsData.result.roles) {
        roleId_Names.push(roles.name);
        
        // userData.roles = userData.roles === undefined ? roles.name : +' ,' + roles.name; // for showing role in landing screen
        // if (userData.roles.split(',').length == 2) {
        //   break
        // }
      }
      if (roleId_Names.length > 0) {
        userData.roles = roleId_Names.join(",");
      }
    }
 
    userData.positionId1 =
      userDetailsData.result.positionId1 === ''
        ? 0
        : parseInt(userDetailsData.result.positionId1);
    userData.positionId2 =
      userDetailsData.result.positionId2 === ''
        ? 0
        : parseInt(userDetailsData.result.positionId2);
    userData.positionId3 =
      userDetailsData.result.positionId3 === ''
        ? 0
        : parseInt(userDetailsData.result.positionId3);
    userData.apiToken = userDetailsData.result.apiToken;
    userData.tokenExpiryTime = userDetailsData.result.tokenExpiryTime;
  }
 
  return userData;
}